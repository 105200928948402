import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleResponse from '@acdc/shared/src/features/module/ModuleResponse';
import SectionResponse from '@acdc/shared/src/features/section/SectionResponse';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Theme,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import ModuleDropzone from './ModuleDropzone';
import ContentModulePreview from './ContentModulePreview';
import useDeleteContentModule from './useDeleteContentModule';
import useCreateContentModule from './useCreateContentModule';

const rootSx = (section: SectionResponse) => ({
    border: (theme: Theme) => `1px solid ${theme.palette.grey['300']}`,
    order: section.row,
    height: section.height,
    width: section.width,
    flex: `1 0 ${section.width}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const cardSx = {
    backgroundColor: 'common.white',
    cursor: 'pointer',
    width: (theme: Theme) => `calc(100% - ${theme.spacing(2)})`,
    maxHeight: (theme: Theme) => `calc(100% - ${theme.spacing(2)})`,
    overflow: 'hidden',
    height: '100%',
};

const cardContentSx = {
    overflow: 'hidden',
    margin: '0px 16px 0px 16px',
    padding: 0,
    height: '100%',
};

export interface SectionConfiguratorProps {
    contentModule?: ContentModuleResponse;
    content: ContentResponse;
    section: SectionResponse;
    onAddContentModule: (newContentModule: ContentModuleResponse) => void;
    onRemoveContentModule: (removedContentModuleId: string) => void;
    onSelectContentModule: (
        contentModule: string | ContentModuleResponse
    ) => void;
}

function SectionConfigurator({
    content,
    section,
    contentModule,
    onAddContentModule,
    onRemoveContentModule,
    onSelectContentModule,
}: SectionConfiguratorProps) {
    const [shouldSelectContentModule, setShouldSelectContentModule] =
        useState<boolean>(false);

    // suppression du ContentModule
    const [deleteItem, isDeleting] = useDeleteContentModule(
        onRemoveContentModule
    );
    const handleDelete = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();

            if (!contentModule?.id) {
                return;
            }

            if (
                // eslint-disable-next-line no-alert
                !window.confirm(
                    'Êtes-vous sûr de vouloir supprimer ce contenu ?'
                )
            ) {
                return;
            }

            deleteItem(contentModule.id);
        },
        [deleteItem, contentModule]
    );

    // création du ContentModule
    const handleCreated = useCallback(
        (createdItem: ContentModuleResponse, { droppedModule }: any) => {
            onAddContentModule({
                id: createdItem.id,
                content,
                section,
                module: droppedModule,
                settingValues: { collection: [] },
            });
            setShouldSelectContentModule(true);
        },
        [content, section, onAddContentModule, setShouldSelectContentModule]
    );
    const [createItem, isCreating] = useCreateContentModule(handleCreated);
    const handleDrop = useCallback(
        (droppedModule: ModuleResponse) => {
            createItem(
                {
                    content: entityToId(content) || undefined,
                    section: entityToId(section) || undefined,
                    module: entityToId(droppedModule) || undefined,
                },
                { droppedModule }
            );
        },
        [createItem, content, section]
    );

    useEffect(() => {
        if (shouldSelectContentModule && contentModule?.id) {
            onSelectContentModule(contentModule);
            setShouldSelectContentModule(false);
        }
    }, [contentModule, shouldSelectContentModule, onSelectContentModule]);

    return (
        <Box sx={rootSx(section)}>
            {contentModule && (
                <Card
                    sx={cardSx}
                    onClick={() => {
                        if (!isDeleting) {
                            onSelectContentModule(contentModule);
                        }
                    }}
                    data-testid="contentModuleCard"
                >
                    <CardHeader
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        sx={{
                            padding: '8px 16px 0px 16px',
                        }}
                        title={contentModule.module?.label}
                        action={
                            <IconButton
                                aria-label="Supprimer"
                                color="error"
                                onClick={handleDelete}
                                disabled={isDeleting || !contentModule.id}
                                data-testid="deleteContentModule"
                                size="small"
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        }
                    />
                    <CardContent sx={cardContentSx}>
                        <ContentModulePreview contentModule={contentModule} />
                    </CardContent>
                </Card>
            )}
            {!contentModule && !isCreating && (
                <ModuleDropzone onDrop={handleDrop} />
            )}
        </Box>
    );
}

export default SectionConfigurator;
