import ModuleResponse from '@acdc/shared/src/features/module/ModuleResponse';
import ModuleCategoryResponse from '@acdc/shared/src/features/moduleCategory/ModuleCategoryResponse';
import ListItemCollapse from '@acdc/shared/src/ui/ListItemCollapse';
import { Box, Grid, List } from '@mui/material';
import React from 'react';
import DraggableModule from './DraggableModule';
import DraggableModulesListSkeleton from './DraggableModulesListSkeleton';

export interface DraggableModulesListProps {
    moduleCategories: ModuleCategoryResponse[] | null;
}

/**
 * Liste des Modules. Chaque module peut être draggé et droppé dans un composant ModuleDropzone (de ContentTemplateConfigurator).
 */
function DraggableModulesList({ moduleCategories }: DraggableModulesListProps) {
    if (!moduleCategories) {
        return <DraggableModulesListSkeleton />;
    }

    return (
        <List sx={{ backgroundColor: 'grey.900', py: 0 }}>
            {moduleCategories.map((category: ModuleCategoryResponse) => (
                <ListItemCollapse
                    key={category.id}
                    primary={category.label}
                    primaryTypographyProps={{
                        variant: 'h6',
                    }}
                    startOpen
                >
                    <Box
                        sx={{
                            padding: 1,
                            backgroundColor: 'background.default',
                        }}
                    >
                        <Grid container spacing={1}>
                            {category.modules?.collection?.map(
                                (module: ModuleResponse) => (
                                    <Grid key={module.id} item xs={12} md={4}>
                                        <DraggableModule module={module} />
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Box>
                </ListItemCollapse>
            ))}
        </List>
    );
}

export default DraggableModulesList;
