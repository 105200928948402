/**
 * Une sépquence à incrémenter pour avoir un id unique.
 */
let idSequence: number = 0;

/**
 * Génère un nouvel id unique chaque fois que la fonction est appelée. Attention, cette unicité n'est pas universel, ce n'est pas un uuid, juste un id unique dans l'instance de l'application courante.
 * Si besoin d'un hook: https://react.dev/reference/react/useId
 */
function generateLocalUniqueId(): string {
    idSequence += 1;
    return `${idSequence}`;
}

export default generateLocalUniqueId;
