import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListContentsResponse from '@acdc/shared/src/features/content/ListContentsResponse';

export const GET_CONTENTS = gql`
    query GetContents {
        contents(itemsPerPage: 10000) {
            collection {
                id
                title
                isTool
            }
        }
    }
`;

const useContents = () => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<ListContentsResponse>(
        GET_CONTENTS,
        {
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des pages.',
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default useContents;
