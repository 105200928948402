import { gql } from '@apollo/client';

const ConfiguredSettingValueFragment = gql`
    fragment ConfiguredSettingValue on SettingValue {
        id
        value
        file {
            id
            publicPath
            originalName
            size
        }
        sharedFile {
            id
            title
            publicPath
        }
        moduleSetting {
            code
            fieldType
            id
            label
        }
    }
`;

export default ConfiguredSettingValueFragment;
