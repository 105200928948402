import FieldType from '@acdc/shared/src/features/moduleSetting/FieldType.enum';

const isFileField = (fieldtype: string | undefined): boolean => {
    if (fieldtype === FieldType.IMAGE || fieldtype === FieldType.FILE) {
        return true;
    }
    return false;
};

export default isFileField;
