import Content from '@acdc/shared/src/features/content/Content.model';
import React, { useCallback, useState } from 'react';
import DuplicateContentButton, {
    DuplicateContentButtonProps,
} from './DuplicateContentButton';
import MultiStepContentFormDialog from '../ContentForm/MultiStepContentFormDialog';

export interface DuplicateContentButtonAndUpdateProps
    extends Omit<DuplicateContentButtonProps, 'onSuccess'> {}

/**
 * Duplique un Content puis ouvre sa dialog de modification.
 */
function DuplicateContentButtonAndUpdate(
    props: DuplicateContentButtonAndUpdateProps
) {
    const [duplicatedContent, setDuplicatedContent] = useState<
        Partial<Content> | undefined
    >();

    const handleContentDuplicated = useCallback(
        (contentId: string) => {
            setDuplicatedContent({ id: contentId });
        },
        [setDuplicatedContent]
    );

    return (
        <>
            <DuplicateContentButton
                {...props}
                onSuccess={handleContentDuplicated}
            />
            {duplicatedContent && (
                <MultiStepContentFormDialog
                    id="DuplicateContentButtonAndUpdate"
                    customProps={{
                        value: duplicatedContent,
                    }}
                    open
                    onClose={() => setDuplicatedContent(undefined)}
                    mainTitle="Modifier une page" // remplacé dans MultiStepContentFormDialog
                >
                    {null}
                </MultiStepContentFormDialog>
            )}
        </>
    );
}

export default DuplicateContentButtonAndUpdate;
