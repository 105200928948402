import JwtPayload from './JwtPayload';

const parseJwtPayload = (jwt: string): JwtPayload => {
    const jwtParts: string[] = jwt.split('.');
    if (jwtParts.length !== 3) {
        throw Error('Invalid jwt.');
    }

    const payload: string = window.atob(jwtParts[1]);

    return JSON.parse(payload) as JwtPayload;
};

export default parseJwtPayload;
