import React from 'react';
import { FieldProps } from 'formik';
import Wysiwyg, { WysiwygProps } from './Wysiwyg';

// inspiré de https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/TextField.tsx

export interface FormikWysiwygProps
    extends FieldProps,
        Omit<WysiwygProps, 'name' | 'value'> {}

function fieldToWysiwyg({ field }: FormikWysiwygProps) {
    return {
        value: field.value,
        onChange: field.onChange,
        name: field.name,
    };
}

function FormikWysiwyg(props: FormikWysiwygProps) {
    return <Wysiwyg {...fieldToWysiwyg(props)} />;
}

export default FormikWysiwyg;
