import { gql } from '@apollo/client';

const ConfiguredVariableFragment = gql`
    fragment ConfiguredVariable on Variable {
        id
        code
        label
        type
        isTextMulti
        maxChar
        maxLine
        defaultValue
        propertyField
    }
`;

export default ConfiguredVariableFragment;
