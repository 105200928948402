import Chapter from '@acdc/shared/src/features/chapter/Chapter.model';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import { ApiFile } from '@acdc/shared/src/ui/Dropzone';
import { initString } from '@acdc/shared/src/utils/form-helpers';
import { useEffect, useMemo } from 'react';
import CONFIGS from '../../../configs';
import type { ChapterFormValue } from './ChapterForm';
import useLazyChapterToUpdate from './useLazyChapterToUpdate';

const initValue = (value: DeepPartial<Chapter>): ChapterFormValue => ({
    title: initString(value?.title),
    subTitle: initString(value?.subTitle),
    chapterImage: [],
});

const useInitialValues = (
    value: DeepPartial<Chapter> | undefined
): {
    initialValues: ChapterFormValue | null;
    initialChapterImages: ApiFile[] | undefined;
    chapter: ChapterResponse | undefined;
} => {
    const isUpdate: boolean = !!value?.id;

    // chargement de la valeur de l'api si besoin
    const [query, { data: chapterResponse }] = useLazyChapterToUpdate(
        value?.id
    );
    useEffect(() => {
        if (isUpdate) {
            query();
        }
    }, [query, isUpdate]);
    const chapterFromResponse = useMemo<
        DeepPartial<Chapter> | undefined
    >(() => {
        if (!chapterResponse?.chapter) {
            return undefined;
        }

        // convertit le ChapterResponse reçu de l'api en un DeepPartial<Chapter> attendu par le form
        return {
            id: chapterResponse.chapter.id,
            title: chapterResponse.chapter.title,
            subTitle: chapterResponse.chapter.subTitle,
        };
    }, [chapterResponse?.chapter]);

    const initialValue = isUpdate ? chapterFromResponse : value;

    const initialFormValues: ChapterFormValue = useMemo(
        () => initValue(initialValue || {}),
        [initialValue]
    );

    const isLoaded: boolean = isUpdate ? Boolean(chapterResponse) : true;

    const initialChapterImages = useMemo<ApiFile[] | undefined>(() => {
        if (isLoaded && chapterResponse?.chapter?.chapterImage) {
            const apiUrl = CONFIGS.apiEntrypoint;
            const filePath = chapterResponse?.chapter?.chapterImage.publicPath;
            return [
                {
                    ...chapterResponse?.chapter?.chapterImage,
                    publicUrl: `${apiUrl}${filePath}`,
                },
            ];
        }

        return undefined;
    }, [isLoaded, chapterResponse?.chapter?.chapterImage]);

    return {
        initialValues: isLoaded ? initialFormValues : null,
        initialChapterImages,
        chapter: chapterResponse?.chapter || undefined,
    };
};

export default useInitialValues;
