import React from 'react';
import { FieldProps } from 'formik';
import { fieldToToggleButtonGroup } from 'formik-mui';
import SharedFileSelect, { SharedFileSelectProps } from './SharedFileSelect';

export interface FormikSharedFileSelectProps
    extends FieldProps,
        Omit<SharedFileSelectProps, 'name' | 'value' | 'error'> {
    type?: string;
}

/**
 * Ajoutez type=checkbox et utilisez un valeur de type array pour ce composant avec Field.
 * Voir https://stackworx.github.io/formik-mui/docs/api/mui#togglebuttongroup
 */
function FormikSharedFileSelect({
    sharedFiles,
    apiEntrypoint,
    colorSelect,
    ...props
}: FormikSharedFileSelectProps) {
    return (
        <SharedFileSelect
            {...fieldToToggleButtonGroup(props)}
            sharedFiles={sharedFiles}
            apiEntrypoint={apiEntrypoint}
            colorSelect={colorSelect}
        />
    );
}

export default FormikSharedFileSelect;
