import NotificationResponse from '@acdc/shared/src/features/notification/NotificationResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { useMutation, gql } from '@apollo/client';
import { useCallback, useState } from 'react';

export const DELETE_NOTIFICATION = gql`
    mutation DeleteNotification($id: ID!) {
        deleteNotification(input: { id: $id }) {
            clientMutationId
        }
    }
`;

function useDeleteNotification(
    onDeleted?: (notificationId: string) => void
): [(notification: string | NotificationResponse) => void, boolean] {
    const [deleteNotification] = useMutation(DELETE_NOTIFICATION, {
        update(cache) {
            cache.evict({ fieldName: 'notifications' });
        },
    });
    const setAlert = useAlert();
    const [isDeleting, setIsDeleteting] = useState<boolean>(false);

    const deleteItem = useCallback(
        (notification: string | NotificationResponse) => {
            const id = entityToId(notification);

            if (!id) {
                return;
            }

            setIsDeleteting(true);

            deleteNotification({ variables: { id } })
                .finally(() => {
                    setIsDeleteting(false);
                })
                .then(() => {
                    onDeleted && onDeleted(id);
                })
                .catch(() => {
                    setAlert(
                        "Une erreur est survenue lors de la suppression d'une notification.",
                        'error'
                    );
                });
        },
        [onDeleted, setIsDeleteting, deleteNotification, setAlert]
    );

    return [deleteItem, isDeleting];
}

export default useDeleteNotification;
