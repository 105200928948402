import React from 'react';
import ButtonWithLoader, { ButtonWithLoaderProps } from './ButtonWithLoader';

export interface SubmitButtonProps {
    children?: React.ReactNode;
    isSubmitting?: boolean;
    buttonProps?: { [buttonProps: string]: any };
    wrapperSx?: ButtonWithLoaderProps['wrapperSx'];
}

function SubmitButton({
    children,
    isSubmitting,
    buttonProps,
    wrapperSx,
}: SubmitButtonProps) {
    return (
        <ButtonWithLoader
            type="submit"
            disabled={isSubmitting}
            variant="contained"
            color="primary"
            wrapperSx={wrapperSx}
            {...buttonProps}
        >
            {children || 'Valider'}
        </ButtonWithLoader>
    );
}

export default SubmitButton;
