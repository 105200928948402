import React, { useEffect, useCallback, useMemo } from 'react';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { gql, useLazyQuery } from '@apollo/client';
import useDebounce from '@acdc/shared/src/utils/useDebounce';
import Autocomplete, {
    AutocompleteProps,
} from '@acdc/shared/src/formik/Autocomplete';
import ListUsersResponse from '@acdc/shared/src/features/user/ListUsersResponse';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';

const noJsFilter = (x: any) => x;

const getOptionLabel = (option: UserResponse) =>
    `${option.firstname} ${option.lastname} - ${option.code}`;

export const GET_USERS = gql`
    query SearchUsers($search: String!) {
        users(
            itemsPerPage: 100
            order: [{ firstname: "ASC" }, { lastname: "ASC" }, { code: "ASC" }]
            search: $search
            searchOn: ["code", "firstname", "lastname"]
        ) {
            collection {
                id
                code
                firstname
                lastname
            }
        }
    }
`;

function UserSelect({ ...props }: AutocompleteProps) {
    const setAlert = useAlert();
    const [query, { loading, data, error, called }] =
        useLazyQuery<ListUsersResponse>(GET_USERS, {
            fetchPolicy: 'network-only',
        });
    const options = useMemo(() => data?.users?.collection || [], [data]);
    const loadOptions = useCallback(
        (event: any) => {
            query({
                variables: {
                    search: `${event.target.value}`,
                },
            });
        },
        [query]
    );
    const [, onDirectValueChange] = useDebounce('', loadOptions);

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des collaborateurs.',
                'error'
            );
        }
    }, [error, setAlert]);

    const onInputChange = useCallback(
        (event: any) => {
            if (event?.target?.value) {
                onDirectValueChange(event);
            }
        },
        [onDirectValueChange]
    );

    return (
        <Autocomplete
            {...props}
            options={options}
            getOptionLabel={getOptionLabel}
            loading={loading}
            isOptionEqualToValue={matchOnId}
            filterOptions={noJsFilter}
            onInputChange={onInputChange}
            noOptionsText={
                called ? 'Pas de résultats' : 'Saisissez votre recherche'
            }
        />
    );
}

export default UserSelect;
