import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import GetPresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/GetPresentationFlowResponse';

export const GET_PRESENTATION_FLOW_DETAILS = gql`
    query GetPresentationFlowDetails($id: ID!) {
        presentationFlow(id: $id) {
            id
            label
        }
    }
`;

/**
 * Retourne les infos de base d'un déroulé
 */
const usePresentationFlowDetail = (id: string) => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<GetPresentationFlowResponse>(
        GET_PRESENTATION_FLOW_DETAILS,
        {
            variables: { id },
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement du déroulé.',
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default usePresentationFlowDetail;
