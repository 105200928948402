import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Notifications from './Notifications';

function NotificationsPages() {
    return (
        <Routes>
            <Route index element={<Notifications />} />
            <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
    );
}

export default NotificationsPages;
