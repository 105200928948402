import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import ToolFormDialogButton from '../../features/tool/ToolForm/ToolFormDialogButton';
import ToolsFilter from '../../features/tool/ToolsList/ToolsFilter';

function Tools() {
    const navigate = useNavigate();
    const handleToolCreated = useCallback(
        (tool: ToolResponse) => {
            navigate(`/outils/${getIdFromIri(entityToId(tool))}/configurer`);
        },
        [navigate]
    );

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    mb: 4,
                    mx: 4,
                }}
            >
                <Typography variant="h2" component="h1">
                    Mes outils
                </Typography>
                <Box>
                    <ToolFormDialogButton
                        id="create-tool-dialog"
                        buttonProps={{ color: 'primary', variant: 'contained' }}
                        onSuccess={handleToolCreated}
                    >
                        Créer un outil{' '}
                        <AddCircleOutlineIcon fontSize="small" sx={{ ml: 1 }} />
                    </ToolFormDialogButton>
                </Box>
            </Box>
            <ToolsFilter />
        </>
    );
}

export default Tools;
