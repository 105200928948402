import React from 'react';
import { FormHelperText } from '@mui/material';

interface ErrorHelperTextProps {
    children?: React.ReactNode;
    [props: string]: any;
}

function ErrorHelperText({ children, ...props }: ErrorHelperTextProps) {
    return (
        <FormHelperText error {...props}>
            {children}
        </FormHelperText>
    );
}

export default ErrorHelperText;
