import ListNotificationStatusesResponse from '@acdc/shared/src/features/notificationStatus/ListNotificationStatusesResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

export const GET_NOTIFICATION_STATUS = gql`
    query GetNotificationStatus {
        notificationStatuses {
            collection {
                id
                code
                label
                color
            }
        }
    }
`;
const useQueryNotificationStatuses = () => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<ListNotificationStatusesResponse>(
        GET_NOTIFICATION_STATUS
        // pas besoin de fetchPolicy: 'network-only',
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des status de notification.',
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default useQueryNotificationStatuses;
