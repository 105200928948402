import { Box } from '@mui/material';
import React from 'react';

const rootSx = {
    px: 2,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
};

export interface DragAndDropListProps {
    children?: React.ReactNode;
}

function DragAndDropList({ children }: DragAndDropListProps) {
    return <Box sx={rootSx}>{children}</Box>;
}

export default DragAndDropList;
