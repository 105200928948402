/**
 * Style par défaut de la valeur du Wysiwyg.
 * Doit correspondre à l'option content_style donné à l'initialisation de tinymce.
 */
const DEFAULT_WYSIWYG_STYLE = {
    fontFamily: 'Lexend',
    color: '#000000',
    fontSize: '100%',
    '& h1, & h2, & h3, & h4, & h5, & h6': {
        margin: 0,
    },
    '& p': {
        my: '1em',
    },
    '& ul, & ol': {
        pl: '2.5em',
        my: '0.5em',
    },
};

export default DEFAULT_WYSIWYG_STYLE;
