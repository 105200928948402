import React from 'react';
import { Box } from '@mui/material';
import ConnexionForm from './ConnexionForm';
import UserResponse from '../user/UserResponse';
import LocalStorageKeys from '../../apollo/LocalStorageKeys';
import UserDataStorageKeys from '../../security/UserDataStorageKeys';
import AuthLayout, { AuthLayoutProps } from './AuthLayout';
import useOnLoginSuccess from './useOnLoginSuccess';

interface ConnexionPageProps {
    authStorageKeys: LocalStorageKeys;
    userDataStorageKeys: UserDataStorageKeys;
    isAllowed?: (user: UserResponse) => boolean;
    sx?: AuthLayoutProps['sx'];
}

function ConnexionPage({
    isAllowed,
    sx,
    authStorageKeys,
    userDataStorageKeys,
}: ConnexionPageProps) {
    const onLoginSuccess = useOnLoginSuccess(
        authStorageKeys,
        userDataStorageKeys,
        isAllowed
    );

    return (
        <AuthLayout title="Connexion" sx={sx}>
            <Box width="75%">
                <ConnexionForm onSuccess={onLoginSuccess} />
            </Box>
        </AuthLayout>
    );
}

export default ConnexionPage;
