import React from 'react';
import { useFormikContext } from 'formik';
import UiSubmitButton, { SubmitButtonProps } from '../ui/SubmitButton';

function SubmitButton(props: SubmitButtonProps) {
    const { isSubmitting } = useFormikContext();

    return <UiSubmitButton isSubmitting={isSubmitting} {...props} />;
}

export default SubmitButton;
