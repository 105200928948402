import Chapter from '@acdc/shared/src/features/chapter/Chapter.model';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
} from '@apollo/client';
import MutateChapterResponse from '@acdc/shared/src/features/chapter/MutateChapterResponse';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { entityToId, filterString } from '@acdc/shared/src/utils/form-helpers';
import { FormikHelpers } from 'formik';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import CreateChapterImageResponse from '@acdc/shared/src/features/chapterImage/CreateChapterImageResponse';
import type { ChapterFormProps, ChapterFormValue } from './ChapterForm';
import getChapterFormSchema from './getChapterFormSchema';

export const CREATE_CHAPTER = gql`
    mutation CreateChapter($input: createChapterInput!) {
        createChapter(input: $input) {
            chapter {
                id
            }
        }
    }
`;

export const UPDATE_CHAPTER = gql`
    mutation UpdateChapter($input: updateChapterInput!) {
        updateChapter(input: $input) {
            chapter {
                id
            }
        }
    }
`;

export const CREATE_CHAPTER_IMAGE = gql`
    mutation CreateChapterImage($input: createChapterImageInput!) {
        createChapterImage(input: $input) {
            chapterImage {
                id
            }
        }
    }
`;

function doSubmit(
    { chapterImage, ...formValues }: ChapterFormValue,
    initialValue: DeepPartial<Chapter> | undefined,
    mutate: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<MutateChapterResponse>>,
    createChapterImage: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateChapterImageResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void
) {
    return (
        Promise.resolve()
            // upload des fichiers à créer d'abord
            .then(() => {
                const chapterImageFile: File | null = chapterImage[0] || null;

                const promises: [
                    Promise<FetchResult<CreateChapterImageResponse>> | null
                ] = [
                    chapterImageFile
                        ? createChapterImage({
                              variables: {
                                  input: {
                                      file: chapterImageFile,
                                  },
                              },
                          })
                        : null,
                ];

                return Promise.all(promises).catch((err: ApolloError) => {
                    handleError(
                        err?.graphQLErrors?.[0]?.path?.[0] ===
                            'createChapterImage'
                            ? 'chapterImage'
                            : undefined
                    )(err);

                    throw Error('cancelled');
                });
            })
            // préparation du Chapter à créer
            .then(([chapterImageRes]) => {
                const input: DeepPartial<Chapter> = {
                    ...formValues,
                    subTitle: filterString(formValues.subTitle),
                };

                if (initialValue?.presentationFlow) {
                    input.presentationFlow =
                        entityToId(initialValue.presentationFlow) || undefined;
                }

                if (initialValue?.id) {
                    input.id = initialValue.id;
                }

                const chapterImageId: string | undefined =
                    chapterImageRes?.data?.createChapterImage?.chapterImage?.id;
                if (chapterImageId) {
                    input.chapterImage = chapterImageId;
                }

                return input;
            })
            // création du Chapter
            .then((input) =>
                mutate({ variables: { input } }).catch((err: ApolloError) => {
                    handleError()(err);

                    throw Error('cancelled');
                })
            )
    );
}

const useSubmit = (
    initialValue: ChapterFormProps['value'],
    onSuccess: ChapterFormProps['onSuccess'],
    onError: ChapterFormProps['onError']
) => {
    const isUpdate: boolean = !!initialValue?.id;
    const setAlert = useAlert();
    const [mutate] = useMutation(isUpdate ? UPDATE_CHAPTER : CREATE_CHAPTER, {
        update(cache) {
            cache.evict({ fieldName: 'chapters' });
        },
    });
    const [createChapterImage] = useMutation(CREATE_CHAPTER_IMAGE, {
        update(cache) {
            cache.evict({ fieldName: 'chapterImages' });
        },
    });

    return useCallback(
        (
            formValues: ChapterFormValue,
            { setSubmitting, setErrors }: FormikHelpers<ChapterFormValue>
        ) => {
            doSubmit(
                formValues,
                initialValue,
                mutate,
                createChapterImage,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        getChapterFormSchema(),
                        onError,
                        overrideFormikKey
                    )
            )
                .then((res: FetchResult<MutateChapterResponse>) => {
                    const resItem: ChapterResponse | null | undefined = isUpdate
                        ? res.data?.updateChapter?.chapter
                        : res.data?.createChapter?.chapter;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }

                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            initialValue,
            onSuccess,
            onError,
            setAlert,
            mutate,
            createChapterImage,
            isUpdate,
        ]
    );
};

export default useSubmit;
