import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import ListChaptersResponse from '@acdc/shared/src/features/chapter/ListChaptersResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';

export const GET_PRESENTATION_FLOW_CHAPTERS = gql`
    query GetPresentationFlowChapters($presentationCode: String!) {
        chapters(
            presentationFlow_code: $presentationCode
            order: [{ id: "ASC" }]
            itemsPerPage: 10000
        ) {
            collection {
                id
                title
                position
                chapterContents(
                    order: [{ position: "ASC" }]
                    itemsPerPage: 10000
                ) {
                    collection {
                        id
                        position
                        content {
                            id
                            title
                        }
                    }
                }
            }
        }
    }
`;

const usePresentationFlowChapters = (
    presentationFlow: string | { id: string }
) => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<ListChaptersResponse>(
        GET_PRESENTATION_FLOW_CHAPTERS,
        {
            variables: {
                presentationCode: getIdFromIri(entityToId(presentationFlow)),
            },
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des chapitres du déroulé.',
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default usePresentationFlowChapters;
