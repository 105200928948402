import { Box } from '@mui/material';
import React from 'react';
import ShortTextIcon from '@mui/icons-material/ShortText';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const absoluteCenteredSx = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
};

const containerSx = {
    width: 40,
    height: 40,
    position: 'relative',
    border: '3px solid',
    borderColor: 'primary.main',
    borderRadius: '4px',
    '&:before': {
        content: '""',
        height: 40,
        width: 20,
        backgroundColor: 'grey.800',
        ...absoluteCenteredSx,
    },
    '&:after': {
        content: '""',
        height: 20,
        width: 40,
        backgroundColor: 'grey.800',
        ...absoluteCenteredSx,
    },
};

const iconSx = {
    ...absoluteCenteredSx,
    fontSize: 34,
    zIndex: 1,
};

const getIconComponent = (icon: string) => {
    switch (icon) {
        case 'ShortTextIcon':
            return ShortTextIcon;
        case 'ViewInArIcon':
            return ViewInArIcon;
        case 'ImageIcon':
            return ImageIcon;
        case 'PdfIcon':
            return PictureAsPdfIcon;
        default:
            return TextFieldsIcon;
    }
};

export type ModuleIconProps = {
    icon: string;
};

function ModuleIcon({ icon }: ModuleIconProps) {
    const IconComponent = getIconComponent(icon);

    return (
        <Box sx={containerSx}>
            {/* <CropFreeIcon sx={containerIconSx} /> */}
            <IconComponent color="primary" sx={iconSx} />
        </Box>
    );
}

export default ModuleIcon;
