import React, { useMemo } from 'react';
import Autocomplete, {
    AutocompleteProps,
} from '@acdc/shared/src/formik/Autocomplete';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import ToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ToolCategoryResponse';
import useQueryToolCategoriesToSelect from './useQueryToolCategoriesToSelect';

function getOptionLabel(
    option: string | Pick<ToolCategoryResponse, 'id' | 'label'> | null
): string {
    if (!option) {
        return '';
    }

    return typeof option === 'string' ? option : `${option.label}`;
}

function ToolCategorySelect(props: AutocompleteProps) {
    const { data: toolCategoriesResponse, loading } =
        useQueryToolCategoriesToSelect();
    const options = useMemo(
        () => toolCategoriesResponse?.toolCategories?.collection || [],
        [toolCategoriesResponse]
    );

    return (
        <Autocomplete
            {...props}
            options={options}
            disableClearable
            getOptionLabel={getOptionLabel}
            loading={loading}
            isOptionEqualToValue={matchOnId}
            noOptionsText="Pas de résultats"
        />
    );
}

export default ToolCategorySelect;
