import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import useDeleteVariable from './useDeleteVariable';

export interface DeleteVariableButtonProps
    extends Omit<ButtonProps, 'onClick'> {
    variable: string | VariableResponse;
    /**
     * Le code de la variable pour l'afficher dans le message de confirmation de suppression.
     */
    code?: string | undefined;
    onDeleted?: (variableId: string) => void;
    buttonComponent?: React.ComponentType<ButtonProps> | undefined;
}

function DeleteVariableButton({
    variable,
    code,
    buttonComponent,
    onDeleted,
    ...buttonProps
}: DeleteVariableButtonProps) {
    const [deleteVariable, isLoading] = useDeleteVariable(onDeleted);
    const ButtonComponent: React.ComponentType<ButtonProps> =
        buttonComponent || Button;

    return (
        <ButtonComponent
            color="error"
            {...buttonProps}
            onClick={() => {
                const title = code ? ` "{{${code}}}"` : '';
                if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                        `Êtes-vous sûr de vouloir supprimer la balise${title} ?`
                    )
                ) {
                    deleteVariable(variable);
                }
            }}
            disabled={isLoading}
            type="button"
        />
    );
}

export default DeleteVariableButton;
