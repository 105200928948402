import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import TemplateResponse from '@acdc/shared/src/features/template/TemplateResponse';
import GetTemplateResponse from '@acdc/shared/src/features/template/GetTemplateResponse';

export const GET_TEMPLATE = gql`
    query GetTemplate($id: ID!) {
        template(id: $id) {
            id
            title
            sections(itemsPerPage: 10000) {
                collection {
                    id
                    height
                    width
                    row
                    col
                }
            }
        }
    }
`;
/**
 * Charge un Template avec toutes les sections.
 */
const useTemplate = (
    templateId: string | undefined
): TemplateResponse | null | undefined => {
    const setAlert = useAlert();

    const { data, error } = useQuery<GetTemplateResponse>(GET_TEMPLATE, {
        variables: { id: templateId },
        fetchPolicy: 'network-only',
        skip: !templateId,
    });

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement du template et de ses sections.',
                'error'
            );
        }
    }, [error, setAlert]);

    return data?.template;
};

export default useTemplate;
