import React from 'react';
import { useDrop } from 'react-dnd';
import { Box } from '@mui/system';
import ModuleResponse from '@acdc/shared/src/features/module/ModuleResponse';
import { DROPPABLE_MODULE } from '../DraggableModulesList';

const containerSx = {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export interface ModuleDropzoneProps {
    onDrop: (module: ModuleResponse) => void;
}

function ModuleDropzone({ onDrop }: ModuleDropzoneProps) {
    const [, drop] = useDrop<ModuleResponse, void, unknown>(
        () => ({
            accept: DROPPABLE_MODULE,
            drop: (item: ModuleResponse) => onDrop(item),
        }),
        [onDrop]
    );

    return (
        <Box ref={drop} data-testid="moduleDropzone" sx={containerSx}>
            <Box component="p" textAlign="center" color="text.secondary">
                Déposer ici un <b>Contenu textuel</b> ou
                <br /> un <b>Média</b> dans cette section
            </Box>
        </Box>
    );
}

export default ModuleDropzone;
