import Content from '@acdc/shared/src/features/content/Content.model';
import PresentationFlow from '@acdc/shared/src/features/presentationFlow/PresentationFlow.model';
import { initString } from '@acdc/shared/src/utils/form-helpers';
import { useEffect, useMemo } from 'react';
import type { PresentationFlowFormValue } from './PresentationFlowForm';
import useLazyPresentationFlowToUpdate from './useLazyPresentationFlowToUpdate';

const initValue = (
    value: DeepPartial<PresentationFlow>
): PresentationFlowFormValue => ({
    label: initString(value?.label),
    code: initString(value?.code),
    coverPage: value?.coverPage || null,
});

const useInitialValues = (
    value: DeepPartial<PresentationFlow> | undefined
): PresentationFlowFormValue | null => {
    const isUpdate: boolean = !!value?.id;

    // chargement de la valeur de l'api si besoin
    const [query, { data: presentationFlowResponse }] =
        useLazyPresentationFlowToUpdate(value?.id);
    useEffect(() => {
        if (isUpdate) {
            query();
        }
    }, [query, isUpdate]);

    const presentationFlowFromResponse = useMemo<
        DeepPartial<PresentationFlow> | undefined
    >(() => {
        if (!presentationFlowResponse?.presentationFlow) {
            return undefined;
        }
        return {
            id: presentationFlowResponse?.presentationFlow?.id,
            label: presentationFlowResponse?.presentationFlow?.label,
            code: presentationFlowResponse?.presentationFlow?.code,
            coverPage: presentationFlowResponse?.presentationFlow?.coverPage as
                | Content
                | undefined,
        };
    }, [presentationFlowResponse?.presentationFlow]);

    const initialValue = isUpdate ? presentationFlowFromResponse : value;

    const initialFormValues: PresentationFlowFormValue = useMemo(
        () => initValue(initialValue || {}),
        [initialValue]
    );

    const isLoaded: boolean = isUpdate
        ? Boolean(presentationFlowResponse)
        : true;

    return isLoaded ? initialFormValues : null;
};

export default useInitialValues;
