import React, { useCallback, useMemo, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { TinyMCE, Editor as EditorModel } from 'tinymce';
import { Box } from '@mui/material';
import CONFIGS from '../configs';
import { lightTheme } from '../mui/theme';

/**
 * fonts requises pour le texte du Wysiwyg
 * à synchro avec :
 * - apps/bo/src/index.css
 * - apps/bo/src/ui/Wysiwyg.tsx
 * - apps/front/src/index.css
 */
const wysiwygFonts: string = `
    @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;600;800&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Condensed&display=swap');
`;

type EditorOptions = Parameters<TinyMCE['init']>[0] & {
    selector?: undefined;
    target?: undefined;
};

const extraBoldLabel = 'Extra gras';

const getEditorConfigs: (
    editorRef: React.MutableRefObject<Editor | null>
) => EditorOptions = (editorRef) => ({
    height: 600,
    language: 'fr_FR',
    menubar: false,
    plugins: 'image media link lists advlist fullscreen',
    contextmenu: false, // désactivation du menu custom au click droit
    toolbar: `
        undo redo |
        fullscreen |
        bold extrabold italic underline |
        fontfamily fontsize blocks |
        alignleft aligncenter alignright alignjustify |
        outdent indent |
        numlist bullist |
        forecolor |
        insertfile image media template link anchor codesample |
    `,
    toolbar_mode: 'wrap',
    // défaut: font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt'
    font_size_formats:
        '50% 66% 75% 80% 100% 115% 125% 133% 150% 200% 300% 400%',
    color_map: [
        '#ffffff',
        'Blanc',
        '#000000',
        'Noir',
        '#555554',
        'Gris Neutre',
        lightTheme.palette.primary.main,
        'Bleu Électrique',
        lightTheme.palette.primary.dark,
        'BleuCorporate',
    ],
    custom_colors: false,
    font_family_formats: `
        IBM Plex Sans Condensed=IBM Plex Sans Condensed;
        Lexend=Lexend;
    `,
    // en cas de changement des valeurs par défaut souhaités il faut aussi faire correspondre
    // @acdc/shared/src/features/settingValue/DEFAULT_WYSIWYG_STYLE
    content_style: `
        ${wysiwygFonts}
        body { font-family: Lexend; color: #000000; }
    `,
    formats: {
        bold: { inline: 'strong', styles: { 'font-weight': '600' } },
        extrabold: { inline: 'strong', styles: { 'font-weight': '800' } },
    },
    setup: (editor: EditorModel) => {
        editor.ui.registry.addToggleButton('extrabold', {
            text: 'B+',
            tooltip: extraBoldLabel,
            onAction: () => editor.formatter.toggle('extrabold'),
            onSetup: (api) => {
                api.setActive(editor.formatter.match('extrabold'));
                const changed = editor.formatter.formatChanged(
                    'extrabold',
                    (state) => api.setActive(state)
                );
                return () => changed.unbind();
            },
        });
    },
    elementpath: false,

    // https://www.tiny.cloud/docs/tinymce/6/image/#interactive-example
    file_picker_types: 'image',
    file_picker_callback: (cb) => {
        const editor = editorRef.current?.editor;
        if (!editor) {
            throw Error('Editor manquant');
        }

        const input: HTMLInputElement = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.addEventListener('change', (e) => {
            const file = (e?.target as HTMLInputElement | undefined)
                ?.files?.[0];

            if (!file) {
                cb();
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const id = `blobid${new Date().getTime()}`;
                const { blobCache } = editor.editorUpload;
                const base64 = reader.result?.toString().split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                cb(blobInfo.blobUri(), { title: file.name });
            });
            reader.readAsDataURL(file);
        });

        input.click();
    },
});

const containerSx: any = {};
containerSx[
    `& .tox .tox-tbtn[aria-label="${extraBoldLabel}"] .tox-tbtn__select-label`
] = {
    fontWeight: '800',
    fontSize: '19px', // la même taille que l'icon du bouton bold
};

export interface WysiwygProps {
    value?: string;
    onChange?: (e: {
        target: { name: string | undefined; value: string };
    }) => void;
    name?: string;
}

function Wysiwyg({ value, onChange, name }: WysiwygProps) {
    const editorRef = useRef<Editor | null>(null);

    const handleEditorChange = useCallback(
        (val: string) => onChange && onChange({ target: { name, value: val } }),
        [name, onChange]
    );

    const init = useMemo(() => getEditorConfigs(editorRef), []);

    return (
        <Box sx={containerSx}>
            <Editor
                apiKey={CONFIGS.tinymceApiKey}
                init={init}
                value={value}
                onEditorChange={handleEditorChange}
                textareaName={name}
                ref={editorRef}
            />
        </Box>
    );
}

export default Wysiwyg;
