import SecurityRole from '../features/user/SecurityRole.enum';
import { RolesHierarchy } from './isGranted';

// à delete si on réutilise security dans un autre projet

const ACDC_ROLES_HIERARCHY: RolesHierarchy = {
    [SecurityRole.ROLE_COLLABORATOR]: [SecurityRole.ROLE_USER],
    [SecurityRole.ROLE_FRANCHISEE]: [SecurityRole.ROLE_COLLABORATOR],
    [SecurityRole.ROLE_CONSULTANT]: [SecurityRole.ROLE_FRANCHISEE],
    [SecurityRole.ROLE_ADMIN_HEADQUARTERS]: [SecurityRole.ROLE_CONSULTANT],
};

export default ACDC_ROLES_HIERARCHY;
