import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import useDeleteContent from './useDeleteContent';

export interface DeleteContentButtonProps extends Omit<ButtonProps, 'onClick'> {
    content: string | ContentResponse;
    onDeleted?: (contentId: string) => void;
    buttonComponent?: React.ComponentType<ButtonProps> | undefined;
}

function DeleteContentButton({
    content,
    buttonComponent,
    onDeleted,
    ...buttonProps
}: DeleteContentButtonProps) {
    const [deleteContent, isLoading] = useDeleteContent(onDeleted);
    const ButtonComponent: React.ComponentType<ButtonProps> =
        buttonComponent || Button;

    return (
        <ButtonComponent
            {...buttonProps}
            onClick={() => {
                const title =
                    typeof content !== 'string' && content.title
                        ? ` "${content.title}"`
                        : '';
                if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                        `Êtes-vous sûr de vouloir supprimer la page${title} ?`
                    )
                ) {
                    deleteContent(content);
                }
            }}
            disabled={isLoading}
        />
    );
}

export default DeleteContentButton;
