import React from 'react';
import { TablePagination as MUITablePagination } from '@mui/material';

export type RowsPerPage = 10 | 20 | 30 | 50 | 100;
const rowsPerPage: RowsPerPage[] = [10, 20, 30, 50, 100];

interface TablePaginationProps {
    setPagination: (page: number, itemsPerPage: RowsPerPage) => void;
    /**
     * La première page est la page 1.
     */
    page: number;
    itemsPerPage: RowsPerPage;
    totalCount: number;
}

function TablePagination({
    setPagination,
    page,
    itemsPerPage,
    totalCount,
}: TablePaginationProps) {
    const handleChangePage = (event: any, newPage: number) => {
        setPagination(newPage + 1, itemsPerPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setPagination(1, parseInt(event.target.value, 10) as RowsPerPage);
    };

    return (
        <MUITablePagination
            component="div"
            rowsPerPageOptions={rowsPerPage}
            count={totalCount}
            rowsPerPage={itemsPerPage}
            page={totalCount === 0 ? 0 : page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
    );
}

export default TablePagination;
