import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListContentModulesResponse from '@acdc/shared/src/features/contentModule/ListContentModulesResponse';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import ConfiguredSettingValueFragment from '../content/ContentConfigurator/ConfiguredSettingValueFragment';
import ConfiguredVariableFragment from '../content/ContentConfigurator/ConfiguredVariableFragment';

export const GET_CONTENT_MODULE = gql`
    ${ConfiguredSettingValueFragment}
    ${ConfiguredVariableFragment}
    query GetContentModules($content_id: Int!) {
        contentModules(content_id: $content_id) {
            collection {
                id
                section {
                    id
                }
                module {
                    id
                    code
                    label
                }
                settingValues(itemsPerPage: 10000) {
                    collection {
                        ...ConfiguredSettingValue
                        moduleSetting {
                            id
                            code
                            label
                            fieldType
                        }
                    }
                }
                variables(itemsPerPage: 10000, order: [{ code: "ASC" }]) {
                    collection {
                        ...ConfiguredVariable
                    }
                }
            }
        }
    }
`;
/**
 * Charge les contents modules d'un content avec ses dépendances
 */
const useContentModule = (
    contentIri: string | undefined
): ContentModuleResponse[] | null | undefined => {
    // transform l'iri en id
    // const contentId = parseInt(contentIri?.split('/')[2]!, 10);
    const contentId = parseInt(getIdFromIri(contentIri)!, 10);

    const setAlert = useAlert();
    const { data, error } = useQuery<ListContentModulesResponse>(
        GET_CONTENT_MODULE,
        {
            variables: { content_id: contentId },
            fetchPolicy: 'network-only',
            skip: !contentIri,
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement des contents modules d'un content.",
                'error'
            );
        }
    }, [error, setAlert]);

    return data?.contentModules?.collection;
};

export default useContentModule;
