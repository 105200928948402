import { Theme, createTheme, lighten } from '@mui/material';
import { frFR } from '@mui/material/locale';

// doc : https://mui.com/customization/theming/
// valeurs par défaut : https://mui.com/customization/default-theme/
// couleurs : https://mui.com/customization/color/

export const headerHeight: string = '48px';
export const pageDrawerWidth = '250px';

declare module '@mui/material/styles' {
    interface TypeBackground {
        rainbow: string;
    }
}

const baseTheme: Theme = createTheme(
    {
        palette: {
            primary: {
                main: '#1c5eeb',
                light: lighten('#1c5eeb', 0.3),
                dark: '#143d8a',
                contrastText: '#ffffff',
            },
            background: {
                rainbow: 'linear-gradient(to right, #2ac1ff, #adffe3)',
                paper: '#f6f8fe',
            },
        },
        shape: {
            borderRadius: 20,
        },
    },
    frFR
);

export const lightTheme = createTheme(baseTheme, {
    typography: {
        h1: {
            fontSize: '3rem',
            fontWeight: 'bold',
            marginTop: baseTheme.spacing(2),
            marginBottom: baseTheme.spacing(2),
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 'bold',
            marginTop: baseTheme.spacing(2),
            marginBottom: baseTheme.spacing(2),
        },
        h3: {
            fontSize: '1.6rem',
            fontWeight: 'bold',
        },
        h4: {
            fontSize: '1.3rem',
            fontWeight: 'bold',
        },
        h5: {
            fontSize: '1.2rem',
            fontWeight: 'bold',
        },
        h6: {
            fontSize: '1.1rem',
            fontWeight: 'bold',
        },
        subtitle1: {
            fontSize: '1.0rem',
            fontWeight: 'bold',
        },
        body1: {},
        body2: {},
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    height: headerHeight,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                    textTransform: 'unset',
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                size: 'small',
                fullWidth: true,
            },
        },
    },
});

export const darkTheme = createTheme(lightTheme, {
    palette: {
        text: {
            primary: '#ffffff',
            secondary: '#ffffff',
        },
        background: {
            default: '#33373a',
            paper: baseTheme.palette.grey[800],
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#161a1e',
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ffffff',
                    },
                    '& .MuiSvgIcon-root': {
                        color: '#ffffff',
                    },
                    // chips des select multiples ou autocompletes
                    '&.MuiOutlinedInput-root .MuiChip-filled.MuiChip-colorDefault':
                        {
                            backgroundColor: baseTheme.palette.grey[200],
                            color: baseTheme.palette.text.primary,
                            '& .MuiSvgIcon-root': {
                                color: baseTheme.palette.grey[500],
                            },
                        },
                },
            },
        },
    },
});
