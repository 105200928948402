import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import { Box, Divider, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import arrayUniq from '@acdc/shared/src/utils/arrayUniq';
import VariableForm from './VariableForm';
import { VariableFormProps } from './VariableForm/VariableForm';

interface WysiwygVariablesItemProps {
    variable: VariableResponse;
    contentModule: ContentModuleResponse;
    onVariableChange: WysiwygVariablesProps['onVariableChange'];
    onVariableDeleted: WysiwygVariablesProps['onVariableDeleted'];
    variableExistsInWysiwyg: boolean;
    topDivider: boolean;
}

function WysiwygVariablesItem({
    variable,
    contentModule,
    onVariableChange,
    onVariableDeleted,
    variableExistsInWysiwyg,
    topDivider,
}: WysiwygVariablesItemProps) {
    return (
        <Box mb={1}>
            {topDivider && <Divider />}
            <Typography variant="h4" mb={2} mt={1}>
                {'{{'}
                {variable.code}
                {'}}'}
                {!variableExistsInWysiwyg && (
                    <Typography
                        variant="body2"
                        component="span"
                        fontStyle="italic"
                        color="error"
                    >
                        balise supprimée du texte
                    </Typography>
                )}
            </Typography>
            <VariableForm
                value={variable}
                contentModule={contentModule}
                onSuccess={onVariableChange}
                onDeleted={onVariableDeleted}
                enableDeleteButton={!variableExistsInWysiwyg}
            />
        </Box>
    );
}

export interface WysiwygVariablesProps {
    contentModule: ContentModuleResponse;
    onVariableChange?: VariableFormProps['onSuccess'];
    onVariableDeleted?: VariableFormProps['onDeleted'];
}

function WysiwygVariables({
    contentModule,
    onVariableChange,
    onVariableDeleted,
}: WysiwygVariablesProps) {
    const existingVariables = useMemo(
        () => contentModule.variables?.collection || [],
        [contentModule]
    );

    const wysiwygValue = useMemo(() => {
        if (contentModule.module?.code !== ModuleCode.WYSIWYG) {
            return undefined;
        }

        return (
            contentModule.settingValues?.collection.find(
                (settingValue) =>
                    settingValue.moduleSetting?.code ===
                    ModuleSettingCode.WYSIWYG_VALUE
            )?.value || undefined
        );
    }, [contentModule]);

    const variableCodes: string[] = useMemo(
        () =>
            arrayUniq(
                wysiwygValue
                    ?.match(/{{[^}\r\n]+}}/g)
                    ?.map((match) => match.substring(2, match.length - 2)) || []
            ),
        [wysiwygValue]
    );

    const newVariables = useMemo(() => {
        return variableCodes.reduce<VariableResponse[]>(
            (curr, variableCode) => {
                if (
                    !existingVariables.find(
                        (variable) => variable.code === variableCode
                    )
                ) {
                    curr.push({
                        code: variableCode,
                    });
                }

                return curr;
            },
            []
        );
    }, [variableCodes, existingVariables]);

    const variables = useMemo(
        () =>
            [...existingVariables, ...newVariables].sort((v1, v2) => {
                return `${v1.code?.toLowerCase()}` > `${v2.code?.toLowerCase()}`
                    ? 1
                    : -1;
            }),
        [existingVariables, newVariables]
    );

    return (
        <>
            {variables.map((variable, ind) => (
                <WysiwygVariablesItem
                    key={`${variable.code}`}
                    variable={variable}
                    contentModule={contentModule}
                    onVariableChange={onVariableChange}
                    onVariableDeleted={onVariableDeleted}
                    variableExistsInWysiwyg={variableCodes.includes(
                        `${variable.code}`
                    )}
                    topDivider={ind !== 0}
                />
            ))}
        </>
    );
}

export default WysiwygVariables;
