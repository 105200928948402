import { Box, IconButton, Theme, Typography } from '@mui/material';
import React from 'react';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import ToolContentResponse from '@acdc/shared/src/features/toolContent/ToolContentResponse';
import BaseContentModel from '@acdc/shared/src/features/baseContent/BaseContent.model';
import { pageDrawerWidth } from '../../mui/theme';
import MultiStepContentFormDialogButton from '../../features/content/ContentForm/MultiStepContentFormDialogButton';

const rootSx = (isDragging: boolean, enableDeleteButton?: boolean) => {
    return {
        opacity: isDragging ? 0 : 1,
        borderRadius: 0.35,
        position: 'relative',
        borderLeftColor: (theme: Theme) => theme.palette.primary.main,
        borderLeftStyle: 'solid',
        borderLeftWidth: '3px',
        maxWidth: enableDeleteButton
            ? pageDrawerWidth
            : (theme: Theme) =>
                  `calc(${pageDrawerWidth} - ${theme.spacing(6)})`,
        cursor: 'grab',
        marginY: 0.5,
        backgroundColor: 'grey.800',
    };
};

const prefixIconSx = {
    transform: 'translate(0,2px)',
    marginX: 1,
};

export interface DragAndDropListItemProps {
    label: string;
    onDelete?: () => void;
    onEdit?: () => void;
    'data-testid'?: string;
    isDragging?: boolean;
    enableDeleteButton?: boolean;
    toolContent?: ToolContentResponse;
}

function DragAndDropListItem(
    {
        label,
        isDragging,
        enableDeleteButton,
        onDelete,
        onEdit,
        toolContent,
        ...props
    }: DragAndDropListItemProps,
    ref: React.Ref<HTMLDivElement>
) {
    const BaseContent = toolContent as unknown as DeepPartial<BaseContentModel>;

    return (
        <Box
            sx={rootSx(Boolean(isDragging), Boolean(enableDeleteButton))}
            ref={ref}
            data-testid={props['data-testid']}
        >
            <Box
                sx={{
                    padding: (theme: Theme) => theme.spacing(0.5),
                    verticalAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ display: 'flex' }}>
                    <InsertDriveFileOutlinedIcon
                        fontSize="small"
                        sx={prefixIconSx}
                    />
                    <Typography variant="body1" component="span">
                        {label}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    {BaseContent && (
                        <MultiStepContentFormDialogButton
                            id="update-tool-content-dialog"
                            type={ContentType.TOOL_CONTENT}
                            value={BaseContent}
                            buttonProps={{
                                color: 'background',
                                sx: {
                                    boxShadow: 'none',
                                    padding: 0,
                                    minWidth: 0,
                                },
                            }}
                        >
                            <EditIcon fontSize="small" />
                        </MultiStepContentFormDialogButton>
                    )}

                    {enableDeleteButton && (
                        <IconButton
                            aria-label="Supprimer"
                            data-testid="DragAndDropListItemDeleteButton"
                            onClick={onDelete}
                            size="small"
                            color="inherit"
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default React.forwardRef(DragAndDropListItem);
