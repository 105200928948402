import Tool from '@acdc/shared/src/features/tool/Tool.model';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
} from '@apollo/client';
import MutateToolResponse from '@acdc/shared/src/features/tool/MutateToolResponse';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import CreateToolThumbnailResponse from '@acdc/shared/src/features/toolThumbnail/CreateToolThumbnailResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import type { ToolFormProps, ToolFormValue } from './ToolForm';
import toolFormSchema from './tooFormSchema';

export const CREATE_TOOL = gql`
    mutation CreateTool($input: createToolInput!) {
        createTool(input: $input) {
            tool {
                id
            }
        }
    }
`;

export const UPDATE_TOOL = gql`
    mutation UpdateTool($input: updateToolInput!) {
        updateTool(input: $input) {
            tool {
                id
            }
        }
    }
`;

export const CREATE_TOOL_THUMBNAIL = gql`
    mutation CreateToolThumbnail($input: createToolThumbnailInput!) {
        createToolThumbnail(input: $input) {
            toolThumbnail {
                id
            }
        }
    }
`;

function doSubmit(
    { toolThumbnail, ...formValues }: ToolFormValue,
    initialValue: DeepPartial<Tool> | undefined,
    mutate: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<MutateToolResponse>>,
    createToolThumbnail: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateToolThumbnailResponse>>,
    shouldDeleteApiFile: boolean,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void
) {
    return (
        Promise.resolve()
            // upload des fichiers à créer d'abord
            .then(() => {
                const toolThumbnailFile: File | null = toolThumbnail[0] || null;

                const promises: [
                    Promise<FetchResult<CreateToolThumbnailResponse>> | null
                ] = [
                    toolThumbnailFile
                        ? createToolThumbnail({
                              variables: {
                                  input: {
                                      file: toolThumbnailFile,
                                  },
                              },
                          })
                        : null,
                ];

                return Promise.all(promises).catch((err: ApolloError) => {
                    handleError(
                        err?.graphQLErrors?.[0]?.path?.[0] ===
                            'createToolThumbnail'
                            ? 'toolThumbnail'
                            : undefined
                    )(err);

                    throw Error('cancelled');
                });
            })
            // préparation du Tool à créer
            .then(([toolThumbnailRes]) => {
                const input: DeepPartial<Tool> = {
                    ...formValues,
                    toolCategory:
                        entityToId(formValues.toolCategory) || undefined,
                };

                if (initialValue?.id) {
                    input.id = initialValue.id;
                }

                const toolThumbnailId: string | undefined =
                    toolThumbnailRes?.data?.createToolThumbnail?.toolThumbnail
                        ?.id;
                if (toolThumbnailId) {
                    input.toolThumbnail = toolThumbnailId;
                } else if (shouldDeleteApiFile) {
                    input.toolThumbnail = null;
                }

                return input;
            })
            // création du Tool
            .then((input) =>
                mutate({ variables: { input } }).catch((err: ApolloError) => {
                    handleError()(err);

                    throw Error('cancelled');
                })
            )
    );
}

const useSubmit = (
    initialValue: ToolFormProps['value'],
    onSuccess: ToolFormProps['onSuccess'],
    onError: ToolFormProps['onError']
) => {
    const isUpdate: boolean = !!initialValue?.id;
    const setAlert = useAlert();
    const [mutate] = useMutation(isUpdate ? UPDATE_TOOL : CREATE_TOOL, {
        update(cache) {
            cache.evict({ fieldName: 'tools' });
        },
    });
    const [createToolThumbnail] = useMutation(CREATE_TOOL_THUMBNAIL, {
        update(cache) {
            cache.evict({ fieldName: 'toolThumbnails' });
        },
    });

    return useCallback(
        (
            formValues: ToolFormValue,
            shouldDeleteApiFile: boolean,
            { setSubmitting, setErrors }: FormikHelpers<ToolFormValue>
        ) => {
            doSubmit(
                formValues,
                initialValue,
                mutate,
                createToolThumbnail,
                shouldDeleteApiFile,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        toolFormSchema,
                        onError,
                        overrideFormikKey
                    )
            )
                .then((res: FetchResult<MutateToolResponse>) => {
                    const resItem: ToolResponse | null | undefined = isUpdate
                        ? res.data?.updateTool?.tool
                        : res.data?.createTool?.tool;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }

                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            initialValue,
            onSuccess,
            onError,
            setAlert,
            mutate,
            createToolThumbnail,
            isUpdate,
        ]
    );
};

export default useSubmit;
