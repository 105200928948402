import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import SettingValue from '@acdc/shared/src/features/settingValue/SettingValue.model';
import MutateSettingValueResponse from '@acdc/shared/src/features/settingValue/MutateSettingValueResponse';
import CreateSettingFileResponse from '@acdc/shared/src/features/settingFile/CreateSettingFileResponse';
import SettingValueResponse from '@acdc/shared/src/features/settingValue/SettingValueResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import UpdateSettingValueResponse from '@acdc/shared/src/features/settingValue/UpdateSettingValueResponse';
import CreateSettingValueResponse from '@acdc/shared/src/features/settingValue/CreateSettingValueResponse';
import { filterString } from '@acdc/shared/src/utils/form-helpers';
import type {
    SettingValueFormProps,
    SettingValueFormValue,
} from './SettingValueForm';
import ConfiguredSettingValueFragment from '../ConfiguredSettingValueFragment';

export const CREATE_SETTING_VALUE = gql`
    ${ConfiguredSettingValueFragment}
    mutation CreateSettingValue($input: createSettingValueInput!) {
        createSettingValue(input: $input) {
            settingValue {
                ...ConfiguredSettingValue
            }
        }
    }
`;

export const UPDATE_SETTING_VALUE = gql`
    ${ConfiguredSettingValueFragment}
    mutation UpdateSettingValue($input: updateSettingValueInput!) {
        updateSettingValue(input: $input) {
            settingValue {
                ...ConfiguredSettingValue
            }
        }
    }
`;

export const CREATE_SETTING_FILE = gql`
    mutation CreateSettingFile($input: createSettingFileInput!) {
        createSettingFile(input: $input) {
            settingFile {
                id
            }
        }
    }
`;

/**
 * Submit le SettingFile (si besoin) puis le SettingValue avec son file
 */
function doSubmit(
    { file, sharedFileId, value }: SettingValueFormValue,
    getId: () => string | null,
    createSettingValue: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateSettingValueResponse>>,
    updateSettingValue: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<UpdateSettingValueResponse>>,
    createSettingFile: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateSettingFileResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<SettingValue>
) {
    return (
        Promise.resolve()
            // upload des fichiers à créer d'abord
            .then(() => {
                const settingFile: File | null = file[0] || null;
                const promises: [
                    Promise<FetchResult<CreateSettingFileResponse>> | null
                ] = [
                    settingFile
                        ? createSettingFile({
                              variables: {
                                  input: {
                                      file: settingFile,
                                  },
                              },
                          })
                        : null,
                ];

                return Promise.all(promises).catch((err: ApolloError) => {
                    handleError(
                        err?.graphQLErrors?.[0]?.path?.[0] ===
                            'createSettingFile'
                            ? 'file'
                            : undefined
                    )(err);

                    throw Error('cancelled');
                });
            })
            // préparation de setting value à créer
            .then(([settingFileRes]) => {
                const input: DeepPartial<SettingValue> = {
                    value: filterString(value),
                    ...fixedProperties,
                };

                if (getId()) {
                    input.id = getId() || undefined;
                }

                const settingFileId: string | undefined =
                    settingFileRes?.data?.createSettingFile?.settingFile?.id;
                input.file = settingFileId || null;

                input.sharedFile = sharedFileId || null;

                return input;
            })

            // création de setting value
            .then((input) =>
                (
                    (getId() ? updateSettingValue : createSettingValue) as (
                        options: MutationFunctionOptions
                    ) => Promise<FetchResult<MutateSettingValueResponse>>
                )({ variables: { input } }).catch((err: ApolloError) => {
                    handleError()(err);

                    throw Error('cancelled');
                })
            )
    );
}

/**
 *
 * @param getId Fonction qui retourne l'id du SettingValue pour savoir si on doit faire une création ou une mise à jour.
 * @param setId Fonction qui set l'id d'un SettingValue créé pour que le prochain submit sache qu'il doit faire une mise à jour.
 * @param setSaved
 * @param yupSchema
 * @param onSuccess
 * @param onError
 * @param fixedProperties Des propriétés fixes à définir dans le SettingValue
 * @returns
 */
const useSubmitSettingValue = (
    getId: () => string | null,
    setId: (id: string) => void,
    setSaved: React.Dispatch<React.SetStateAction<boolean>>,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: SettingValueFormProps['onSuccess'],
    onError: SettingValueFormProps['onError'],
    fixedProperties: DeepPartial<SettingValue>
) => {
    const setAlert = useAlert();
    const [createSettingValue] = useMutation(CREATE_SETTING_VALUE, {
        // pas d'eviction du cache
    });

    const [updateSettingValue] = useMutation(UPDATE_SETTING_VALUE, {
        // pas d'eviction du cache
    });

    const [createSettingFile] = useMutation(CREATE_SETTING_FILE, {
        // pas d'eviction du cache
    });

    return useCallback(
        (
            formValues: SettingValueFormValue,
            { setSubmitting, setErrors }: FormikHelpers<SettingValueFormValue>
        ) => {
            doSubmit(
                formValues,
                getId,
                createSettingValue,
                updateSettingValue,
                createSettingFile,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties
            )
                .then((res: FetchResult<MutateSettingValueResponse>) => {
                    const isUpdate = Boolean(getId());
                    const resItem: SettingValueResponse | null | undefined =
                        isUpdate
                            ? res.data?.updateSettingValue?.settingValue
                            : res.data?.createSettingValue?.settingValue;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }
                    setId(resItem.id);
                    setSaved(true);
                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            getId,
            createSettingValue,
            updateSettingValue,
            createSettingFile,
            fixedProperties,
            setAlert,
            yupSchema,
            onError,
            setId,
            setSaved,
            onSuccess,
        ]
    );
};

export default useSubmitSettingValue;
