import { useEffect } from 'react';
import {
    gql,
    LazyQueryResultTuple,
    OperationVariables,
    useLazyQuery,
} from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import GetToolResponse from '@acdc/shared/src/features/tool/GetToolResponse';

export const GET_TOOL_TO_UPDATE = gql`
    query GetToolToUpdate($id: ID!) {
        tool(id: $id) {
            id
            title
            required
            canUpdateTitle
            toolCategory {
                id
                label
            }
            toolThumbnail {
                id
                originalName
                publicPath
                size
            }
        }
    }
`;

const useLazyToolToUpdate = (
    id: string | null | undefined
): LazyQueryResultTuple<GetToolResponse, OperationVariables> => {
    const setAlert = useAlert();
    const res = useLazyQuery<GetToolResponse>(GET_TOOL_TO_UPDATE, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });
    const error = res?.[1]?.error;

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement de l'outil.",
                'error'
            );
        }
    }, [error, setAlert]);

    return res;
};

export default useLazyToolToUpdate;
