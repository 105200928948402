import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import { Box, Grid } from '@mui/material';
import React from 'react';
import PresentationFlowsListItem from './PresentationFlowsListItem';
import usePresentationFlows from './usePresentationFlows';

function PresentationFlowsList() {
    const { data: presentationFlowsResponse } = usePresentationFlows();
    const presentationFlows =
        presentationFlowsResponse?.presentationFlows?.collection || null;

    return (
        <Box px={2}>
            <Grid container spacing={2} columns={15}>
                {presentationFlows?.map(
                    (presentationFlow: PresentationFlowResponse) => (
                        <Grid
                            item
                            sm={5}
                            md={3}
                            key={presentationFlow.id}
                            height="200px"
                            position="relative"
                        >
                            <PresentationFlowsListItem
                                presentationFlow={presentationFlow}
                            />
                        </Grid>
                    )
                )}
            </Grid>
        </Box>
    );
}

export default PresentationFlowsList;
