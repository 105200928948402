import React from 'react';
import { TableCell, TableRow, TableSortLabel } from '@mui/material';

const reverseOrder = (order: OrderDirection): OrderDirection => {
    return order === 'asc' ? 'desc' : 'asc';
};

const createSortHandler =
    (orderBy: string, orderDirection: OrderDirection, setOrder: SetOrder) =>
    () => {
        setOrder(orderBy, orderDirection);
    };

export type OrderDirection = 'asc' | 'desc';

export type SetOrder = (
    orderBy: string,
    orderDirection: OrderDirection
) => void;

export interface TableHeader {
    id: string;
    label: string;
    sortable: boolean;
}

export interface TableHeadersProps {
    headers: TableHeader[];
    orderBy?: string | null;
    orderDirection?: OrderDirection | null;
    setOrder: SetOrder;
}

function TableHeaders({
    headers,
    orderBy,
    orderDirection,
    setOrder,
}: TableHeadersProps) {
    return (
        <TableRow>
            {headers.map((header: TableHeader) => (
                <TableCell key={`table-header-${header.label}`}>
                    {header.sortable ? (
                        <TableSortLabel
                            active={orderBy === header.id}
                            direction={
                                header.id === orderBy && orderDirection
                                    ? reverseOrder(orderDirection)
                                    : 'asc'
                            }
                            onClick={createSortHandler(
                                header.id,
                                header.id === orderBy && orderDirection
                                    ? reverseOrder(orderDirection)
                                    : 'asc',
                                setOrder
                            )}
                        >
                            <b>{header.label}</b>
                        </TableSortLabel>
                    ) : (
                        <b>{header.label}</b>
                    )}
                </TableCell>
            ))}
        </TableRow>
    );
}

export default TableHeaders;
