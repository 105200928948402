import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { Alert, IconButton, TableCell, TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import NotificationResponse from '@acdc/shared/src/features/notification/NotificationResponse';
import Notification from '@acdc/shared/src/features/notification/Notification.model';
import { isValid } from 'date-fns';
import NotificationStatusCode from '@acdc/shared/src/features/notificationStatus/NotificationStatusCode.enum';
import { toLocaleDateTimeString } from '@acdc/shared/src/utils/dateFormat';
import { TableCellActions } from '@acdc/shared/src/ui/Table';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationFormDialogButton from '../NotificationsForm/NotificationFormDialogButton';
import DeleteNotificationButton from '../DeleteNotificationButton';

export interface NotificationsListItemProps {
    notification: NotificationResponse;
}

const actionProps = {
    sx: {
        minWidth: 0,
        marginLeft: 1,
        backgroundColor: 'grey.400',
        '&:hover': {
            backgroundColor: 'grey.600',
        },
    },
};

function NotificationsListItem({ notification }: NotificationsListItemProps) {
    const notificationToUpdate = useMemo<DeepPartial<Notification>>(
        () => ({
            id: notification.id,
            // on laisse PresentationFlowForm charger les autres champs
        }),
        [notification.id]
    );
    const createdAt = useMemo(() => {
        if (!notification.createdAt) {
            return null;
        }

        const toDate = new Date(notification.createdAt);

        if (!isValid(toDate)) {
            return null;
        }

        return toDate;
    }, [notification.createdAt]);

    return (
        <TableRow data-testid="NotificationListItem">
            <TableCell>{notification.title}</TableCell>
            <TableCell>
                <time
                    title={createdAt ? toLocaleDateTimeString(createdAt) : ''}
                >
                    {createdAt ? createdAt.toLocaleDateString() : ''}
                </time>
            </TableCell>

            <TableCell>
                <Alert
                    severity={
                        notification.notificationStatus?.code ===
                        NotificationStatusCode.IMPORTANT
                            ? 'warning'
                            : 'info'
                    }
                >
                    {notification.notificationStatus?.label}
                </Alert>
            </TableCell>
            <TableCellActions>
                <NotificationFormDialogButton
                    id={`update-Notification-${getIdFromIri(
                        entityToId(notification)
                    )}`}
                    value={notificationToUpdate}
                    buttonComponent={IconButton}
                    buttonProps={actionProps}
                >
                    <EditIcon />
                </NotificationFormDialogButton>
                <DeleteNotificationButton
                    notification={notification}
                    buttonProps={actionProps}
                >
                    <DeleteIcon color="error" />
                </DeleteNotificationButton>
            </TableCellActions>
        </TableRow>
    );
}

export default NotificationsListItem;
