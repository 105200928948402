import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { history } from '@acdc/shared/src/router/BrowserRouter';

function NoMatchPage() {
    const [tripleClick, setTripleClick] = useState(0);

    const handleClick = (e: any) => {
        if (e.detail === 3) {
            setTripleClick(tripleClick + 1);
        }
    };

    return (
        <Box
            height="100vh"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={5}
        >
            {tripleClick === 2 ? (
                <img
                    src="/images/404.gif"
                    alt="Easteregg 404"
                    data-testid="easteregg404"
                    style={{
                        width: 'auto',
                    }}
                />
            ) : (
                <Typography variant="h1" fontSize={100} onClick={handleClick}>
                    404
                </Typography>
            )}
            <Typography variant="h2" textAlign="center">
                Nous sommes désolés, nous ne parvenons pas à trouver la page que
                vous recherchez
            </Typography>
            <Button
                data-testid="bo404Button"
                sx={{
                    fontSize: '0.7rem',
                    mr: 2,
                }}
                onClick={() => history.push('/')}
                variant="contained"
            >
                Accueil
            </Button>
        </Box>
    );
}

export default NoMatchPage;
