import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import DialogButton from '../../../ui/DialogButton';
import PresentationFlowForm, {
    PresentationFlowFormProps,
} from './PresentationFlowForm';

type PresentationFlowFormDialogButtonProps = Pick<
    PresentationFlowFormProps,
    'value' | 'onSuccess'
> & {
    id: string;
    children: React.ReactNode;
    buttonComponent?: React.ComponentType<{ onClick: () => void }>;
    buttonProps?: any;
};

function PresentationFlowFormDialogButton({
    id,
    children,
    value,
    onSuccess,
    buttonComponent,
    buttonProps,
}: PresentationFlowFormDialogButtonProps) {
    const dialogProps = useMemo<any>(
        () => ({
            id,
            mainTitle: value?.id ? 'Modifier un déroulé' : 'Créer un déroulé',
            maxWidth: 'xs',
        }),
        [id, value?.id]
    );

    return (
        <DialogButton
            label={children}
            buttonComponent={buttonComponent}
            buttonProps={{
                variant: 'contained',
                'data-testid': 'PresentationFlowFormDialogButton',
                ...buttonProps,
            }}
            dialogProps={dialogProps}
        >
            {(close) => (
                <Box pt={2}>
                    <PresentationFlowForm
                        value={value}
                        onSuccess={(res) => {
                            close();
                            onSuccess && onSuccess(res);
                        }}
                    />
                </Box>
            )}
        </DialogButton>
    );
}

export default PresentationFlowFormDialogButton;
