import React, { useMemo } from 'react';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import ContentModel from '@acdc/shared/src/features/content/Content.model';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import DialogButton from '../../ui/DialogButton';
import CONFIGS from '../../configs';

type PreviewContentDialogButtonProps = {
    content: ContentModel | string;
    children: React.ReactNode;
    buttonComponent?: React.ComponentType<{ onClick: () => void }>;
    buttonProps?: any;
    type?: ContentType | undefined;
};

function PreviewContentDialogButton({
    content,
    children,
    buttonComponent,
    buttonProps,
    type,
}: PreviewContentDialogButtonProps) {
    const contentId = getIdFromIri(entityToId(content));
    const id = useMemo(() => `PreviewContentDialog${contentId}`, [contentId]);
    const dialogProps = useMemo<any>(
        () => ({
            id,
            mainTitle:
                'Prévisualisation en mode tablette (résolution 1024x768 pixels)',
            maxWidth: false,
            fullWidth: false,
            sx: { '& .MuiDialogContent-root': { padding: 0 } },
        }),
        [id]
    );

    return (
        <DialogButton
            label={children}
            buttonComponent={buttonComponent}
            buttonProps={{
                variant: 'contained',
                'data-testid': 'previewContentDialogButton',
                ...buttonProps,
            }}
            dialogProps={dialogProps}
        >
            {() => {
                const url =
                    type === ContentType.TOOL_CONTENT
                        ? `/pages/outils/${contentId}/previsualiser`
                        : `/pages/${contentId}/previsualiser`;
                return (
                    <iframe
                        style={{
                            width: '1024px',
                            aspectRatio: '4 / 3',
                            border: 'none',
                        }}
                        title="Prévisualisation de page"
                        src={`${CONFIGS.frontEntrypoint}${url}`}
                        data-testid="previewContentDialogIframe"
                    />
                );
            }}
        </DialogButton>
    );
}

export default PreviewContentDialogButton;
