import { Switch, SwitchProps, useTheme, Theme } from '@mui/material';
import React, { useMemo } from 'react';

/**
 * Estimation en px de la largeur moyenne d'1 caractère.
 */
const CHAR_WIDTH = 7;

const getSx = (
    theme: Theme,
    checkedLabel: string,
    uncheckedLabel: string,
    switchWidth: string,
    halfSwitchWidth: string,
    disabled: boolean
) => ({
    marginTop: theme.spacing(1),
    width: switchWidth,
    height: '40px',
    padding: 0,
    '& .MuiSwitch-switchBase': {
        margin: 0,
        padding: 0,
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.grey[200],
            width: halfSwitchWidth,
            height: '40px',
            borderRadius: '20px',
            boxShadow: 'none',
            '&:before': {
                content: `"${uncheckedLabel}"`,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: disabled
                    ? theme.palette.grey[500]
                    : theme.palette.grey[800],
                whiteSpace: 'nowrap',
            },
        },
        '&.Mui-checked': {
            transform: `translateX(${halfSwitchWidth})`,
            '& .MuiSwitch-thumb': {
                backgroundColor: disabled
                    ? theme.palette.primary.light
                    : theme.palette.primary.main,
            },
            '& .MuiSwitch-thumb:before': {
                content: `"${checkedLabel}"`,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: theme.palette.primary.contrastText,
                whiteSpace: 'nowrap',
            },
        },
    },
    '& .MuiSwitch-track': {
        position: 'relative',
        height: '40px',
        borderRadius: '20px',
        color: '#fff',
        '&:before': {
            content: `"${uncheckedLabel}"`,
            position: 'absolute',
            width: '50%',
            top: '50%',
            left: 0,
            transform: 'translate(0, -50%)',
            textAlign: 'center',
            whiteSpace: 'nowrap',
        },
        '&:after': {
            content: `"${checkedLabel}"`,
            position: 'absolute',
            width: '50%',
            top: '50%',
            right: 0,
            transform: 'translate(0, -50%)',
            textAlign: 'center',
            whiteSpace: 'nowrap',
        },
    },
});

export type BigSwitchProps = SwitchProps & {
    checkedLabel: string;
    uncheckedLabel: string;

    /**
     * La longueur en px de checkedLabel. La longueur est estimée par défaut mais si ça s'affiche mal il faut définir la valeur.
     */
    checkedLabelWidth?: number;

    /**
     * La longueur en px de uncheckedLabel. La longueur est estimée par défaut mais si ça s'affiche mal il faut définir la valeur.
     */
    uncheckedLabelWidth?: number;
};

/**
 * Switch avec un label correspondant à la valeur checkée et un label correspondant à la valeur non-checkée.
 */
function BigSwitch({
    checkedLabel,
    uncheckedLabel,
    checkedLabelWidth,
    uncheckedLabelWidth,
    disabled,
    ...props
}: BigSwitchProps) {
    const theme = useTheme();
    const sx = useMemo(() => {
        const estimatedCheckedLabelWidth =
            checkedLabelWidth || checkedLabel.length * CHAR_WIDTH;
        const estimatedUncheckedLabelWidth =
            uncheckedLabelWidth || uncheckedLabel.length * CHAR_WIDTH;

        // on utilise la largeur du plus long des labels pour les 2 cotés du switch pour que ça soit symétrique
        const largerLabelWidth =
            estimatedCheckedLabelWidth > estimatedUncheckedLabelWidth
                ? estimatedCheckedLabelWidth
                : estimatedUncheckedLabelWidth;

        /**
         * spacing(2) + largeur uncheckedLabel + spacing(4) + largeur checkedLabel + spacing 2
         * illustration: (  uncheckedLabel ) checkedLabel  )
         */
        const switchWidthFormula = `${theme.spacing(
            8
        )} + ${largerLabelWidth}px * 2`;

        const switchWidth = `calc(${switchWidthFormula})`;
        const halfSwitchWidth = `calc((${switchWidthFormula}) / 2)`;

        return getSx(
            theme,
            checkedLabel,
            uncheckedLabel,
            switchWidth,
            halfSwitchWidth,
            Boolean(disabled)
        );
    }, [
        checkedLabel,
        uncheckedLabel,
        checkedLabelWidth,
        uncheckedLabelWidth,
        theme,
        disabled,
    ]);

    return <Switch {...props} disabled={disabled} sx={sx} />;
}

export default BigSwitch;
