enum UserFieldEnum {
    FIRSTNAME = 'firstname',
    LASTNAME = 'lastname',
    EMAIL = 'email',
    SECONDARY_EMAIL = 'secondaryEmail',
    PHONE = 'phone',
    PHOTO = 'photo.publicPath',
    PROFESSION = 'profession',
    COMMENT = 'comment',
}

export default UserFieldEnum;
