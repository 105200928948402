import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import React from 'react';
import ListContentsResponse from '@acdc/shared/src/features/content/ListContentsResponse';
import DraggableContentsListItem from './DraggableContentsListItem';
import DragAndDropList from '../../../ui/DragAndDropList/DragAndDropList';

export interface DraggableContentsListProps {
    contents: ListContentsResponse | null | undefined;
}

function DraggableContentsList({ contents }: DraggableContentsListProps) {
    return (
        <DragAndDropList>
            {contents?.contents?.collection.map((content: ContentResponse) => (
                <DraggableContentsListItem key={content.id} content={content} />
            ))}
        </DragAndDropList>
    );
}

export default DraggableContentsList;
