import React from 'react';
import { Box, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MultiStepContentFormDialogButton from '../../features/content/ContentForm/MultiStepContentFormDialogButton';
import ContentsList from '../../features/content/ContentsList';

function Contents() {
    return (
        <>
            <Box sx={{ mt: 2, mb: 4, mx: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h2" component="h1">
                        Mes pages
                    </Typography>
                    <Box>
                        <MultiStepContentFormDialogButton
                            id="create-content-dialog"
                            buttonProps={{
                                color: 'primary',
                                variant: 'contained',
                            }}
                        >
                            Nouvelle page{' '}
                            <AddCircleOutlineIcon
                                fontSize="small"
                                sx={{ ml: 1 }}
                            />
                        </MultiStepContentFormDialogButton>
                    </Box>
                </Box>
            </Box>

            <ContentsList />
        </>
    );
}

export default Contents;
