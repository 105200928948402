import { Box, Drawer, Paper, Theme, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleResponse from '@acdc/shared/src/features/module/ModuleResponse';
import ModuleCategoryResponse from '@acdc/shared/src/features/moduleCategory/ModuleCategoryResponse';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import BaseContentResponse from '@acdc/shared/src/features/baseContent/BaseContentResponse';
import { headerHeight } from '../../mui/theme';
import ThemeProvider from '../../mui/ThemeProvider';
import {
    DraggableModulesList,
    ContentTemplateConfigurator,
    ContentModuleConfigurator,
    useModuleCategories,
    useContentToConfigure,
} from '../../features/content/ContentConfigurator';
import PreviewContentDialogButton from '../../features/content/PreviewContentDialogButton';

const drawerWidth = '350px';

const drawerSx = {
    // pas de width sur le Drawer mais uniquement sur son Paper sinon le Drawer peut géner le contenu en arrière plan en se mettant par dessus
    width: 0,
    '& .MuiDrawer-paper': {
        width: drawerWidth,
        height: `calc(100% - ${headerHeight})`,
        boxSizing: 'border-box',
        top: headerHeight,
        backgroundColor: 'background.default',
    },
};

const templateConfiguratorContainerSx = {
    px: (theme: Theme) => `calc(${drawerWidth} + ${theme.spacing(2)})`,
    py: 2,
    minHeight: `calc(100vh - ${headerHeight})`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const templateConfiguratorPaperSx = {
    width: '100%',
    maxWidth: 1024, // la largeur d'une tablette
    aspectRatio: '4 / 3', // le ratio d'une tablette paysage
    overflow: 'hidden', // cacher les bordures des sections qui dépassent
    border: (theme: Theme) => `1px solid ${theme.palette.grey['300']}`,
};

const moduleConfiguratorHeadingSx = {
    backgroundColor: 'grey.300',
    pl: 2,
    py: 1,
};

const findModule = (
    module: ModuleResponse,
    moduleCategories: ModuleCategoryResponse[]
): ModuleResponse | null => {
    for (let c = 0; c < moduleCategories.length; c += 1) {
        const someCategory = moduleCategories[c];
        if (someCategory.modules?.collection) {
            for (
                let m = 0;
                m < someCategory.modules.collection.length;
                m += 1
            ) {
                const someModule = someCategory.modules.collection[m];
                if (matchOnId(module, someModule)) {
                    return someModule;
                }
            }
        }
    }

    return null;
};

export interface ConfigureContentProps {
    type?: ContentType | undefined;
}

function ConfigureContent({ type }: ConfigureContentProps) {
    // le Content
    const { contentId } = useParams();
    const contentIri =
        type === ContentType.TOOL_CONTENT
            ? `/tool-contents/${contentId}`
            : `/contents/${contentId}`;
    const contentResponse = useContentToConfigure(
        contentIri,
        type || ContentType.CONTENT
    );
    const [content, setContent] = useState<BaseContentResponse | null>(null);
    useEffect(() => {
        if (!content && contentResponse) {
            // set la valeur initiale
            setContent(contentResponse);
        }
    }, [content, contentResponse]);

    // les Modules par ModuleCategories
    const { data: moduleCategoriesResponse } = useModuleCategories();
    const moduleCategories =
        moduleCategoriesResponse?.moduleCategories?.collection || null;

    // le ContentModule selectionné
    const [selectedContentModuleId, setSelectedContentModuleId] = useState<
        string | null
    >(null);

    const selectedContentModule = useMemo<ContentModuleResponse | null>(() => {
        if (!selectedContentModuleId) {
            return null;
        }

        return (
            content?.contentModules?.collection.find((c) =>
                matchOnId(c, selectedContentModuleId)
            ) || null
        );
    }, [content?.contentModules?.collection, selectedContentModuleId]);

    const handleSelectContentModule = useCallback(
        (contentModuleToSelect: string | ContentModuleResponse) => {
            setSelectedContentModuleId(
                entityToId(contentModuleToSelect) || null
            );
        },
        [setSelectedContentModuleId]
    );
    const handleUnselectContentModule = useCallback(
        (contentModuleToUnselect: string | ContentModuleResponse) => {
            setSelectedContentModuleId((current) => {
                return matchOnId(current, contentModuleToUnselect)
                    ? null
                    : current; // pas de déselection si ce n'est pas ce contentModule qui était selectionné
            });
        },
        [setSelectedContentModuleId]
    );

    // le Module à configurer pour le ContentModule selectionné
    const moduleToConfigure = useMemo<ModuleResponse | null>(() => {
        if (!selectedContentModule?.module || !moduleCategories) {
            return null;
        }

        return findModule(selectedContentModule.module, moduleCategories);
    }, [selectedContentModule, moduleCategories]);

    const handleContentModuleChange = useCallback(
        (
            contentModuleId: string,
            setContentModule: (
                curr: ContentModuleResponse
            ) => ContentModuleResponse
        ) => {
            setContent((curr) => ({
                ...curr,
                contentModules: {
                    collection: (curr?.contentModules?.collection || []).map(
                        (contentModule: ContentModuleResponse) => {
                            if (matchOnId(contentModule, contentModuleId)) {
                                return setContentModule(contentModule);
                            }

                            return contentModule;
                        }
                    ),
                },
            }));
        },
        [setContent]
    );

    return (
        <ThemeProvider variant="light">
            <ThemeProvider variant="dark">
                <Drawer anchor="left" variant="permanent" sx={drawerSx}>
                    <PreviewContentDialogButton
                        buttonProps={{
                            sx: {
                                marginTop: 3,
                                marginBottom: 3,
                                marginLeft: 3,
                                marginRight: 3,
                            },
                        }}
                        content={contentIri}
                        type={type}
                    >
                        Prévisualiser
                    </PreviewContentDialogButton>
                    <DraggableModulesList moduleCategories={moduleCategories} />
                </Drawer>
            </ThemeProvider>
            <Box sx={templateConfiguratorContainerSx}>
                <Paper sx={templateConfiguratorPaperSx}>
                    {content && (
                        <ContentTemplateConfigurator
                            content={content}
                            onContentChange={setContent}
                            onSelectContentModule={handleSelectContentModule}
                            onUnselectContentModule={
                                handleUnselectContentModule
                            }
                        />
                    )}
                </Paper>
            </Box>
            <Drawer
                open={Boolean(selectedContentModule)}
                anchor="right"
                sx={drawerSx}
                disableEnforceFocus
                ModalProps={{
                    keepMounted: false,
                }}
                hideBackdrop
            >
                <Typography
                    variant="h6"
                    component="h2"
                    sx={moduleConfiguratorHeadingSx}
                >
                    Édition
                </Typography>
                {selectedContentModule && moduleToConfigure && (
                    <ContentModuleConfigurator
                        key={selectedContentModule.id}
                        contentModule={selectedContentModule}
                        onContentModuleChange={handleContentModuleChange}
                        module={moduleToConfigure}
                    />
                )}
            </Drawer>
        </ThemeProvider>
    );
}

export default ConfigureContent;
