import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import Connexion from './Connexion';
import Validation from './Validation';

function AuthPages() {
    return (
        <Routes>
            <Route path="/">
                <Route index element={<Navigate to="connexion" replace />} />
                <Route path="connexion" element={<Connexion />} />
                <Route path="validation" element={<Validation />} />
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Route>
        </Routes>
    );
}

export default AuthPages;
