import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import useDuplicateContent from './useDuplicateContent';

export interface DuplicateContentButtonProps
    extends Omit<ButtonProps, 'onClick'> {
    content: string | { id: string };
    onSuccess?: (contentId: string) => void;
    buttonComponent?: React.ComponentType<ButtonProps> | undefined;
}

function DuplicateContentButton({
    content,
    onSuccess,
    buttonComponent,
    ...buttonProps
}: DuplicateContentButtonProps) {
    const [duplicate, submitting] = useDuplicateContent(onSuccess);
    const ButtonComponent: React.ComponentType<ButtonProps> =
        buttonComponent || Button;

    return (
        <ButtonComponent
            {...buttonProps}
            onClick={() => duplicate(content)}
            disabled={submitting}
        />
    );
}

export default DuplicateContentButton;
