import React from 'react';
import DialogButton from '../../../ui/DialogButton';
import { MultiStepContentFormProps } from './MultiStepContentForm';
import MultiStepContentFormDialog from './MultiStepContentFormDialog';

type MultiStepContentFormDialogButtonProps = Pick<
    MultiStepContentFormProps,
    'value' | 'type'
> & {
    id: string;
    children: React.ReactNode;
    buttonComponent?: React.ComponentType<{ onClick: () => void }>;
    buttonProps?: any;
};

function MultiStepContentFormDialogButton({
    id,
    children,
    value,
    type,
    buttonComponent,
    buttonProps,
}: MultiStepContentFormDialogButtonProps) {
    return (
        <DialogButton
            dialogComponent={MultiStepContentFormDialog}
            label={children}
            buttonComponent={buttonComponent}
            buttonProps={{
                variant: 'contained',
                'data-testid': 'contentFormButton',
                ...buttonProps,
            }}
            dialogProps={{
                id,
                mainTitle: 'Formulaire de page', // remplacé dans MultiStepContentFormDialog
                customProps: {
                    value,
                    type,
                },
            }}
        >
            {() => null}
        </DialogButton>
    );
}

export default MultiStepContentFormDialogButton;
