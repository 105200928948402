// pensez à ajoutez les champs dans apps/front/src/features/property/useLoadProperties.ts
enum PropertyFieldEnum {
    CLIENT_LASTNAME = 'clientLastname',
    CLIENT_FIRSTNAME = 'clientFirstname',
    CLIENT_EMAIL = 'clientEmail',
    CLIENT_PHONE = 'clientPhone',
    ADDRESS = 'address',
    STREET = 'street',
    POST_CODE = 'postCode',
    CITY = 'city',
}

export default PropertyFieldEnum;
