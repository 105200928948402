/* eslint-disable react/no-danger */
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import React, { useMemo } from 'react';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import { Alert, Box } from '@mui/material';
import WysiwygValue from '@acdc/shared/src/ui/WysiwygValue';
import DEFAULT_WYSIWYG_STYLE from '@acdc/shared/src/features/settingValue/DEFAULT_WYSIWYG_STYLE';
import moduleSettingsCodePartner from '@acdc/shared/src/features/partner/moduleSettingsCodePartner';
import CONFIGS from '../../../../configs';

export interface ContentModulePreviewProps {
    contentModule: ContentModuleResponse;
}

const gridSx = {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 2fr)',
    gridTemplateRows: ' repeat(4, 25%)',
    gridGap: 0,
    height: '100%',
};

const containerPartnerImageSx = {
    gridColumn: 'span 2',
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',

    '&:last-of-type:nth-of-type(odd)': {
        gridColumnStart: '2',
        gridColumnEnd: '4',
    },
};

const imageSx = {
    gridColumn: 'span 1',
    height: '100%',
    width: '50%',
    objectFit: 'contain',
};

function ContentModulePreview({ contentModule }: ContentModulePreviewProps) {
    const valueSetting = useMemo(
        () =>
            contentModule.settingValues?.collection.find(
                (settingValue) =>
                    settingValue.moduleSetting?.code ===
                    ModuleSettingCode.WYSIWYG_VALUE
            )?.value,
        [contentModule.settingValues]
    );

    if (contentModule.module?.code === ModuleCode.WYSIWYG) {
        return (
            <WysiwygValue
                value={valueSetting || ''}
                sx={DEFAULT_WYSIWYG_STYLE}
            />
        );
    }

    if (contentModule.module?.code === ModuleCode.IMAGE) {
        const publicPath = contentModule.settingValues?.collection.find(
            (settingValue) =>
                settingValue.moduleSetting?.code === ModuleSettingCode.IMAGE
        )?.file?.publicPath;
        return (
            <Box
                component="img"
                src={publicPath ? CONFIGS.apiEntrypoint + publicPath : ''}
                alt=""
                sx={{
                    maxWidth: '100%',
                }}
            />
        );
    }

    if (contentModule.module?.code === ModuleCode.PDF) {
        const file = contentModule.settingValues?.collection.find(
            (settingValue) =>
                settingValue.moduleSetting?.code === ModuleSettingCode.IMAGE
        )?.file;
        if (!file?.publicPath) return null;
        return (
            <Box>
                <iframe
                    title={file.name}
                    src={CONFIGS.apiEntrypoint + file.publicPath}
                    width="100%"
                    height="100%"
                >
                    {' '}
                </iframe>
            </Box>
        );
    }

    if (contentModule.module?.code === ModuleCode.PARTNERS) {
        const imagePartners = contentModule.settingValues?.collection.filter(
            (settingValue) =>
                moduleSettingsCodePartner().includes(
                    settingValue.moduleSetting?.code as ModuleSettingCode
                )
        );

        return (
            <>
                {imagePartners && (
                    <Box sx={gridSx}>
                        {imagePartners.map((imagePartner) => {
                            const publicPath =
                                imagePartner.file?.publicPath ||
                                imagePartner.sharedFile?.publicPath;

                            return publicPath ? (
                                <Box
                                    sx={containerPartnerImageSx}
                                    key={publicPath}
                                >
                                    <Box
                                        key={imagePartner.id}
                                        component="img"
                                        src={CONFIGS.apiEntrypoint + publicPath}
                                        alt=""
                                        sx={imageSx}
                                    />
                                </Box>
                            ) : null;
                        })}
                    </Box>
                )}
            </>
        );
    }

    if (contentModule.module?.code === ModuleCode.QUOTATION) {
        return null;
    }

    if (contentModule.module?.code === ModuleCode.PACKAGES) {
        return null;
    }

    return (
        <Alert severity="error" data-testid="contentModulePreviewError">
            Prévisualisation indisponible
        </Alert>
    );
}

export default ContentModulePreview;
