import { useCallback, useRef, useState } from 'react';

// @see https://usehooks.com/useLocalStorage/

/*
 * Sauvegarde et/ou recupère la/les valeur(s) du localStorage
 * Possibilité de lui passer un string, tableau, objet, function
 */

export type SetValue<T> = (
    value: T | ((curr: T | undefined) => T | undefined) | undefined
) => void;

function usePersistentState<T = any>(
    key: string,
    initialValue: T | undefined = undefined
): [T | undefined, SetValue<T>] {
    const [storedValue, setStoredValue] = useState<T | undefined>(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const storedValueRef = useRef<T | undefined>();
    storedValueRef.current = storedValue;

    const setValue = useCallback<SetValue<T>>(
        (value) => {
            const valueToStore =
                value instanceof Function
                    ? value(storedValueRef.current)
                    : value;

            setStoredValue(valueToStore);

            if (typeof window !== 'undefined') {
                if (valueToStore === undefined) {
                    try {
                        window.localStorage.removeItem(key);
                        // eslint-disable-next-line no-empty
                    } catch {}
                } else {
                    try {
                        window.localStorage.setItem(
                            key,
                            JSON.stringify(valueToStore)
                        );
                        // eslint-disable-next-line no-empty
                    } catch {}
                }
            }
        },
        [key, setStoredValue]
    );

    return [storedValue, setValue];
}

export default usePersistentState;
