import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import ModuleResponse from '@acdc/shared/src/features/module/ModuleResponse';
import ModuleSettingResponse from '@acdc/shared/src/features/moduleSetting/ModuleSettingResponse';
import SettingValueResponse from '@acdc/shared/src/features/settingValue/SettingValueResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import React, { useCallback, useMemo, useState } from 'react';
import { SxProps, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { visuallyHidden } from '@mui/utils';
import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import ModuleSettingCode from '@acdc/shared/src/features/moduleSetting/ModuleSettingCode.enum';
import SettingValueForm from './SettingValueForm';
import WysiwygVariables from './WysiwygVariables';
import PartnersForm from './SharedFile/PartnersForm';

const tabSx: SxProps = {
    textTransform: 'none',
    letterSpacing: 'normal',
    flex: 1,
};

function addOrUpdate<T>(res: T, currentSettingValues: T[]) {
    let isUpdated = false;
    const updatedSettingValues = currentSettingValues.map((cv) => {
        if (matchOnId(cv, res)) {
            isUpdated = true;
            return {
                ...cv,
                ...res,
            };
        }

        return cv;
    });
    if (isUpdated) {
        return updatedSettingValues;
    }

    return [...currentSettingValues, res];
}

const getSettingValue = (
    setting: ModuleSettingResponse,
    contentModule: ContentModuleResponse
): SettingValueResponse | undefined => {
    const settingValueResponse = contentModule.settingValues?.collection.find(
        (val: SettingValueResponse) => matchOnId(val.moduleSetting, setting)
    );

    if (!settingValueResponse) {
        return undefined;
    }

    return {
        ...settingValueResponse,
        contentModule,
        moduleSetting: setting,
    };
};

export interface ContentModuleConfiguratorProps {
    contentModule: ContentModuleResponse;
    onContentModuleChange: (
        contentModuleId: string,
        setContentModule: (curr: ContentModuleResponse) => ContentModuleResponse
    ) => void;
    module: ModuleResponse;
}

/**
 * Formulaire de configuration d'un ContentModule.
 * Il est constitué de tous les formulaires de chacun des SettingValues du ContentModule.
 * Attention, ce composant n'est pas controllé. La prop contentModule n'est prise en compte qu'une seule fois à l'initialisation.
 */
function ContentModuleConfigurator({
    contentModule,
    onContentModuleChange,
    module,
}: ContentModuleConfiguratorProps) {
    const displayImageLabel = useMemo<any>(
        () =>
            getSettingValue(
                module.settings?.collection.find(
                    (c) => c.code === ModuleSettingCode.IMAGE_EDITABLE
                )!,
                contentModule
            )?.value === 'true',
        [module, contentModule]
    );

    const handleSettingValueChange = useCallback(
        (moduleSetting: ModuleSettingResponse) =>
            (res: SettingValueResponse) => {
                onContentModuleChange(
                    contentModule.id!,
                    (curr: ContentModuleResponse) => ({
                        ...curr,
                        settingValues: {
                            collection: addOrUpdate<SettingValueResponse>(
                                {
                                    // on complète la réponse avec le setting
                                    ...res,
                                    moduleSetting,
                                },
                                curr.settingValues?.collection || []
                            ),
                        },
                    })
                );
            },
        [onContentModuleChange, contentModule.id]
    );

    const handleSettingValuesChange = useCallback(
        () => (res: SettingValueResponse[]) => {
            if (res) {
                onContentModuleChange(
                    contentModule.id!,
                    (curr: ContentModuleResponse) => ({
                        ...curr,
                        settingValues: {
                            collection: res,
                        },
                    })
                );
            }
        },
        [onContentModuleChange, contentModule.id]
    );

    const handleVariableChange = useCallback(
        (variable: VariableResponse) => {
            onContentModuleChange(
                contentModule.id!,
                (curr: ContentModuleResponse) => ({
                    ...curr,
                    variables: {
                        collection: addOrUpdate<VariableResponse>(
                            variable,
                            curr.variables?.collection || []
                        ),
                    },
                })
            );
        },
        [onContentModuleChange, contentModule.id]
    );

    const handleDeletedVariable = useCallback(
        (variable: string | VariableResponse) => {
            onContentModuleChange(
                contentModule.id!,
                (curr: ContentModuleResponse) => ({
                    ...curr,
                    variables: {
                        collection:
                            curr.variables?.collection.filter(
                                (v) => !matchOnId(v, variable)
                            ) || [],
                    },
                })
            );
        },
        [onContentModuleChange, contentModule.id]
    );

    const shouldHaveVariablesConfiguration = useMemo(() => {
        return Boolean(
            ([ModuleCode.WYSIWYG] as string[]).includes(`${module?.code}`)
        );
    }, [module]);
    const [tabValue, setTabValue] = useState<string>('1');
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return (
        <TabContext value={tabValue}>
            <TabList
                onChange={handleTabChange}
                aria-label="Configuration du module"
                sx={
                    shouldHaveVariablesConfiguration
                        ? { borderBottom: 1, borderColor: 'divider' }
                        : visuallyHidden
                }
            >
                <Tab
                    label="Configurer le contenu"
                    id="configure-content-tab"
                    aria-controls="configure-content-tabpanel"
                    value="1"
                    sx={tabSx}
                />
                <Tab
                    label="Configurer les balises"
                    id="configure-variables-tab"
                    aria-controls="configure-variables-tabpanel"
                    value="2"
                    sx={tabSx}
                    disabled={!shouldHaveVariablesConfiguration}
                    data-testid="configureVariablesTab"
                />
            </TabList>
            <TabPanel value="1" sx={{ p: 0, pt: 1, overflowY: 'auto' }}>
                {module.code === ModuleCode.PARTNERS ? (
                    <PartnersForm
                        contentModule={contentModule}
                        onSuccess={handleSettingValuesChange()}
                    />
                ) : (
                    module.settings?.collection.map(
                        (setting: ModuleSettingResponse) =>
                            (setting.code !== 'image_label' ||
                                displayImageLabel) && (
                                <SettingValueForm
                                    key={setting.id}
                                    value={getSettingValue(
                                        setting,
                                        contentModule
                                    )}
                                    module={module}
                                    moduleSetting={setting}
                                    contentModule={contentModule}
                                    onSuccess={handleSettingValueChange(
                                        setting
                                    )}
                                />
                            )
                    )
                )}
            </TabPanel>
            <TabPanel value="2" sx={{ p: 1, overflowY: 'auto' }}>
                <WysiwygVariables
                    contentModule={contentModule}
                    onVariableChange={handleVariableChange}
                    onVariableDeleted={handleDeletedVariable}
                />
            </TabPanel>
        </TabContext>
    );
}

export default ContentModuleConfigurator;
