import React from 'react';
import {
    CssBaseline,
    ScopedCssBaseline,
    ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import { lightTheme, darkTheme } from './theme';

export interface ThemeProviderProps {
    children: React.ReactNode;
    variant: 'light' | 'dark';

    /**
     * Faut il appliquer le style globalement ? (donc au body parent)
     * false par défaut
     */
    global?: boolean;
}

function ThemeProvider({ children, variant, global }: ThemeProviderProps) {
    const CssBaseLineComponent = global ? CssBaseline : ScopedCssBaseline;

    return (
        <MuiThemeProvider theme={variant === 'dark' ? darkTheme : lightTheme}>
            <CssBaseLineComponent>{children}</CssBaseLineComponent>
        </MuiThemeProvider>
    );
}

export default ThemeProvider;
