import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BaseContentModel from '@acdc/shared/src/features/baseContent/BaseContent.model';
import FormSkeleton from '@acdc/shared/src/ui/FormSkeleton';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import ContentForm, { ContentFormProps } from './ContentForm';
import PickContentTemplateForm, {
    PickContentTemplateFormProps,
} from './PickContentTemplateForm';
import useLazyContentToUpdate from './useLazyContentToUpdate';

export enum ContentFormStep {
    BASE_FIELDS,
    PICK_TEMPLATE,
}

export type MultiStepContentFormProps = Pick<ContentFormProps, 'value'> &
    Pick<PickContentTemplateFormProps, 'onSuccess'> & {
        onStepChange?: (step: ContentFormStep) => void;
        type?: ContentType | undefined;
    };

/**
 * Formulaire pour créer un Content en 2 étapes.
 * Si la valeur fournie a un id le Content est chargé depuis l'api avant de rendre le formulaire.
 */
function MultiStepContentForm({
    value,
    onSuccess,
    onStepChange,
    type,
}: MultiStepContentFormProps) {
    const [query, contentResponse] = useLazyContentToUpdate(value?.id, type);
    const contentFromResponse = useMemo<
        DeepPartial<BaseContentModel> | undefined
    >(() => {
        if (!contentResponse) {
            return undefined;
        }
        return {
            id: contentResponse?.id,
            title: contentResponse?.title,
            required: contentResponse?.required,
            template: contentResponse?.template?.id,
        } as DeepPartial<BaseContentModel>;
    }, [contentResponse]);
    const initialValue = value?.id ? contentFromResponse : value;

    const [completedValue, setCompletedValue] = useState<
        DeepPartial<BaseContentModel> | undefined
    >();
    const handleContentFormSuccess = useCallback(
        (res: DeepPartial<BaseContentModel>) => {
            setCompletedValue({
                ...initialValue,
                ...res,
            });
            onStepChange && onStepChange(ContentFormStep.PICK_TEMPLATE);
        },
        [setCompletedValue, onStepChange, initialValue]
    );

    useEffect(() => {
        if (value?.id) {
            query();
        }
    }, [query, value?.id]);

    return (
        <>
            {!completedValue && (
                <>
                    {value?.id && !contentFromResponse && (
                        <FormSkeleton nbInputs={2} />
                    )}
                    {(!value?.id || contentFromResponse) && (
                        <ContentForm
                            value={initialValue}
                            onSuccess={handleContentFormSuccess}
                        />
                    )}
                </>
            )}
            {completedValue && (
                <PickContentTemplateForm
                    value={completedValue}
                    onSuccess={onSuccess}
                    type={type}
                />
            )}
        </>
    );
}

export default MultiStepContentForm;
