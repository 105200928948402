import {
    ToggleButtonGroup,
    ToggleButton,
    Typography,
    ToggleButtonGroupProps,
    Theme,
} from '@mui/material';
import React, { useMemo } from 'react';
import FlexImage from '@acdc/shared/src/ui/FlexImage';
import CONFIGS from '../../configs';

const groupSx = {
    '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
        marginRight: 1,
    },
    '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        marginLeft: 0,
    },
    display: 'flex',
    flexFlow: 'row wrap',
};

export const buttonSx = {
    display: 'flex',
    flexDirection: 'column',
    height: '240px', // utilisé par TemplateSelectSkeleton aussi
    width: '240px',
    background: '#fff',
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
    },
    '&.Mui-selected': {
        background: (theme: Theme) => theme.palette.background.rainbow,
        color: 'primary.contrastText',
        '&:hover': {
            background: (theme: Theme) => theme.palette.background.rainbow,
        },
        '&.Mui-focusVisible': {
            background: (theme: Theme) => theme.palette.background.rainbow,
        },
    },
    borderRadius: 0,
    border: 0,
    marginTop: 1,
};

const imageSx = { width: '100%', backgroundColor: '#fff' };

const labelSx = {
    fontWeight: 'bold',
    textAlign: 'left',
    width: '100%',
    textTransform: 'none',
};

export type TemplateSelectProps = ToggleButtonGroupProps & {
    templates: any[]; // todo : model
};

function TemplateSelect({
    templates,
    disabled,
    ...props
}: TemplateSelectProps) {
    const variableButtonSx = useMemo(
        () => ({
            ...buttonSx,
            ...(disabled && {
                filter: 'grayscale(70%)',
            }),
        }),
        [disabled]
    );

    return (
        <ToggleButtonGroup disabled={disabled} {...props} sx={groupSx}>
            {templates.map((template) => (
                <ToggleButton
                    key={template.id}
                    value={template.id}
                    aria-label={template.title}
                    sx={variableButtonSx}
                    disableRipple
                >
                    <FlexImage
                        src={
                            CONFIGS.apiEntrypoint +
                            template.templateImage.publicPath
                        }
                        alt={template.title}
                        sx={imageSx}
                    />
                    <Typography variant="body1" component="span" sx={labelSx}>
                        {template.title}
                    </Typography>
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}

export default TemplateSelect;
