import { Form, Formik } from 'formik';
import React, { useMemo, useRef, useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
    entityToId,
    initString,
    stringToBool,
} from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import SettingValueResponse from '@acdc/shared/src/features/settingValue/SettingValueResponse';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleSettingResponse from '@acdc/shared/src/features/moduleSetting/ModuleSettingResponse';
import FieldType from '@acdc/shared/src/features/moduleSetting/FieldType.enum';
import ModuleResponse from '@acdc/shared/src/features/module/ModuleResponse';
import SettingValueFormInner from './SettingValueFormInner';
import useSubmitSettingValue from './useSubmitSettingValue';

export interface SettingValueFormValue {
    id?: string;
    value: any;
    file: File[];
    sharedFileId: string;
}

const initValue = (
    value: SettingValueResponse,
    setting: ModuleSettingResponse
): SettingValueFormValue => ({
    value:
        setting.fieldType === FieldType.SWITCH
            ? stringToBool(value.value || 'false')
            : initString(value.value),
    file: [],
    sharedFileId: '',
});

export const settingValueFormSchema = Yup.object().shape({
    value: Yup.string().label('La valeur').optional(),
    file: Yup.array().label("L'image").optional(),
});

export type SettingValueFormProps = {
    value?: SettingValueResponse;
    contentModule: ContentModuleResponse;
    moduleSetting: ModuleSettingResponse;
    onSuccess?: (res: SettingValueResponse) => void;
    onError?: (err: ApolloError) => void;
    module: ModuleResponse;
};

/**
 * Formulaire pour créer ou modifier un SettingValue.
 * Le seul champ du formulaire est généré à partir d'un ModuleSetting donné.
 * Le form se sauvegarde automtiquement en cas de modification.
 */
function SettingValueForm({
    value,
    contentModule,
    moduleSetting,
    onSuccess,
    onError,
    module,
}: SettingValueFormProps) {
    const settingValueIdRef = useRef(value?.id);

    const getSettingValueId = useCallback(
        () => settingValueIdRef.current || null,
        []
    );
    const setSettingValueId = useCallback((id: string) => {
        settingValueIdRef.current = id;
    }, []);

    const initialValues: SettingValueFormValue = useMemo(
        () => initValue(value || {}, moduleSetting),
        [value, moduleSetting]
    );

    const [saved, setSaved] = useState(Boolean(value?.id && value?.value));

    // les propriétés qu'il faut envoyer à l'api mais qui ne font pas parti du formulaire
    const fixedProperties = useMemo(
        () => ({
            moduleSetting: entityToId(moduleSetting)!,
            contentModule: entityToId(contentModule)!,
        }),
        [moduleSetting, contentModule]
    );

    const submit = useSubmitSettingValue(
        getSettingValueId,
        setSettingValueId,
        setSaved,
        settingValueFormSchema,
        onSuccess,
        onError,
        fixedProperties
    );

    return (
        <Formik
            validationSchema={settingValueFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <SettingValueFormInner
                    moduleSetting={moduleSetting}
                    getSettingValueId={getSettingValueId}
                    saved={saved}
                    initialValue={value}
                    module={module}
                />
            </Form>
        </Formik>
    );
}

export default SettingValueForm;
