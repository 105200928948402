import { setContext } from '@apollo/client/link/context';

/**
 * @see https://www.apollographql.com/docs/react/networking/authentication/#header
 */
const createAuthorizationLink = (localStorageKeyTokenKey: string) => {
    return setContext((_, { headers }) => {
        const token = localStorage.getItem(localStorageKeyTokenKey);

        if (!token) {
            return headers;
        }

        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : '',
            },
        };
    });
};

export default createAuthorizationLink;
