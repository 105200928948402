import React from 'react';
import { Alert, Theme } from '@mui/material';
import SharedFileResponse from '@acdc/shared/src/features/sharedFile/SharedFileResponse';
import ModuleFile from '@acdc/shared/src/features/moduleFile/ModuleFile.model';
import PickModuleFileForm from './PickModuleFileForm';
import DialogButton from '../../ui/DialogButton';

type PickModuleFileFormDialogButtonProps = {
    children: React.ReactNode;
    buttonComponent?: React.ComponentType<{ onClick: () => void }>;
    buttonProps?: any;
    value: ModuleFile[];
    disableDropzone: boolean;
    sharedFiles: SharedFileResponse[] | undefined;
    onChange: (e: {
        target: { name: string | null; value: ModuleFile[] };
    }) => void;
    name: string;
    disabled: boolean | undefined;
    apiEntrypoint: string;
    multiple: boolean;
    colorSelect: (theme: Theme) => string;
};

function PickModuleFileFormDialogButton({
    children,
    buttonComponent,
    buttonProps,
    value,
    disableDropzone,
    sharedFiles,
    onChange,
    name,
    disabled,
    apiEntrypoint,
    multiple,
    colorSelect,
}: PickModuleFileFormDialogButtonProps) {
    return (
        <DialogButton
            label={children}
            buttonComponent={buttonComponent}
            buttonProps={{
                variant: 'contained',
                title: multiple
                    ? 'Ajouter des partenaires'
                    : 'Ajouter un partenaire',
                'data-testid': 'previewContentDialogButton',
                ...buttonProps,
                sx: {
                    background: 'white',
                    '&:hover': {
                        background: (theme: Theme) => theme.palette.grey[200],
                        boxShadow: 'none',
                    },
                    color: (theme: Theme) => theme.palette.text.primary,
                    boxShadow: 'none',
                },
            }}
            dialogProps={{
                id: 'pickSharedFile',
                mainTitle: multiple
                    ? 'Choisissez vos partenaires'
                    : 'Choissisez votre partenaire',
                maxWidth: false,
                fullWidth: false,
                sx: { '& .MuiDialogContent-root': { padding: 2 } },
            }}
        >
            {function form(close) {
                return sharedFiles?.length ? (
                    <PickModuleFileForm
                        value={value}
                        onChange={(ev) => {
                            onChange && onChange(ev);
                            close();
                        }}
                        name={name}
                        sharedFiles={sharedFiles}
                        disableDropzone={disableDropzone}
                        disabled={disabled}
                        apiEntrypoint={apiEntrypoint}
                        multiple={multiple}
                        colorSelect={colorSelect}
                    />
                ) : (
                    <Alert severity="warning">
                        Vous devez téléverser des images de partenaires en
                        utilisant l&apos;API de MonAppliConseiller.
                    </Alert>
                );
            }}
        </DialogButton>
    );
}

export default PickModuleFileFormDialogButton;
