import React from 'react';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import { Box, Grid } from '@mui/material';
import ListChaptersResponse from '@acdc/shared/src/features/chapter/ListChaptersResponse';
import ChaptersListItem from './ChaptersListItem';
import {
    MoveContentFn,
    RemoveChapterContentFn,
} from '../../presentationFlow/PresentationFlowConfigurator/usePresentationFlowConfigurator';

export interface ChaptersListProps {
    chapters: ListChaptersResponse | null | undefined;
    moveContent: MoveContentFn;
    removeChapterContent: RemoveChapterContentFn;
}

function ChaptersList({
    chapters,
    moveContent,
    removeChapterContent,
}: ChaptersListProps) {
    return (
        <Box px={2} mx={4}>
            <Grid container spacing={2} columns={15}>
                {chapters?.chapters?.collection.map(
                    (chapter: ChapterResponse, index) => (
                        <Grid
                            item
                            sm={5}
                            md={3}
                            key={chapter.id}
                            position="relative"
                        >
                            <ChaptersListItem
                                itemNumber={index + 1}
                                chapter={chapter}
                                moveContent={moveContent}
                                removeChapterContent={removeChapterContent}
                            />
                        </Grid>
                    )
                )}
            </Grid>
        </Box>
    );
}

export default ChaptersList;
