export const bytesToMegaBytes = (bytes: number): number => bytes / 1048576; // bytes / (1024*1024)
export const megaBytesToBytes = (megaBytes: number): number =>
    megaBytes * 1048576; // megaBytes * (1024*1024)

export const isMimeTypeImage = (
    mimeType: string | null | undefined
): boolean => {
    return mimeType?.split('/')[0] === 'image';
};

export const isFileImage = (file: File): boolean => {
    return isMimeTypeImage(file.type);
};

/**
 * Extensions de fichiers image supportés par l'application.
 * En lowercase.
 */
export const imagesExtensions = [
    '.jpeg',
    '.jpg',
    '.png',
    '.gif',
    '.avif',
    '.webp',
    '.ico',
];

export const acceptImage = {
    'image/*': imagesExtensions,
};

export const pdfExtensions = ['.pdf'];

export const acceptPdf = {
    'application/*': pdfExtensions,
};

export function getFilenameExtension(filename: string): string | null {
    const parts = filename.split('.');
    if (parts.length === 1) {
        return null;
    }

    return `.${parts[parts.length - 1]}`;
}

export function preloadImage(
    src: string,
    onload?: ((this: GlobalEventHandlers, ev: Event) => any) | null
) {
    const img = new Image();
    img.src = src;
    if (onload) {
        img.onload = onload;
    }
}
