import NotificationResponse from '@acdc/shared/src/features/notification/NotificationResponse';
import { TableContainer } from '@mui/material';
import {
    Table,
    BaseTableParams,
    TableHeader,
    TablePagination,
    useTableFilters,
} from '@acdc/shared/src/ui/Table';
import React, { useEffect } from 'react';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { gql, useLazyQuery } from '@apollo/client';
import ListNotificationsResponse from '@acdc/shared/src/features/notification/ListNotificationsResponse';
import NotificationsListItem from './NotificationsListItem';

export const GET_NOTIFICATIONS = gql`
    query GetNotifications($page: Int, $itemsPerPage: Int) {
        notifications(
            order: [{ id: "DESC" }]
            page: $page
            itemsPerPage: $itemsPerPage
        ) {
            collection {
                id
                title
                createdAt
                notificationStatus {
                    id
                    code
                    label
                    color
                }
            }
            paginationInfo {
                itemsPerPage
                totalCount
            }
        }
    }
`;

const headers: TableHeader[] = [
    {
        id: 'title',
        label: 'Titre',
        sortable: false,
    },
    {
        id: 'createdAt',
        label: 'Date de création',
        sortable: false,
    },
    {
        id: 'notificationStatus',
        label: 'Statut',
        sortable: false,
    },
    {
        id: 'action',
        label: 'Action',
        sortable: false,
    },
];

function NotificationsList() {
    const setAlert = useAlert();

    const { filters, setOrder, setPagination, toVariables } =
        useTableFilters<BaseTableParams>({
            orderBy: headers[0].id,
            orderDirection: 'asc',
            page: 1,
            itemsPerPage: 30,
        });

    const [query, { data, loading, error }] =
        useLazyQuery<ListNotificationsResponse>(GET_NOTIFICATIONS, {
            fetchPolicy: 'network-only',
        });

    useEffect(() => {
        query({
            variables: toVariables(filters),
        });
    }, [query, filters, toVariables]);

    useEffect(() => {
        if (error) {
            setAlert('Une erreur est survenue.', 'error');
        }
    }, [error, setAlert]);

    return (
        <TableContainer sx={{ px: 5, mt: 5 }}>
            <Table
                headers={headers}
                orderBy={filters.orderBy}
                orderDirection={filters.orderDirection}
                setOrder={setOrder}
                loading={Boolean(loading || error)}
            >
                {data?.notifications?.collection.map(
                    (notification: NotificationResponse) => (
                        <NotificationsListItem
                            key={notification.id}
                            notification={notification}
                        />
                    )
                )}
            </Table>
            <TablePagination
                page={filters.page}
                itemsPerPage={filters.itemsPerPage}
                setPagination={setPagination}
                totalCount={
                    data?.notifications?.paginationInfo?.totalCount || 0
                }
            />
        </TableContainer>
    );
}

export default NotificationsList;
