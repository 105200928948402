import React, { useEffect, useState } from 'react';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import { useSearchParams, Link } from 'react-router-dom';
import { Alert, Box, Button, CircularProgress } from '@mui/material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import UserResponse from '../user/UserResponse';

const defaultErrorMessage = 'Une erreur est survenu lors de la connexion.';

function translateError(error: ApolloError) {
    switch (error.message) {
        case 'Unable to authenticate the user with such service ticket.':
            return "Votre compte n'a pas accès à Mon appli conseiller.";
        default:
            return defaultErrorMessage;
    }
}

const logoutSx = {
    mt: 4,
};

export const CONNEXION_CAS_USER = gql`
    mutation loginCASUser($ticket: String!, $service: String!) {
        loginCASUser(input: { ticket: $ticket, service: $service }) {
            user {
                id
                code
                token
                refreshToken
                roles
                firstname
                lastname
            }
        }
    }
`;

interface MutateUserResult {
    loginCASUser: {
        user: UserResponse;
    };
}

interface ValidateTicketProps {
    onSuccess?: (user: UserResponse) => void;
    loginUrl: string;
    validationUrl: string;
    casEntrypoint: string;
    isAllowed?: ((user: UserResponse) => boolean) | undefined;
}

function ValidateTicket({
    onSuccess,
    loginUrl,
    validationUrl,
    casEntrypoint,
    isAllowed,
}: ValidateTicketProps) {
    const [params] = useSearchParams();
    const ticket = params.get('ticket');
    const [errorMessage, setErrorMessage] = useState<string | null>(
        ticket ? null : defaultErrorMessage
    );
    const [mutate, { loading, error }] = useMutation(CONNEXION_CAS_USER);

    useEffect(() => {
        if (!ticket) {
            return;
        }
        mutate({ variables: { ticket, service: validationUrl } }).then(
            (res: FetchResult<MutateUserResult>) => {
                if (!res.data) {
                    throw Error('Missing data result');
                }

                const { user } = res.data.loginCASUser;

                if (isAllowed && !isAllowed(user)) {
                    setErrorMessage(
                        'Vous devez avoir le rôle administrateur siège pour pouvoir accéder au BO.'
                    );
                    return;
                }

                onSuccess && onSuccess(user);
            }
        );
    }, [mutate, onSuccess, ticket, validationUrl, isAllowed, setErrorMessage]);

    useEffect(() => {
        if (error) {
            setErrorMessage(translateError(error));
        }
    }, [error, setErrorMessage]);

    return (
        <Box>
            {loading && <CircularProgress />}
            {errorMessage && (
                <Box>
                    <Alert severity="error" data-testid="ErrorMessage">
                        {errorMessage}
                    </Alert>
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        mt={2}
                    >
                        <Button
                            variant="contained"
                            component={Link}
                            to={loginUrl}
                        >
                            Réessayer
                        </Button>
                        <Button
                            component="a"
                            href={`${casEntrypoint}/logout`}
                            startIcon={<HighlightOffRoundedIcon />}
                            sx={logoutSx}
                        >
                            Se déconnecter globalement de Laforêt
                        </Button>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default ValidateTicket;
