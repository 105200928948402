import Content from '@acdc/shared/src/features/content/Content.model';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import HandymanIcon from '@mui/icons-material/Handyman';
import DeleteIcon from '@mui/icons-material/Delete';
import MultiStepContentFormDialogButton from '../ContentForm/MultiStepContentFormDialogButton';
import PreviewContentDialogButton from '../PreviewContentDialogButton';
import DuplicateContentButtonAndUpdate from '../DuplicateContentButton/DuplicateContentButtonAndUpdate';
import DeleteContentButton from '../DeleteContentButton';

const rootSx = {
    height: '100%',
    overflow: 'hidden',
    borderRadius: 1,
    position: 'relative',
    '& .ContentsListItemActions': {
        display: 'none',
    },
    '&:hover .ContentsListItemActions': {
        display: 'flex',
    },
};

const actionsSx = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    flexFlow: 'row nowrap',
    '& > button:not(:first-of-type)': {
        marginLeft: 2,
    },
};

const toolIconSx = {
    transform: 'translateY(2px)',
    mr: 1,
    fontSize: '1rem',
};

const actionProps = {
    sx: {
        backgroundColor: 'grey.400',
        '&:hover': {
            backgroundColor: 'grey.600',
        },
    },
};

export interface ContentsListItemProps {
    content: ContentResponse;
}

function ContentsListItem({ content }: ContentsListItemProps) {
    const contentToUpdate = useMemo<DeepPartial<Content>>(
        () => ({
            id: content.id,
            // on laisse MultiStepContentForm charger les autres champs
        }),
        [content.id]
    );

    return (
        <Box sx={rootSx} data-testid="ContentsListItemRoot">
            <Box
                component="img"
                height="100%"
                width="100%"
                src="/blank.jpg"
                alt={content.title}
                loading="lazy"
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    paddingX: 2.5,
                    paddingY: 1,
                    backgroundColor: (theme: Theme) =>
                        theme.palette.grey['700'],
                    borderTopRightRadius: (theme: Theme) =>
                        theme.shape.borderRadius,
                }}
            >
                <Typography variant="body1" component="span">
                    {content.isTool && <HandymanIcon sx={toolIconSx} />}{' '}
                    {content.title}
                </Typography>
            </Box>
            <Box
                className="ContentsListItemActions"
                sx={actionsSx}
                data-testid="ContentsListItemActions"
            >
                <MultiStepContentFormDialogButton
                    id={`update-content-${getIdFromIri(entityToId(content))}`}
                    value={contentToUpdate}
                    buttonComponent={IconButton}
                    buttonProps={actionProps}
                >
                    <EditIcon />
                </MultiStepContentFormDialogButton>
                <PreviewContentDialogButton
                    content={`${entityToId(content)}`}
                    buttonComponent={IconButton}
                    buttonProps={actionProps}
                >
                    <VisibilityIcon />
                </PreviewContentDialogButton>
                <DuplicateContentButtonAndUpdate
                    content={entityToId(content)!}
                    buttonComponent={IconButton}
                    {...actionProps}
                >
                    <ContentCopyIcon />
                </DuplicateContentButtonAndUpdate>
                <DeleteContentButton
                    content={content}
                    buttonComponent={IconButton}
                    {...actionProps}
                >
                    <DeleteIcon />
                </DeleteContentButton>
            </Box>
        </Box>
    );
}

export default ContentsListItem;
