import Yup from '@acdc/shared/src/yup/yupFr';

const toolFormSchema = Yup.object().shape({
    title: Yup.string().label('Le libellé').required(),
    toolCategory: Yup.mixed().label("La rubrique d'outils").required(),
    required: Yup.bool().label("Le droit d'affichage").required(),
    canUpdateTitle: Yup.bool()
        .label('Autoriser la modification du titre')
        .required(),
    toolThumbnail: Yup.array().label("L'image").optional(),
});

export default toolFormSchema;
