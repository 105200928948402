import { Button } from '@mui/material';
import React from 'react';
import NotificationResponse from '@acdc/shared/src/features/notification/NotificationResponse';
import useDeleteNotification from './useDeleteNotification';

interface DeleteNotificationButtonProps {
    notification: NotificationResponse;
    children: React.ReactNode;
    buttonProps?: any;
}

function DeleteNotificationButton({
    notification,
    children,
    buttonProps,
}: DeleteNotificationButtonProps) {
    const [deleteNotification, isLoading] = useDeleteNotification();

    return (
        <Button
            onClick={() => {
                const title =
                    typeof notification !== 'string' && notification.title
                        ? ` "${notification.title}"`
                        : '';
                if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                        `Êtes-vous sûr de vouloir supprimer la notification ${title} ?`
                    )
                ) {
                    deleteNotification(notification);
                }
            }}
            disabled={isLoading}
            {...buttonProps}
        >
            {children}
        </Button>
    );
}

export default DeleteNotificationButton;
