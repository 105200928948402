import ModuleSettingCode from '../moduleSetting/ModuleSettingCode.enum';

const moduleSettingsCodePartner = () => {
    return [
        ModuleSettingCode.IMAGE_PARTNER1,
        ModuleSettingCode.IMAGE_PARTNER2,
        ModuleSettingCode.IMAGE_PARTNER3,
        ModuleSettingCode.IMAGE_PARTNER4,
        ModuleSettingCode.IMAGE_PARTNER5,
        ModuleSettingCode.IMAGE_PARTNER6,
        ModuleSettingCode.IMAGE_PARTNER7,
        ModuleSettingCode.IMAGE_PARTNER8,
    ];
};

export default moduleSettingsCodePartner;
