import React from 'react';
import LogoutPage from '@acdc/shared/src/features/auth/LogoutPage';
import CONFIGS from '../configs';

function Logout() {
    return (
        <LogoutPage
            loginUrl="/auth/connexion"
            casEntrypoint={CONFIGS.casEntrypoint}
        />
    );
}

export default Logout;
