import React from 'react';
import UserResponse from '../user/UserResponse';
import LocalStorageKeys from '../../apollo/LocalStorageKeys';
import UserDataStorageKeys from '../../security/UserDataStorageKeys';
import ValidateTicket from './ValidateTicket';
import AuthLayout, { AuthLayoutProps } from './AuthLayout';
import useOnLoginSuccess from './useOnLoginSuccess';

interface ValidationPageProps {
    authStorageKeys: LocalStorageKeys;
    userDataStorageKeys: UserDataStorageKeys;
    isAllowed?: (user: UserResponse) => boolean;
    sx?: AuthLayoutProps['sx'];
    loginUrl: string;
    validationUrl: string;
    casEntrypoint: string;
}

function ValidationPage({
    isAllowed,
    sx,
    authStorageKeys,
    userDataStorageKeys,
    loginUrl,
    validationUrl,
    casEntrypoint,
}: ValidationPageProps) {
    const onLoginSuccess = useOnLoginSuccess(
        authStorageKeys,
        userDataStorageKeys
    );

    return (
        <AuthLayout title="Connexion" sx={sx}>
            <ValidateTicket
                onSuccess={onLoginSuccess}
                loginUrl={loginUrl}
                validationUrl={validationUrl}
                casEntrypoint={casEntrypoint}
                isAllowed={isAllowed}
            />
        </AuthLayout>
    );
}

export default ValidationPage;
