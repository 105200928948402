import React from 'react';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import SubmitButton from '@acdc/shared/src/formik/SubmitButton';
import PresentationFlow from '@acdc/shared/src/features/presentationFlow/PresentationFlow.model';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import MutatePresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/MutatePresentationFlowResponse';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { Box, Stack } from '@mui/material';
import { TextField } from 'formik-mui';
import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import FormSkeleton from '@acdc/shared/src/ui/FormSkeleton';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import Content from '@acdc/shared/src/features/content/Content.model';
import useInitialValues from './useInitialValues';
import ContentSelect from '../../content/ContentSelect';

export const CREATE_PRESENTATION_FLOW = gql`
    mutation CreatePresentationFlow($input: createPresentationFlowInput!) {
        createPresentationFlow(input: $input) {
            presentationFlow {
                id
            }
        }
    }
`;

export const UPDATE_PRESENTATION_FLOW = gql`
    mutation UpdatePresentationFlow($input: updatePresentationFlowInput!) {
        updatePresentationFlow(input: $input) {
            presentationFlow {
                id
            }
        }
    }
`;

export interface PresentationFlowFormValue {
    label: string;
    code: string;
    coverPage: string | DeepPartial<Content> | null;
}

const presentationFlowFormSchema = Yup.object().shape({
    label: Yup.string().label('Le libellé').required(),
    code: Yup.string().label('Code de la présentation').required(),
    coverPage: Yup.mixed().label('La page de garde').optional(),
});

export interface PresentationFlowFormProps {
    value?: DeepPartial<PresentationFlow> | undefined;
    onSuccess?: ((res: PresentationFlowResponse) => void) | undefined;
    onError?: ((err: ApolloError) => void) | undefined;
}

/**
 * Formulaire de PresentationFlow.
 * Si la valeur fournie a un id le PresentationFlow est chargé depuis l'api avant de rendre le formulaire.
 */
function PresentationFlowForm({
    value,
    onSuccess,
    onError,
}: PresentationFlowFormProps) {
    const isUpdate: boolean = !!value?.id;
    const initialValues = useInitialValues(value);
    const setAlert = useAlert();

    const [mutate] = useMutation(
        isUpdate ? UPDATE_PRESENTATION_FLOW : CREATE_PRESENTATION_FLOW,
        {
            update(cache) {
                cache.evict({ fieldName: 'presentationFlows' });
            },
        }
    );

    if (!initialValues) {
        return <FormSkeleton nbInputs={1} />;
    }

    return (
        <Formik
            validationSchema={presentationFlowFormSchema}
            initialValues={initialValues}
            onSubmit={(
                values: PresentationFlowFormValue,
                {
                    setSubmitting,
                    setErrors,
                }: FormikHelpers<PresentationFlowFormValue>
            ) => {
                const input: DeepPartial<PresentationFlow> = {
                    ...values,
                    coverPage: entityToId(values.coverPage),
                };

                if (isUpdate) {
                    input.id = value?.id;
                }

                mutate({ variables: { input } })
                    .finally(() => {
                        setSubmitting(false);
                    })
                    .then(
                        (res: FetchResult<MutatePresentationFlowResponse>) => {
                            const resItem:
                                | PresentationFlowResponse
                                | null
                                | undefined = isUpdate
                                ? res.data?.updatePresentationFlow
                                      ?.presentationFlow
                                : res.data?.createPresentationFlow
                                      ?.presentationFlow;

                            if (!resItem?.id) {
                                // eslint-disable-next-line no-console
                                console.error('Missing data result', res.data);
                                return;
                            }

                            onSuccess && onSuccess(resItem);
                        }
                    )
                    .catch(
                        handleApolloError(
                            setErrors,
                            setAlert,
                            presentationFlowFormSchema,
                            onError
                        )
                    );
            }}
        >
            <Form data-testid="presentationFlowForm">
                <Stack direction="column" spacing={2}>
                    <Field
                        component={TextField}
                        inputProps={{ 'data-testid': 'label' }}
                        id="label"
                        label="Nom"
                        name="label"
                        required
                    />
                    <Field
                        component={TextField}
                        inputProps={{ 'data-testid': 'code' }}
                        id="code"
                        label="Code"
                        name="code"
                        helperText="Code utilisé par l’API pour lier les biens aux déroulés."
                        required
                    />
                    <Field
                        component={ContentSelect}
                        id="coverPage"
                        label="Page de garde dans l'export PDF"
                        name="coverPage"
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <SubmitButton
                            buttonProps={{
                                'data-testid':
                                    'presentationFlowFormSubmitButton',
                                sx: { width: '396px' },
                            }}
                        >
                            Suivant
                        </SubmitButton>
                    </Box>
                </Stack>
            </Form>
        </Formik>
    );
}

export default PresentationFlowForm;
