import ListToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ListToolCategoryResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

export const GET_TOOL_CATEGORIES = gql`
    query GetToolCategories {
        toolCategories(itemsPerPage: 10000) {
            collection {
                id
                label
            }
        }
    }
`;

const useQueryToolCategoriesToSelect = () => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<ListToolCategoryResponse>(
        GET_TOOL_CATEGORIES
        // pas besoin de fetchPolicy: 'network-only',
    );

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement des rubriques d'outils.",
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default useQueryToolCategoriesToSelect;
