import type Notification from '@acdc/shared/src/features/notification/Notification.model';
import { initString } from '@acdc/shared/src/utils/form-helpers';
import { useEffect, useMemo } from 'react';
import type { NotificationFormValue } from './NotificationForm';
import useLazyNotificationToUpdate from './useLazyNotificationToUpdate';

const initValue = (
    value: DeepPartial<Notification>
): NotificationFormValue => ({
    title: initString(value?.title),
    description: initString(value?.description),
    notificationStatus: value?.notificationStatus || null,
});

const useInitialValues = (
    value: DeepPartial<Notification> | undefined
): NotificationFormValue | null => {
    const isUpdate: boolean = !!value?.id;

    // chargement de la valeur de l'api si besoin
    const [query, { data: notificationResponse }] = useLazyNotificationToUpdate(
        value?.id
    );
    useEffect(() => {
        if (isUpdate) {
            query();
        }
    }, [query, isUpdate]);
    const notificationFromResponse = useMemo<
        DeepPartial<Notification> | undefined
    >(() => {
        if (!notificationResponse?.notification) {
            return undefined;
        }
        return {
            id: notificationResponse?.notification?.id,
            title: notificationResponse?.notification?.title,
            description: notificationResponse?.notification?.description,
            notificationStatus:
                notificationResponse?.notification?.notificationStatus,
        };
    }, [notificationResponse?.notification]);

    const initialValue = isUpdate ? notificationFromResponse : value;

    const initialFormValues: NotificationFormValue = useMemo(
        () => initValue(initialValue || {}),
        [initialValue]
    );

    const isLoaded: boolean = isUpdate ? Boolean(notificationResponse) : true;

    return isLoaded ? initialFormValues : null;
};

export default useInitialValues;
