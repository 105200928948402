import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import GetToolResponse from '@acdc/shared/src/features/tool/GetToolResponse';

export const GET_TOOL_DETAIL = gql`
    query GetToolDetail($id: ID!) {
        tool(id: $id) {
            id
            title
        }
    }
`;

/**
 * Retourne les infos de base d'un outil
 */
const useToolDetail = (id: string) => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<GetToolResponse>(
        GET_TOOL_DETAIL,
        {
            variables: { id },
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement de l'outil.",
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default useToolDetail;
