import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { useMutation, gql } from '@apollo/client';
import { useCallback, useState } from 'react';

export const DELETE_VARIABLE = gql`
    mutation DeleteVariable($id: ID!) {
        deleteVariable(input: { id: $id }) {
            clientMutationId
        }
    }
`;

function useDeleteVariable(
    onDeleted?: (variableId: string) => void
): [(variable: string | VariableResponse) => void, boolean] {
    const [doDelete] = useMutation(DELETE_VARIABLE, {
        // pas d'eviction du cache car pas pratique dans ContentModuleConfigurator
    });
    const setAlert = useAlert();
    const [isDeleting, setIsDeleteting] = useState<boolean>(false);

    const deleteItem = useCallback(
        (content: string | VariableResponse) => {
            const id = entityToId(content);

            if (!id) {
                return;
            }

            setIsDeleteting(true);

            doDelete({ variables: { id } })
                .finally(() => {
                    setIsDeleteting(false);
                })
                .then(() => {
                    onDeleted && onDeleted(id);
                })
                .catch(() => {
                    setAlert(
                        'Une erreur est survenue lors de la suppression de la balise.',
                        'error'
                    );
                });
        },
        [onDeleted, setIsDeleteting, doDelete, setAlert]
    );

    return [deleteItem, isDeleting];
}

export default useDeleteVariable;
