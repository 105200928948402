import React from 'react';
import ValidationPage from '@acdc/shared/src/features/auth/ValidationPage';
import CONFIGS from '../../configs';
import isAllowedToLoginToBO from './isAllowedToLoginToBO';

function Validation() {
    return (
        <ValidationPage
            authStorageKeys={CONFIGS.authStorageKeys}
            casEntrypoint={CONFIGS.casEntrypoint}
            userDataStorageKeys={CONFIGS.userDataStorageKeys}
            loginUrl="/auth/connexion"
            validationUrl={`${CONFIGS.boEntrypoint}/auth/validation`}
            isAllowed={isAllowedToLoginToBO}
        />
    );
}

export default Validation;
