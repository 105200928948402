import React from 'react';
import PendingIcon from '@mui/icons-material/Pending';
import SaveIcon from '@mui/icons-material/Save';
import { keyframes, SxProps, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const wink = keyframes`
    0% { opacity: .2; }
    50% { opacity: .9; }
    100% { opacity: .2; }
`;

const loaderSx = {
    animation: `${wink} 2.5s ease-in-out infinite`,
};

export interface SavingStatusProps {
    saved: boolean;
    pendingSave: boolean;
    isSubmitting: boolean;
    sx?: SxProps | undefined;
}

/**
 * Affiche un icon représentant le statut de sauvegarde d'un truc.
 */
function SavingStatus({
    saved,
    pendingSave,
    isSubmitting,
    sx,
}: SavingStatusProps) {
    if (pendingSave) {
        return (
            <Tooltip
                title="Modifications en attente de sauvegarde..."
                placement="top-start"
            >
                <PendingIcon
                    color="warning"
                    sx={{
                        ...sx,
                        ...loaderSx,
                    }}
                    data-testid="pendingStatus"
                />
            </Tooltip>
        );
    }
    if (isSubmitting) {
        return (
            <Tooltip title="Sauvegarde en cours..." placement="top-start">
                <SaveIcon
                    color="primary"
                    sx={{
                        ...sx,
                        ...loaderSx,
                    }}
                    data-testid="savingStatus"
                />
            </Tooltip>
        );
    }
    if (saved) {
        return (
            <Tooltip title="Sauvegardé" placement="top-start">
                <CheckCircleIcon
                    color="success"
                    sx={sx}
                    data-testid="savedStatus"
                />
            </Tooltip>
        );
    }

    return null;
}

export default SavingStatus;
