import React, { useMemo } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import { TextField } from 'formik-mui';
import SubmitButton from '@acdc/shared/src/formik/SubmitButton';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import { initBool, initString } from '@acdc/shared/src/utils/form-helpers';
import BaseContentModel from '@acdc/shared/src/features/baseContent/BaseContent.model';
import { Stack, Divider, FormLabel } from '@mui/material';
import BigSwitch from '@acdc/shared/src/formik/BigSwitch';

interface ContentFormValue {
    title: string;
    required: boolean;
}

const contentFormSchema = Yup.object().shape({
    title: Yup.string().label('Le titre').required(),
    required: Yup.bool().label("Le droit d'affichage").optional(),
});

const initValue = (value: DeepPartial<BaseContentModel>): ContentFormValue => {
    return {
        title: initString(value?.title),
        required: initBool(value?.required, true),
    };
};

export interface ContentFormProps {
    value?: DeepPartial<BaseContentModel>;
    onSuccess?: (content: DeepPartial<BaseContentModel>) => void;
}

/**
 * Formulaire pour définir les propriétés de base d'un contenu.
 * Attention, le contenu n'est pas créé sur l'API ici.
 */
function ContentForm({ value, onSuccess }: ContentFormProps) {
    const initialValues: ContentFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    return (
        <Formik
            validationSchema={contentFormSchema}
            initialValues={initialValues}
            onSubmit={(values: ContentFormValue) => {
                onSuccess && onSuccess(values);
            }}
        >
            <Form data-testid="contentForm">
                <Stack
                    spacing={2}
                    divider={<Divider orientation="horizontal" flexItem />}
                >
                    <Field
                        component={TextField}
                        inputProps={{ 'data-testid': 'title' }}
                        id="title"
                        label="Titre de la page"
                        name="title"
                    />
                    <div>
                        <FormLabel
                            htmlFor="required"
                            required
                            sx={{ display: 'block' }}
                        >
                            Droits d&apos;affichage
                        </FormLabel>
                        <Field
                            component={BigSwitch}
                            inputProps={{
                                'data-testid': 'required',
                            }}
                            id="required"
                            label="Droits d'affichage"
                            name="required"
                            type="checkbox"
                            checkedLabel="Obligatoire"
                            uncheckedLabel="Optionel"
                        />
                        <ErrorMessage
                            name="required"
                            component={ErrorHelperText}
                        />
                    </div>

                    <SubmitButton
                        buttonProps={{
                            'data-testid': 'contentFormSubmitButton',
                            sx: { width: '100%' },
                        }}
                    >
                        Suivant
                    </SubmitButton>
                </Stack>
            </Form>
        </Formik>
    );
}

export default ContentForm;
