import React from 'react';
import { FieldProps } from 'formik';
import { fieldToToggleButtonGroup } from 'formik-mui';
import TemplateSelect, { TemplateSelectProps } from './TemplateSelect';

export interface FormikTemplateSelectProps
    extends FieldProps,
        Omit<TemplateSelectProps, 'name' | 'value' | 'error'> {
    type?: string;
}

/**
 * Ajoutez type=checkbox et utilisez un valeur de type array pour ce composant avec Field.
 * Voir https://stackworx.github.io/formik-mui/docs/api/mui#togglebuttongroup et ./FormikTemplateSelect.test.tsx
 */
function FormikTemplateSelect({
    templates,
    ...props
}: FormikTemplateSelectProps) {
    return (
        <TemplateSelect
            {...fieldToToggleButtonGroup(props)}
            templates={templates}
        />
    );
}

export default FormikTemplateSelect;
