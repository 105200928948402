import NotificationStatusResponse from '@acdc/shared/src/features/notificationStatus/NotificationStatusResponse';
import Autocomplete, {
    AutocompleteProps,
} from '@acdc/shared/src/formik/Autocomplete';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import React from 'react';
import useQueryNotificationStatuses from './useQueryNotificationStatuses';

function getOptionLabel(option: string | NotificationStatusResponse) {
    return typeof option === 'string' ? option : `${option.label}`;
}
function NotificationStatusSelect(props: AutocompleteProps) {
    const { data, loading } = useQueryNotificationStatuses();

    return (
        <Autocomplete
            {...props}
            options={data?.notificationStatuses?.collection || []}
            disableClearable
            getOptionLabel={getOptionLabel}
            loading={loading}
            isOptionEqualToValue={matchOnId}
            noOptionsText="Pas de résultats"
            data-testid="notificationStatus"
        />
    );
}

export default NotificationStatusSelect;
