import React from 'react';
import { TableHead, TableBody, Table as MUITable } from '@mui/material';
import TableRowsSkeleton from './TableRowsSkeleton';
import TableHeaders, { TableHeadersProps } from './TableHeaders';

interface TableProps extends TableHeadersProps {
    children: React.ReactNode;
    loading?: boolean;
}

function Table({
    children,
    headers,
    setOrder,
    orderDirection,
    orderBy,
    loading,
}: TableProps) {
    return (
        <MUITable size="small">
            <TableHead>
                <TableHeaders
                    headers={headers}
                    orderBy={orderBy}
                    orderDirection={orderDirection}
                    setOrder={setOrder}
                />
            </TableHead>
            <TableBody>
                {loading && (
                    <TableRowsSkeleton rows={2} cols={headers.length} />
                )}
                {!loading && children}
            </TableBody>
        </MUITable>
    );
}

export default Table;
