import {
    formatDistanceToNow,
    formatDistanceToNowStrict,
    isValid,
} from 'date-fns';
import frLocale from 'date-fns/locale/fr';

export function toLocaleDateTimeString(date: string | Date | number): string {
    const toDate = new Date(date);
    if (!isValid(toDate)) {
        return '';
    }

    return `${toDate.toLocaleDateString()} ${toDate.toLocaleTimeString()}`;
}

/**
 * formatDistanceToNow de date-fns mais en français.
 * Todo : passer date-fns en 2.29.9 ou plus pour utiliser https://date-fns.org/v2.29.0/docs/setDefaultOptions
 */
export function formatLocaleDistanceToNow(
    date: string | Date | number
): string {
    const toDate = new Date(date);
    if (!isValid(toDate)) {
        return '';
    }

    return formatDistanceToNow(toDate, { locale: frLocale });
}

/**
 * formatDistanceToNowStrict de date-fns mais en français.
 * Todo : passer date-fns en 2.29.9 ou plus pour utiliser https://date-fns.org/v2.29.0/docs/setDefaultOptions
 */
export function formatLocaleDistanceToNowStrict(
    date: string | Date | number
): string {
    const toDate = new Date(date);
    if (!isValid(toDate)) {
        return '';
    }

    return formatDistanceToNowStrict(toDate, { locale: frLocale });
}
