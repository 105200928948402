import { useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import GetContentResponse from '@acdc/shared/src/features/content/GetContentResponse';
import GetToolContentResponse from '@acdc/shared/src/features/toolContent/GetToolContentResponse';
import ToolContentResponse from '@acdc/shared/src/features/toolContent/ToolContentResponse';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';

export const GET_CONTENT_TO_UPDATE = gql`
    query GetContentToUpdate($id: ID!) {
        content(id: $id) {
            id
            title
            required
            template {
                id
            }
        }
    }
`;

// todo : manque required qui doit venir de BaseContent
export const GET_TOOL_CONTENT_TO_UPDATE = gql`
    query GetToolContentToUpdate($id: ID!) {
        toolContent(id: $id) {
            id
            title
            required
            template {
                id
            }
        }
    }
`;

const useLazyContentToUpdate = (
    id: string | null | undefined,
    type: ContentType | undefined
): [
    query: () => void,
    content: ContentResponse | ToolContentResponse | null | undefined
] => {
    const setAlert = useAlert();
    const res = useLazyQuery<GetContentResponse | GetToolContentResponse>(
        type === ContentType.TOOL_CONTENT
            ? GET_TOOL_CONTENT_TO_UPDATE
            : GET_CONTENT_TO_UPDATE,
        {
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        }
    );
    const error = res?.[1]?.error;

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement de la page.',
                'error'
            );
        }
    }, [error, setAlert]);

    const query = res[0];
    const content =
        type === ContentType.TOOL_CONTENT
            ? (res[1].data as GetToolContentResponse)?.toolContent
            : (res[1].data as GetContentResponse)?.content;

    return [query, content];
};

export default useLazyContentToUpdate;
