import React, { useContext, useMemo } from 'react';
import { To } from 'react-router';
import { RolesHierarchy } from './isGranted';

export interface PermissionsCtxValue {
    getUserRoles: () => string[];
    rolesHierarchy: RolesHierarchy | undefined;
    isAuthenticated: () => boolean;
    loginRoute: To;
}

const initialValue: PermissionsCtxValue = {
    getUserRoles: () => [],
    rolesHierarchy: undefined,
    isAuthenticated: () => false,
    loginRoute: '/',
};

const PermissionsCtx = React.createContext<PermissionsCtxValue>(initialValue);

export const usePermissions = () => useContext(PermissionsCtx);

interface PermissionsProviderProps {
    getUserRoles: () => string[];
    rolesHierarchy?: RolesHierarchy;
    isAuthenticated: () => boolean;
    loginRoute: To;
    children?: React.ReactNode;
}

function PermissionsProvider({
    getUserRoles,
    rolesHierarchy,
    isAuthenticated,
    loginRoute,
    children,
}: PermissionsProviderProps) {
    const value = useMemo(
        () => ({
            getUserRoles,
            rolesHierarchy,
            isAuthenticated,
            loginRoute,
        }),
        [getUserRoles, rolesHierarchy, isAuthenticated, loginRoute]
    );

    return (
        <PermissionsCtx.Provider value={value}>
            {children}
        </PermissionsCtx.Provider>
    );
}

export default PermissionsProvider;
