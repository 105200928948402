import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import ToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ToolCategoryResponse';

const useFilterToolsByCategories = (
    tools: ToolResponse[] | null,
    filters: ToolCategoryResponse[]
) => {
    if (tools === null || !filters.length) {
        return tools;
    }

    return tools.filter((tool) => {
        return filters.some((filter) => {
            return tool.toolCategory?.id === filter.id;
        });
    });
};

export default useFilterToolsByCategories;
