import { Form, Formik, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import Yup from '@acdc/shared/src/yup/yupFr';
import SharedFileResponse from '@acdc/shared/src/features/sharedFile/SharedFileResponse';
import ModuleFile from '@acdc/shared/src/features/moduleFile/ModuleFile.model';
import ModuleFileEnum from '@acdc/shared/src/features/moduleFile/ModuleFile.enum';
import { Theme } from '@mui/material';
import PickSharedFileFormInner from './PickModuleFileFormInner';
import { matchOnId } from '../../utils/form-helpers';
import generateLocalUniqueId from '../../utils/generateLocalUniqueId';

export interface PickModuleFileFormValue {
    sharedFiles: string[];
    files: File[];
}

interface PickModuleFileFormProps {
    value: ModuleFile[];
    disableDropzone: boolean;
    sharedFiles: SharedFileResponse[] | undefined;
    onChange: (e: {
        target: { name: string | null; value: ModuleFile[] };
    }) => void;
    name: string;
    disabled: boolean | undefined;
    apiEntrypoint: string;
    multiple: boolean;
    colorSelect: (theme: Theme) => string;
}

const PickMultipleSharedFileFormSchema = Yup.object().shape({
    sharedFiles: Yup.array().label('Galerie').optional(),
    files: Yup.array()
        .label('Ajouter des images')
        .optional()
        .test(
            'maxPartners',
            'Vous ne pouvez pas ajouter plus de 8 partenaires',
            (value, { parent: { sharedFiles } }) => {
                return (value || []).length + (sharedFiles || []).length <= 8;
            }
        ),
});

const PickSingleSharedFileFormSchema = Yup.object().shape({
    sharedFiles: Yup.array().label('Galerie').optional(),
    files: Yup.array()
        .label('Ajouter des images')
        .optional()
        .test(
            'maxPartners',
            'Vous pouvez ajouter 1 partenaire maximum',
            (value, { parent: { sharedFiles } }) => {
                return (value || []).length + (sharedFiles || []).length <= 1;
            }
        ),
});

const loadFile = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const imageUrl = reader.result as string;
            resolve(imageUrl);
        };

        reader.onerror = () => {
            reject(new Error('Error reading file.'));
        };

        reader.readAsDataURL(file);
    });
};

function PickModuleFileForm({
    value,
    disableDropzone,
    sharedFiles,
    onChange,
    name,
    disabled,
    apiEntrypoint,
    multiple,
    colorSelect,
}: PickModuleFileFormProps) {
    const initialValues: PickModuleFileFormValue = useMemo(() => {
        return {
            sharedFiles: value.map(
                (settingFile) => settingFile.sharedFile?.id!
            ),
            files: [],
        };
    }, [value]);

    return (
        <>
            <Formik
                validationSchema={
                    multiple
                        ? PickMultipleSharedFileFormSchema
                        : PickSingleSharedFileFormSchema
                }
                initialValues={initialValues}
                onSubmit={async (values: PickModuleFileFormValue) => {
                    if (!sharedFiles) {
                        return;
                    }

                    // on crée un ModuleFile pour chaque SharedFile selectionné
                    const moduleFilesFromSharedFiles = values.sharedFiles.map(
                        (id) => {
                            // on retrouve l'objet SharedFile
                            const sharedFile = sharedFiles.find((file) =>
                                matchOnId(file, id)
                            );

                            // si le SharedFile était déjà sélectionné on retrouve sa valeur actuelle pour ne pas perdre son id (de SettingValue ou ImageOverride)
                            const matchedValue = value.find((item) =>
                                matchOnId(sharedFile, item.sharedFile)
                            );

                            return {
                                type: ModuleFileEnum.SHARED_FILE,
                                id: matchedValue?.id || undefined,
                                uuid:
                                    matchedValue?.uuid ||
                                    generateLocalUniqueId(),
                                publicPath: sharedFile?.publicPath || undefined,
                                title: sharedFile?.title || '',
                                sharedFile: sharedFile || undefined,
                                file: undefined,
                            };
                        }
                    );

                    // on crée un ModuleFile pour chaque fichier uploadé
                    const moduleFilesFromFilesPromises = values.files.map(
                        (file) => {
                            return loadFile(file).then((imageUrl) => ({
                                type: ModuleFileEnum.FILE,
                                id: undefined,
                                uuid: generateLocalUniqueId(),
                                publicPath: imageUrl,
                                title: '',
                                sharedFile: undefined,
                                file,
                            }));
                        }
                    );

                    Promise.all(moduleFilesFromFilesPromises).then(
                        (moduleFilesFromFiles) => {
                            const newValue = [
                                ...moduleFilesFromFiles,
                                ...moduleFilesFromSharedFiles,
                            ];

                            onChange &&
                                onChange({
                                    target: {
                                        name: name || null,
                                        value: newValue,
                                    },
                                });
                        }
                    );
                }}
            >
                {({
                    values,
                    handleChange,
                }: FormikProps<PickModuleFileFormValue>) => (
                    <Form>
                        <PickSharedFileFormInner
                            sharedFiles={sharedFiles}
                            disabled={disabled}
                            multiple={multiple}
                            files={values.files}
                            disableDropzone={disableDropzone}
                            apiEntrypoint={apiEntrypoint}
                            handleChange={handleChange}
                            colorSelect={colorSelect}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default PickModuleFileForm;
