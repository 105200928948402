import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListSharedFilesResponse from '../sharedFile/ListSharedFilesResponse';

export const GET_SHARED_FILES = gql`
    query GetSharedFiles {
        sharedFiles(itemsPerPage: 10000) {
            collection {
                id
                publicPath
                title
            }
        }
    }
`;

const useQuerySharedFiles = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListSharedFilesResponse>(GET_SHARED_FILES);

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement de la banque d'image.",
                'error'
            );
        }
    }, [error, setAlert]);
    return data;
};

export default useQuerySharedFiles;
