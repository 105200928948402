import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { useMutation, gql } from '@apollo/client';
import { useCallback, useState } from 'react';

export const DELETE_CONTENT_MODULE = gql`
    mutation DeleteContentModule($id: ID!) {
        deleteContentModule(input: { id: $id }) {
            clientMutationId
        }
    }
`;

function useDeleteContentModule(
    onDeleted: (contentModuleId: string) => void
): [(contentModule: string | ContentModuleResponse) => void, boolean] {
    const [deleteContentModule] = useMutation(DELETE_CONTENT_MODULE);
    const setAlert = useAlert();
    const [isDeleting, setIsDeleteting] = useState<boolean>(false);

    const deleteItem = useCallback(
        (contentModule: string | ContentModuleResponse) => {
            const id = entityToId(contentModule);

            if (!id) {
                return;
            }

            setIsDeleteting(true);

            deleteContentModule({ variables: { id } })
                .finally(() => {
                    setIsDeleteting(false);
                })
                .then(() => {
                    onDeleted(id);
                })
                .catch(() => {
                    setAlert(
                        'Une erreur est survenue lors de la suppression du module.',
                        'error'
                    );
                });
        },
        [onDeleted, setIsDeleteting, deleteContentModule, setAlert]
    );

    return [deleteItem, isDeleting];
}

export default useDeleteContentModule;
