import React from 'react';
import {
    DateTimePicker as MuiDateTimePicker,
    DateTimePickerProps,
} from '@mui/lab';

export const frProps: Partial<DateTimePickerProps> = {
    componentsProps: {
        leftArrowButton: {
            title: 'Précédent',
        },
        rightArrowButton: {
            title: 'Suivant',
        },
    },
};

function DateTimePickerFR(props: DateTimePickerProps) {
    return <MuiDateTimePicker {...frProps} {...props} />;
}

export default DateTimePickerFR;
