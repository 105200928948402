import React from 'react';
import BrowserRouter from '@acdc/shared/src/router/BrowserRouter';
import AlertProvider from '@acdc/shared/src/tools/alert/AlertProvider';
import { ApolloProvider } from '@apollo/client';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import PermissionsProvider from '@acdc/shared/src/security/PermissionsProvider';
import {
    getRoles,
    isAuthenticated as apolloIsAuthenticated,
} from '@acdc/shared/src/apollo/auth-tokens';
import ACDC_ROLES_HIERARCHY from '@acdc/shared/src/security/ACDC_ROLES_HIERARCHY';
import { LocalizationProvider } from '@mui/lab';
import DateFnsUtilsFR from '@acdc/shared/src/date-fns/DateFnsUtilsFR';
import frLocale from 'date-fns/locale/fr';
import initApollo from './apollo/initApollo';
import Pages from './pages/Pages';
import ThemeProvider from './mui/ThemeProvider';
import DragDisplayLayer from './ui/DragDisplayLayer';
import CONFIGS from './configs';

const apolloClient = initApollo();

const getUserRoles = () => getRoles(CONFIGS.authStorageKeys) || [];
const isAuthenticated = () => apolloIsAuthenticated(CONFIGS.authStorageKeys);

function App() {
    return (
        <DndProvider backend={HTML5Backend}>
            <ApolloProvider client={apolloClient}>
                <ThemeProvider variant="dark" global>
                    <LocalizationProvider
                        dateAdapter={DateFnsUtilsFR}
                        locale={frLocale}
                    >
                        <DragDisplayLayer />
                        <PermissionsProvider
                            getUserRoles={getUserRoles}
                            rolesHierarchy={ACDC_ROLES_HIERARCHY}
                            isAuthenticated={isAuthenticated}
                            loginRoute="/auth/connexion"
                        >
                            <AlertProvider>
                                <BrowserRouter>
                                    <Pages />
                                </BrowserRouter>
                            </AlertProvider>
                        </PermissionsProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </ApolloProvider>
        </DndProvider>
    );
}

export default App;
