import ContentModule from '@acdc/shared/src/features/contentModule/ContentModule.model';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import CreateContentModuleResponse from '@acdc/shared/src/features/contentModule/CreateContentModuleResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { getSingleErrorMessage } from '@acdc/shared/src/utils/error-helpers';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import { useCallback, useState } from 'react';

export const CREATE_CONTENT_MODULE = gql`
    mutation CreateContentModule($input: createContentModuleInput!) {
        createContentModule(input: $input) {
            contentModule {
                id
            }
        }
    }
`;

function useCreateContentModule(
    onCreated: (
        contentModule: ContentModuleResponse,
        forwardedData: any
    ) => void
): [
    (contentModule: DeepPartial<ContentModule>, dataToForward?: any) => void,
    boolean
] {
    const [createContentModule] = useMutation(CREATE_CONTENT_MODULE);
    const setAlert = useAlert();
    const [isCreating, setIsCreating] = useState<boolean>(false);

    const createItem = useCallback(
        (contentModule: DeepPartial<ContentModule>, dataToForward?: any) => {
            setIsCreating(true);

            createContentModule({ variables: { input: contentModule } })
                .finally(() => {
                    setIsCreating(false);
                })
                .then((res: FetchResult<CreateContentModuleResponse>) => {
                    if (!res?.data?.createContentModule?.contentModule) {
                        setAlert(
                            'Une erreur est survenue pendant la création du module.',
                            'error'
                        );
                        return;
                    }

                    onCreated(
                        res.data.createContentModule.contentModule,
                        dataToForward
                    );
                })
                .catch((err: ApolloError) => {
                    setAlert(
                        `Une erreur est survenue lors de la création du module.
                        "${getSingleErrorMessage(err)}"`,
                        'error'
                    );
                });
        },
        [onCreated, setIsCreating, createContentModule, setAlert]
    );

    return [createItem, isCreating];
}

export default useCreateContentModule;
