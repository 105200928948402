import {
    Collapse,
    CollapseProps,
    ListItemButton,
    ListItemText,
    ListItemTextProps,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react';

export type ListItemCollapseProps = Pick<
    ListItemTextProps,
    'primary' | 'primaryTypographyProps'
> &
    Pick<CollapseProps, 'children' | 'mountOnEnter' | 'unmountOnExit'> & {
        startOpen?: boolean;
    };

function ListItemCollapse({
    children,
    primary,
    primaryTypographyProps,
    startOpen,
    mountOnEnter,
    unmountOnExit,
}: ListItemCollapseProps) {
    const [open, setOpen] = useState<boolean>(!!startOpen);

    return (
        <>
            <ListItemButton onClick={() => setOpen(!open)}>
                <ListItemText
                    primary={primary}
                    primaryTypographyProps={primaryTypographyProps}
                />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
                in={open}
                mountOnEnter={!!mountOnEnter}
                unmountOnExit={!!unmountOnExit}
            >
                {children}
            </Collapse>
        </>
    );
}

export default ListItemCollapse;
