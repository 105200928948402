import React from 'react';
import { useDrop } from 'react-dnd';
import type { DropTargetMonitor } from 'react-dnd';
import { Box, Theme } from '@mui/system';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import {
    DROPPABLE_CONTENT_FROM_CHAPTER,
    DROPPABLE_CONTENT,
} from '../DraggableContentsList/DraggableContentsListItem';
import { OnDropFn } from '../../chapterContents/ChapterContentsList/ChapterContentDropWrapper';

const containerSx = (isOver: boolean) => ({
    height: '100%',
    width: '100%',
    boxShadow: (theme: Theme) => {
        return isOver ? `0 0 0 2px ${theme.palette.primary.main}` : 'none';
    },
});

export interface ContentDropzoneProps {
    onDrop: OnDropFn;
    children: React.ReactNode;
    hasItems: boolean;
}

export interface DropProps {
    isOver: boolean;
}

function ContentDropzone({ onDrop, children, hasItems }: ContentDropzoneProps) {
    const [{ isOver }, drop] = useDrop<
        {
            content: ContentResponse;
            currentChapter: ChapterResponse | undefined;
        },
        void,
        DropProps
    >(
        () => ({
            accept: [DROPPABLE_CONTENT, DROPPABLE_CONTENT_FROM_CHAPTER],
            drop: (item: {
                content: ContentResponse;
                currentChapter: ChapterResponse | undefined;
            }) => {
                if (!hasItems) {
                    onDrop(item);
                }
            },
            collect: (monitor: DropTargetMonitor) => ({
                isOver: monitor.isOver(),
            }),
        }),
        [onDrop, hasItems]
    );

    return (
        <Box ref={drop} data-testid="contentDropzone" sx={containerSx(isOver)}>
            {children}
        </Box>
    );
}

export default ContentDropzone;
