import { useEffect } from 'react';
import {
    gql,
    LazyQueryResultTuple,
    OperationVariables,
    useLazyQuery,
} from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import GetPresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/GetPresentationFlowResponse';

export const GET_PRESENTATION_FLOW_TO_UPDATE = gql`
    query GetPresentationFlowToUpdate($id: ID!) {
        presentationFlow(id: $id) {
            id
            label
            code
            coverPage {
                id
                title
            }
        }
    }
`;

const useLazyPresentationFlowToUpdate = (
    id: string | null | undefined
): LazyQueryResultTuple<GetPresentationFlowResponse, OperationVariables> => {
    const setAlert = useAlert();
    const res = useLazyQuery<GetPresentationFlowResponse>(
        GET_PRESENTATION_FLOW_TO_UPDATE,
        {
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        }
    );
    const error = res?.[1]?.error;

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement du déroulé.',
                'error'
            );
        }
    }, [error, setAlert]);

    return res;
};

export default useLazyPresentationFlowToUpdate;
