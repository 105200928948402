import React from 'react';
import { Navigate } from 'react-router';
import useIsAuthenticated from './useIsAuthenticated';

interface RequireAnonymousProps {
    children: React.ReactNode;
}

/**
 * @see https://reactrouter.com/docs/en/v6/examples/auth
 */
function RequireAnonymous({ children }: RequireAnonymousProps) {
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return <Navigate to="/" replace />;
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}

export default RequireAnonymous;
