import ModuleResponse from '@acdc/shared/src/features/module/ModuleResponse';
import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { useDrag } from 'react-dnd';
import ModuleIcon from './ModuleIcon';

export const DROPPABLE_MODULE = 'droppable_module';

const paperSx = {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    width: '100%',
    aspectRatio: '1 / 1',
    pt: 2.5,
    pb: 1.5,
    backgroundColor: 'grey.800',
    alignItems: 'center',
    '&:hover': {
        cursor: 'grab',
    },
};

export type DraggableModuleProps = {
    module: ModuleResponse;
};

function DraggableModule({ module }: DraggableModuleProps) {
    const [, drag] = useDrag<ModuleResponse, void, unknown>(
        () => ({
            type: DROPPABLE_MODULE,
            item: module,
        }),
        [module]
    );

    return (
        <Box ref={drag} data-testid="draggableModule">
            <Paper sx={paperSx}>
                <ModuleIcon icon={module.icon || 'TextFieldsIcon'} />
                <Typography
                    variant="body2"
                    textAlign="center"
                    whiteSpace="nowrap"
                >
                    {module.label}
                </Typography>
            </Paper>
        </Box>
    );
}

export default DraggableModule;
