import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ConfigurePresentationFlow from './ConfigurePresentationFlow';
import PresentationFlows from './PresentationFlows';

function PresentationFlowsPages() {
    return (
        <Routes>
            <Route index element={<PresentationFlows />} />
            <Route path=":presentationFlowId">
                <Route
                    path="configurer"
                    element={<ConfigurePresentationFlow />}
                />
                <Route index element={<Navigate replace to="/404" />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
    );
}

export default PresentationFlowsPages;
