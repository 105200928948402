import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import ListChaptersResponse from '@acdc/shared/src/features/chapter/ListChaptersResponse';
import ChapterContent from '@acdc/shared/src/features/chapterContent/ChapterContent.model';
import ChapterContentResponse from '@acdc/shared/src/features/chapterContent/ChapterContentResponse';
import ListContentsResponse from '@acdc/shared/src/features/content/ListContentsResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useCallback } from 'react';
import SetChapters from './SetChapters';
import useMutateChapterContent from './useMutateChapterContent';

export type MoveContentFn = (
    contentId: string,
    newPosition: number,
    newChapterId: string,
    /**
     * Si défini c'est un déplacement d'un ChapterContent d'un Chapter vers un autre
     * sinon une création de ChapterContent pour ajouter un nouveau Content a un Chapter.
     */
    currentChapterId: string | undefined
) => void;

const chapterContentResponseToModel = (
    chapterContent: ChapterContentResponse,
    chapter: ChapterResponse
): DeepPartial<ChapterContent> => ({
    ...chapterContent,
    chapter: entityToId(chapter) || undefined,
    content: entityToId(chapterContent.content) || undefined,
});

/**
 * Retourne une fonction pour déplacer un Content dans un Chapter.
 * Fait le déplacement directement dans les datas locales + répercute le changement sur l'API.
 */
function useMoveContent(
    chapters: ListChaptersResponse | null | undefined,
    setChapters: SetChapters,
    allContents: ListContentsResponse | null | undefined
) {
    const setAlert = useAlert();

    const onMutated = useCallback(
        (
            chapterContent: ChapterContentResponse,
            forwardedValues: DeepPartial<ChapterContent>
        ) => {
            if (forwardedValues.id) {
                return; // c'était une modification donc osef
            }
            // sinon une création donc il faut transmettre l'id du chapterContent
            // on le retrouve par son Chapter + Content
            setChapters((curr) => ({
                ...curr,
                chapters: {
                    ...curr.chapters,
                    collection:
                        curr.chapters?.collection.map((chap) => {
                            if (!matchOnId(chap, forwardedValues.chapter)) {
                                return chap;
                            }

                            return {
                                ...chap,
                                chapterContents: {
                                    ...chap.chapterContents,
                                    collection:
                                        chap.chapterContents?.collection.map(
                                            (chapCont) => {
                                                if (
                                                    !matchOnId(
                                                        forwardedValues.content,
                                                        chapCont.content
                                                    )
                                                ) {
                                                    return chapCont;
                                                }

                                                return {
                                                    ...chapCont,
                                                    id: chapterContent.id,
                                                };
                                            }
                                        ) || [],
                                },
                            };
                        }) || [],
                },
            }));
        },
        [setChapters]
    );

    const mutateChapterContent = useMutateChapterContent(onMutated);

    return useCallback<MoveContentFn>(
        (contentId, newPosition, newChapterId, currentChapterId) => {
            let chapterContent: ChapterContentResponse | undefined;
            let currentChapter: ChapterResponse | undefined;

            if (currentChapterId) {
                // recherche du ChapterContent à déplacer
                currentChapter = chapters?.chapters?.collection.find((el) =>
                    matchOnId(el, currentChapterId)
                );
                if (!currentChapter) {
                    setAlert(
                        "Impossible de déplacer cette page car le chapitre n'existe pas.",
                        'error'
                    );
                    return;
                }
                const currentChapterContentIndex =
                    currentChapter.chapterContents?.collection.findIndex((el) =>
                        matchOnId(el.content, contentId)
                    );
                if (
                    currentChapterContentIndex === undefined ||
                    currentChapterContentIndex === -1
                ) {
                    setAlert(
                        'Impossible de déplacer cette page car elle est introuvable dans son chapitre initial.',
                        'error'
                    );
                    return;
                }

                chapterContent = {
                    ...currentChapter.chapterContents?.collection[
                        currentChapterContentIndex
                    ],
                };
            } else {
                // préparation du ChapterContent à créer
                const content = allContents?.contents?.collection.find((el) =>
                    matchOnId(el, contentId)
                );
                if (!content) {
                    setAlert(
                        'Impossible de déplacer cette page elle est introuvable.',
                        'error'
                    );
                    return;
                }

                chapterContent = {
                    id: undefined,
                    content,
                };
            }

            const newChapter = chapters?.chapters?.collection.find((chapter) =>
                matchOnId(chapter, newChapterId)
            );
            if (!newChapter) {
                setAlert(
                    "Impossible de déplacer cette page car le chapitre de destination n'existe pas.",
                    'error'
                );
                return;
            }

            if (
                chapterContent.position !== undefined &&
                matchOnId(newChapterId, currentChapterId) &&
                chapterContent.position === newPosition
            ) {
                // move au même endroit qu'avant = rien à changer.
                return;
            }

            chapterContent.position = newPosition;

            const updatedChapters: ListChaptersResponse = {
                chapters: {
                    ...chapters?.chapters,
                    collection:
                        chapters?.chapters?.collection.map((chapter) => {
                            let updatedChapter = chapter;
                            if (
                                currentChapter &&
                                matchOnId(chapter, currentChapter)
                            ) {
                                // on supprime le ChapterContent de son Chapter actuel
                                updatedChapter = {
                                    ...updatedChapter,
                                    chapterContents: {
                                        ...updatedChapter.chapterContents,
                                        collection:
                                            updatedChapter.chapterContents?.collection.filter(
                                                (el) =>
                                                    !matchOnId(
                                                        el,
                                                        chapterContent
                                                    )
                                            ) || [],
                                    },
                                };
                            }
                            if (matchOnId(chapter, newChapter)) {
                                // on ajoute le ChapterContent a son nouveau Chapter
                                updatedChapter = {
                                    ...updatedChapter,
                                    chapterContents: {
                                        ...updatedChapter.chapterContents,
                                        collection: (
                                            updatedChapter.chapterContents
                                                ?.collection || []
                                        )
                                            // on incrémente la position des items suivants
                                            // l'api fait la même chose de son coté
                                            .map((cc) => {
                                                if (
                                                    cc.position !== undefined &&
                                                    cc.position >= newPosition
                                                ) {
                                                    return {
                                                        ...cc,
                                                        position:
                                                            cc.position + 1,
                                                    };
                                                }

                                                return cc;
                                            }, [] as ChapterContentResponse[])
                                            // push le nouveau ChapterContent
                                            .concat([
                                                chapterContent,
                                            ] as ChapterContentResponse[])
                                            // réordonne par position
                                            .sort(
                                                (cc1, cc2) =>
                                                    (cc1.position || 0) -
                                                    (cc2.position || 0)
                                            ),
                                    },
                                };
                            }

                            return updatedChapter;
                        }) || [],
                },
            };

            setChapters(updatedChapters);

            mutateChapterContent(
                chapterContentResponseToModel(chapterContent, newChapter)
            );
        },
        [setChapters, chapters, allContents, setAlert, mutateChapterContent]
    );
}

export default useMoveContent;
