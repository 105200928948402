import { Chip, Stack } from '@mui/material';
import React, { useState } from 'react';
import ToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ToolCategoryResponse';
import useQueryToolCategoriesToSelect from '../../toolCategory/ToolCategorySelect/useQueryToolCategoriesToSelect';
import ToolsList from './ToolsList';

function ToolsFilter() {
    const { data: toolCategoriesResponse } = useQueryToolCategoriesToSelect();
    const toolCategories =
        toolCategoriesResponse?.toolCategories?.collection || null;

    const [filters, setFilters] = useState<ToolCategoryResponse[]>([]);

    function addFilter(filter: ToolCategoryResponse) {
        if (!filters.includes(filter)) {
            setFilters([...filters, filter]);
        }
    }

    const deleteFilter = (items: ToolCategoryResponse) => () => {
        setFilters((newFilters) =>
            newFilters.filter((filter) => filter.id !== items.id)
        );
    };

    return (
        <>
            <Stack
                direction="row"
                spacing={2}
                sx={{ marginBottom: 2, marginLeft: 2 }}
            >
                {toolCategories?.map((toolCategory) => {
                    if (filters.includes(toolCategory)) {
                        return (
                            <Chip
                                key={toolCategory.label}
                                label={toolCategory.label}
                                color="primary"
                                onDelete={deleteFilter(toolCategory)}
                            />
                        );
                    }
                    return (
                        <Chip
                            key={toolCategory.label}
                            label={toolCategory.label}
                            variant="outlined"
                            onClick={() => addFilter(toolCategory)}
                        />
                    );
                })}
            </Stack>

            <ToolsList filters={filters} />
        </>
    );
}

export default ToolsFilter;
