import { useEffect } from 'react';
import {
    gql,
    LazyQueryResultTuple,
    OperationVariables,
    useLazyQuery,
} from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import GetChapterResponse from '@acdc/shared/src/features/chapter/GetChapterResponse';

export const GET_CHAPTER_TO_UPDATE = gql`
    query GetChapterToUpdate($id: ID!) {
        chapter(id: $id) {
            id
            title
            subTitle
            chapterImage {
                id
                originalName
                publicPath
                size
            }
        }
    }
`;

const useLazyChapterToUpdate = (
    id: string | null | undefined
): LazyQueryResultTuple<GetChapterResponse, OperationVariables> => {
    const setAlert = useAlert();
    const res = useLazyQuery<GetChapterResponse>(GET_CHAPTER_TO_UPDATE, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });
    const error = res?.[1]?.error;

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement du chapitre.',
                'error'
            );
        }
    }, [error, setAlert]);

    return res;
};

export default useLazyChapterToUpdate;
