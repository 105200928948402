import PresentationFlow from '@acdc/shared/src/features/presentationFlow/PresentationFlow.model';
import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import PresentationFlowFormDialogButton from '../PresentationFlowForm/PresentationFlowFormDialogButton';

const rootSx = {
    height: '100%',
    overflow: 'hidden',
    borderRadius: 1,
    position: 'relative',
    '& .PresentationFlowsListItemActions': {
        display: 'none',
    },
    '&:hover .PresentationFlowsListItemActions': {
        display: 'flex',
    },
};

const actionsSx = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    flexFlow: 'row nowrap',
    '& > button:not(:first-of-type)': {
        marginLeft: 2,
    },
};

const actionProps = {
    sx: {
        backgroundColor: 'grey.400',
        '&:hover': {
            backgroundColor: 'grey.600',
        },
    },
};

export interface PresentationFlowsListItemProps {
    presentationFlow: PresentationFlowResponse;
}

function PresentationFlowsListItem({
    presentationFlow,
}: PresentationFlowsListItemProps) {
    const presentationFlowToUpdate = useMemo<DeepPartial<PresentationFlow>>(
        () => ({
            id: presentationFlow.id,
            // on laisse PresentationFlowForm charger les autres champs
        }),
        [presentationFlow.id]
    );
    const navigate = useNavigate();
    const handlePresentationFlowUpdated = useCallback(
        (res: PresentationFlowResponse) => {
            navigate(`/deroules/${getIdFromIri(entityToId(res))}/configurer`);
        },
        [navigate]
    );

    return (
        <Box sx={rootSx} data-testid="PresentationFlowsListItemRoot">
            <Box
                component="img"
                height="100%"
                width="100%"
                src="/blank.jpg"
                alt={presentationFlow.label}
                loading="lazy"
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    paddingX: 2.5,
                    paddingY: 1,
                    backgroundColor: (theme: Theme) =>
                        theme.palette.grey['700'],
                    borderTopRightRadius: (theme: Theme) =>
                        theme.shape.borderRadius,
                }}
            >
                <Typography variant="body1" component="span">
                    {presentationFlow.label}
                </Typography>
            </Box>
            <Box
                className="PresentationFlowsListItemActions"
                sx={actionsSx}
                data-testid="PresentationFlowsListItemActions"
            >
                <PresentationFlowFormDialogButton
                    id={`update-PresentationFlow-${getIdFromIri(
                        entityToId(presentationFlow)
                    )}`}
                    value={presentationFlowToUpdate}
                    onSuccess={handlePresentationFlowUpdated}
                    buttonComponent={IconButton}
                    buttonProps={actionProps}
                >
                    <EditIcon />
                </PresentationFlowFormDialogButton>
            </Box>
        </Box>
    );
}

export default PresentationFlowsListItem;
