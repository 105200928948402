import { useCallback, useState } from 'react';
import { RowsPerPage } from './TablePagination';
import { OrderDirection } from './TableHeaders';

export interface BaseTableParams {
    orderBy: string;
    orderDirection: OrderDirection;
    page: number;
    itemsPerPage: RowsPerPage;
}

/**
 * Hook pour aider à gérer les callbacks à passer aux composants d'une table pour modifier les filtres de la requête qui charge les données.
 */
function useTableFilters<S extends BaseTableParams>(initialFilters: S) {
    const [filters, setFilters] = useState<S>(initialFilters);

    const toVariables = useCallback(
        ({ orderBy, orderDirection, ...otherFilters }: S) => {
            return {
                ...otherFilters,
                order: [{ [orderBy]: orderDirection }],
            };
        },
        []
    );

    const updateFilters = useCallback(
        (newFilters: Partial<S>) => {
            setFilters((curr) => ({
                ...curr,
                ...newFilters,
            }));
        },
        [setFilters]
    );

    const setOrder = useCallback(
        (orderBy: string, orderDirection: OrderDirection) => {
            setFilters((curr) => ({
                ...curr,
                orderBy,
                orderDirection,
            }));
        },
        [setFilters]
    );

    const setPagination = useCallback(
        (page: number, itemsPerPage: RowsPerPage) => {
            setFilters((curr) => ({
                ...curr,
                page,
                itemsPerPage,
            }));
        },
        [setFilters]
    );

    return {
        filters,
        setFilters,
        updateFilters,
        setOrder,
        setPagination,
        toVariables,
    };
}

export default useTableFilters;
