enum FieldType {
    WYSIWYG = 'wysiwyg',
    IMAGE = 'image',
    TEXT = 'text',
    SELECT = 'select',
    SWITCH = 'switch',
    FILE = 'file',
    SHARED_IMAGE_PICKER = 'shared_image_picker',
}

export default FieldType;
