import React from 'react';
import { Box, Theme } from '@mui/material';
import ChapterContentResponse from '@acdc/shared/src/features/chapterContent/ChapterContentResponse';
import CollectionConnection from '@acdc/shared/src/graphql/CollectionConnection';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import DraggableContentsListItem from '../../content/DraggableContentsList/DraggableContentsListItem';
import ChapterContentDropWrapper, {
    OnDropFn,
} from './ChapterContentDropWrapper';
import DragAndDropList from '../../../ui/DragAndDropList/DragAndDropList';

export interface ChapterContentsListProps {
    chapter: ChapterResponse;
    chapterContents: CollectionConnection<ChapterContentResponse>;
    onRemoveChapterContent?: (chapterContentId: string) => void;
    onDrop: OnDropFn;
}

function ChapterContentsList({
    chapter,
    chapterContents,
    onRemoveChapterContent,
    onDrop,
}: ChapterContentsListProps) {
    return (
        <DragAndDropList>
            {chapterContents.collection.map(
                (chapterContent: ChapterContentResponse) => (
                    <Box
                        key={`${entityToId(chapterContent.chapter)}${entityToId(
                            chapterContent.content
                        )}`}
                        sx={{
                            margin: (theme: Theme) => theme.spacing(0.5),
                        }}
                    >
                        {chapterContent.content && (
                            <ChapterContentDropWrapper
                                chapterContent={chapterContent}
                                onDrop={onDrop}
                            >
                                <DraggableContentsListItem
                                    chapter={chapter}
                                    content={chapterContent?.content}
                                    enableDeleteButton={Boolean(
                                        chapterContent.id
                                    )}
                                    onRemove={() => {
                                        if (!chapterContent.id) {
                                            return;
                                        }
                                        onRemoveChapterContent &&
                                            onRemoveChapterContent(
                                                chapterContent.id
                                            );
                                    }}
                                    disableDrag={!chapterContent.id}
                                />
                            </ChapterContentDropWrapper>
                        )}
                    </Box>
                )
            )}
            {chapterContents.collection.length !== 0 && (
                <ChapterContentDropWrapper onDrop={onDrop} isHidden>
                    <Box
                        sx={{
                            minHeight: '38px',
                            height: '100%',
                        }}
                    />
                </ChapterContentDropWrapper>
            )}
        </DragAndDropList>
    );
}

export default ChapterContentsList;
