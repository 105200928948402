import ChapterContentResponse from '@acdc/shared/src/features/chapterContent/ChapterContentResponse';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { useMutation, gql } from '@apollo/client';
import { useCallback } from 'react';

export const DELETE_CHAPTER_CONTENT = gql`
    mutation DeleteChapterContent($id: ID!) {
        deleteChapterContent(input: { id: $id }) {
            clientMutationId
        }
    }
`;

/**
 * Retourne une fonction pour supprimer un ChapterContent de l'API
 */
function useDeleteChapterContent(
    onDeleted?: (chapterContentId: string) => void
): (chapterContent: string | ChapterContentResponse) => void {
    const [deleteChapterContent] = useMutation(DELETE_CHAPTER_CONTENT);
    const setAlert = useAlert();

    const deleteItem = useCallback(
        (chapterContent: string | ContentModuleResponse) => {
            const id = entityToId(chapterContent);

            if (!id) {
                return;
            }

            deleteChapterContent({ variables: { id } })
                .then(() => {
                    onDeleted && onDeleted(id);
                })
                .catch(() => {
                    setAlert(
                        "Une erreur est survenue lors de la suppression d'une page d'un chapitre.",
                        'error'
                    );
                });
        },
        [onDeleted, deleteChapterContent, setAlert]
    );

    return deleteItem;
}

export default useDeleteChapterContent;
