import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { buttonSx } from './TemplateSelect';

const getSkeletons = (nb: number) => {
    const skeletons: React.ReactNode[] = [];

    for (let i = 0; i < nb; i += 1) {
        skeletons.push(
            <Skeleton
                key={i}
                variant="rectangular"
                width={buttonSx.width}
                height={buttonSx.height}
                data-testid={`templateSelectSkeletonItem${i}`}
            />
        );
    }

    return skeletons;
};

const containerSx = {
    display: 'flex',
    flexFlow: 'row wrap',
    '& .MuiSkeleton-root:not(:last-of-type)': {
        marginRight: 1,
    },
};

function TemplateSelectSkeleton() {
    return <Box sx={containerSx}>{getSkeletons(5)}</Box>;
}

export default TemplateSelectSkeleton;
