import React from 'react';
import { Theme, SxProps, Box } from '@mui/system';
import { TableCell } from '@mui/material';

interface TableCellActionsProps {
    children: React.ReactNode;
    sx?: SxProps<Theme>;
}

/**
 * Wrapper pour gérer l'affichage des boutons d'action d'une row d'une table.
 */
function TableCellActions({ children, sx }: TableCellActionsProps) {
    return (
        <TableCell padding="checkbox">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    '& *': {
                        ml: 1,
                    },
                    '& *:first-of-type': {
                        ml: 0,
                    },
                    ...sx,
                }}
            >
                {children}
            </Box>
        </TableCell>
    );
}

export default TableCellActions;
