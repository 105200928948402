import React, { useMemo, useRef } from 'react';
import { ErrorMessage, Field, FieldAttributes, useFormikContext } from 'formik';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import FieldType from '@acdc/shared/src/features/moduleSetting/FieldType.enum';
import { TextField, Switch } from 'formik-mui';
import useAutoSubmitSingleFieldForm from '@acdc/shared/src/utils/useAutoSubmitSingleFieldForm';
import ModuleSettingResponse from '@acdc/shared/src/features/moduleSetting/ModuleSettingResponse';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import { Box, MenuItem, FormLabel } from '@mui/material';
import Dropzone, { ApiFile } from '@acdc/shared/src/ui/Dropzone';
import {
    acceptImage,
    acceptPdf,
    megaBytesToBytes,
} from '@acdc/shared/src/utils/file-helpers';
import SettingValueResponse from '@acdc/shared/src/features/settingValue/SettingValueResponse';
import SavingStatus from '@acdc/shared/src/ui/SavingStatus';
import ModuleCode from '@acdc/shared/src/features/module/ModuleCode.enum';
import ModuleResponse from '@acdc/shared/src/features/module/ModuleResponse';
import FormikWysiwyg from '../../../../ui/FormikWysiwyg';
import type { SettingValueFormValue } from './SettingValueForm';
import CONFIGS from '../../../../configs';
import isFileField from './isFileField';

const savingStatusSx = {
    opacity: 0.9,
    position: 'absolute',
    // 18px = hauteur du footer de tinymce
    bottom: (theme: any) => `calc(18px + ${theme.spacing(2)})`,
    right: (theme: any) => theme.spacing(2),
    zIndex: 1,
};

export const getFieldComponent = (moduleSetting: ModuleSettingResponse) => {
    switch (moduleSetting.fieldType) {
        case FieldType.WYSIWYG:
            return FormikWysiwyg;
        case FieldType.SWITCH:
            return Switch;
        // case FieldType.SELECT:
        //      les selects sont des <TextField select />. Pas de <Select /> pour que le style soient consistent
        default:
            return TextField;
    }
};

const imageMaxSize = megaBytesToBytes(5);

const getInputId = (moduleSetting: ModuleSettingResponse) =>
    `value_${entityToId(moduleSetting)}`;

export const getSettingInputProps = (
    moduleSetting: ModuleSettingResponse,
    valid: boolean
): FieldAttributes<any> => {
    const inputId = getInputId(moduleSetting);
    const props: FieldAttributes<any> = {
        component: getFieldComponent(moduleSetting),
        id: inputId,
        label: moduleSetting.label || '',
        name: 'value',
        required: false,
        disabled: false, // il ne faut pas bloquer le champ pendant une sauvegarde
        color: valid ? 'success' : 'primary',
        ...(([FieldType.TEXT, FieldType.SELECT] as string[]).includes(
            `${moduleSetting.fieldType}`
        ) && {
            focused: valid ? true : undefined, // pour afficher la couleur
            InputProps: {
                'data-testid': inputId,
            },
        }),
        ...(([FieldType.SELECT] as string[]).includes(
            `${moduleSetting.fieldType}`
        ) && {
            select: true,
            children: moduleSetting.options?.collection.map((option) => (
                <MenuItem key={option.id} value={option.value}>
                    {option.label}
                </MenuItem>
            )),
        }),
        ...(FieldType.SWITCH === moduleSetting.fieldType && {
            type: 'checkbox',
            inputprops: {
                'data-testid': inputId,
            },
        }),
    };

    return props;
};

export interface SettingValueFormInnerProps {
    moduleSetting: ModuleSettingResponse;
    getSettingValueId: () => string | null;
    saved: boolean;
    initialValue: SettingValueResponse | undefined;
    module: ModuleResponse;
}

function SettingValueFormInner({
    moduleSetting,
    getSettingValueId,
    saved,
    initialValue,
    module,
}: SettingValueFormInnerProps) {
    const fieldName = isFileField(moduleSetting.fieldType) ? 'file' : 'value';

    const pendingSave = useAutoSubmitSingleFieldForm(
        fieldName,
        getSettingValueId
    );
    const isValid: boolean = Boolean(saved && !pendingSave);
    const { isSubmitting, values, handleChange } =
        useFormikContext<SettingValueFormValue>();

    // les fichiers déjà présents sur l'API au départ
    const initialApiFiles = useMemo<ApiFile[] | undefined>(() => {
        if (initialValue?.file) {
            const apiUrl = CONFIGS.apiEntrypoint;
            const filePath = initialValue?.file.publicPath;
            return [
                {
                    ...initialValue?.file,
                    publicUrl: `${apiUrl}${filePath}`,
                },
            ];
        }

        return undefined;
    }, [initialValue?.file]);

    // comme on submit quand on vide le champ, il ne faut pas montrer les fichiers initiaux qui ne sont plus utilisés.
    const shouldShowInitialApiFilesRef = useRef<boolean>(true);
    if (shouldShowInitialApiFilesRef.current === true && isSubmitting) {
        // si on a submit une fois on arrete d'afficher les fichiers initiaux
        // si on vide le champ
        shouldShowInitialApiFilesRef.current = false;
    }

    return (
        <Box position="relative">
            <Box
                // on évite que le wysiwyg ai des paddings
                px={moduleSetting.fieldType === FieldType.WYSIWYG ? 0 : 1}
                mt={moduleSetting.fieldType === FieldType.WYSIWYG ? -1 : 0}
                sx={{
                    ...(FieldType.SWITCH === moduleSetting.fieldType && {
                        display: 'inline-flex',
                    }),
                }}
            >
                {isFileField(moduleSetting.fieldType) && (
                    <Dropzone
                        id={getInputId(moduleSetting)}
                        name="file"
                        value={values.file}
                        onChange={handleChange}
                        label={`${moduleSetting.label}`}
                        accept={
                            module.code === ModuleCode.IMAGE
                                ? acceptImage
                                : acceptPdf
                        }
                        multiple={false}
                        maxSize={imageMaxSize}
                        initialApiFiles={
                            shouldShowInitialApiFilesRef.current
                                ? initialApiFiles
                                : undefined
                        }
                    />
                )}

                {!isFileField(moduleSetting.fieldType) &&
                    moduleSetting.fieldType !==
                        FieldType.SHARED_IMAGE_PICKER && (
                        <>
                            {FieldType.SWITCH === moduleSetting.fieldType && (
                                <FormLabel
                                    htmlFor={getInputId(moduleSetting)}
                                    sx={{ marginX: 1, marginTop: '6px' }}
                                >
                                    {moduleSetting.label}
                                </FormLabel>
                            )}
                            <Field
                                sx={{
                                    marginBottom: 3,
                                    ...(FieldType.SWITCH ===
                                        moduleSetting.fieldType && {
                                        marginBottom: 2,
                                    }),
                                }}
                                {...getSettingInputProps(
                                    moduleSetting,
                                    isValid
                                )}
                            />
                        </>
                    )}
            </Box>

            {([FieldType.WYSIWYG, FieldType.IMAGE] as string[]).includes(
                `${moduleSetting.fieldType}`
            ) && (
                <SavingStatus
                    saved={saved}
                    pendingSave={pendingSave}
                    isSubmitting={isSubmitting}
                    sx={savingStatusSx}
                />
            )}

            <ErrorMessage name="value" component={ErrorHelperText} />
            <ErrorMessage name="file" component={ErrorHelperText} />
        </Box>
    );
}

export default SettingValueFormInner;
