import { Grid, Skeleton } from '@mui/material';
import React from 'react';

const skeletonSx = {
    width: '100%',
    aspectRatio: '1 / 1',
    height: 'auto',
    borderRadius: 1,
};

function DraggableModulesListSkeleton() {
    return (
        <div role="presentation">
            <Skeleton variant="rectangular" height={52} />
            <Grid container>
                <Grid item xs={12} md={4} p={1}>
                    <Skeleton variant="rectangular" sx={skeletonSx} />
                </Grid>
                <Grid item xs={12} md={4} p={1}>
                    <Skeleton variant="rectangular" sx={skeletonSx} />
                </Grid>
            </Grid>
        </div>
    );
}

export default DraggableModulesListSkeleton;
