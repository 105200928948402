// see https://stackoverflow.com/a/9229821
function arrayUniq(a: string[]): string[] {
    const seen: { [key: string]: 1 } = {};
    const out: string[] = [];
    const len = a.length;
    let j = 0;

    for (let i = 0; i < len; i += 1) {
        const item = a[i];
        if (seen[item] !== 1) {
            seen[item] = 1;
            out[j] = item;
            j += 1;
        }
    }

    return out;
}

export default arrayUniq;
