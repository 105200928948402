import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import UserDataStorageKeys from '../../security/UserDataStorageKeys';
import { usePersistentState } from '../../tools/pesistant-state';
import UserResponse from '../user/UserResponse';
import { setAuthTokens } from '../../apollo/auth-tokens';
import LocalStorageKeys from '../../apollo/LocalStorageKeys';

function useOnLoginSuccess(
    authStorageKeys: LocalStorageKeys,
    userDataStorageKeys: UserDataStorageKeys,
    isAllowed?: ((user: UserResponse) => boolean) | undefined
) {
    const navigate = useNavigate();
    const location = useLocation();
    const from: string | null = (location.state as any)?.from?.pathname || null;
    const apollo = useApolloClient();

    const [, setCurrentUser] = usePersistentState(
        userDataStorageKeys.currentUser
    );

    return useCallback(
        (user: UserResponse) => {
            if (!user.token || !user.refreshToken || !user.roles) {
                throw Error("Données manquantes dans l'utilisateur connecté.");
            }

            if (isAllowed && !isAllowed(user)) {
                return;
            }

            setAuthTokens(
                authStorageKeys,
                user.token,
                user.refreshToken,
                user.roles
            );

            setCurrentUser(user);

            Sentry.setUser({
                userId: user.id,
                userCode: user.code,
                userRoles: user.roles,
            });

            apollo.resetStore().then(() => {
                if (from) {
                    // si on a été redirigé sur la connexion après une déconnexion, on ne laisse pas d'entré dans l'historique
                    navigate(from, { replace: true });
                } else {
                    navigate('/');
                }
            });
        },
        [navigate, from, isAllowed, authStorageKeys, setCurrentUser, apollo]
    );
}

export default useOnLoginSuccess;
