import React from 'react';
import { Skeleton, TableRow, TableCell } from '@mui/material';

interface TableRowsSkeletonProps {
    cols: number;
    rows?: number;
}

function TableRowsSkeleton({ cols, rows = 2 }: TableRowsSkeletonProps) {
    const getCells = () => {
        const cells = [];

        for (let cellId = 0; cellId < cols; cellId += 1) {
            cells.push(
                <TableCell key={cellId}>
                    <Skeleton width={`${Math.round(Math.random() * 100)}%`} />
                </TableCell>
            );
        }

        return cells;
    };

    const getRows = () => {
        const rowItems = [];

        for (let rowId = 0; rowId < rows; rowId += 1) {
            rowItems.push(
                <TableRow key={rowId} role="presentation">
                    {getCells()}
                </TableRow>
            );
        }

        return rowItems;
    };

    return <>{getRows()}</>;
}

export default TableRowsSkeleton;
