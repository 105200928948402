import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { Box } from '@mui/material';
import React from 'react';
import { useDrop } from 'react-dnd';
import type { DropTargetMonitor } from 'react-dnd';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import chapterContentResponse from '@acdc/shared/src/features/chapterContent/ChapterContentResponse';

export const DROPPABLE_CONTENT = 'droppable_content';
export const DROPPABLE_CONTENT_FROM_CHAPTER = 'droppable_content_from_chapter';

const rootSx = (isOver?: boolean, isHidden?: boolean) => {
    return {
        height: isHidden ? '100%' : ' ',
        '&:before': isOver
            ? {
                  content: '""',
                  display: 'block',
                  height: '38px',
              }
            : {},
    };
};

export interface ChapterContentDropWrapperProps {
    chapterContent?: chapterContentResponse;
    onDrop: OnDropFn;
    isHidden?: boolean;
    children: React.ReactNode;
}

export interface DragProps {
    isDragging: boolean;
}

export interface DropProps {
    isOver: boolean;
}

export type OnDropFn = (
    drop: {
        content: ContentResponse;
        currentChapter: ChapterResponse | undefined;
    },
    newPosition?: number | undefined
) => void;

function ChapterContentDropWrapper({
    chapterContent,
    onDrop,
    children,
    isHidden,
}: ChapterContentDropWrapperProps) {
    const [{ isOver }, drop] = useDrop<
        {
            content: ContentResponse;
            currentChapter: ChapterResponse | undefined;
        },
        void,
        DropProps
    >(
        () => ({
            accept: [DROPPABLE_CONTENT, DROPPABLE_CONTENT_FROM_CHAPTER],
            drop: (item: {
                content: ContentResponse;
                currentChapter: ChapterResponse | undefined;
            }) => {
                if (chapterContent) {
                    onDrop(item, chapterContent.position);
                } else {
                    onDrop(item);
                }
            },
            collect: (monitor: DropTargetMonitor) => ({
                isOver: monitor.isOver(),
            }),
        }),
        [onDrop, chapterContent]
    );

    return (
        <Box
            sx={rootSx(isOver, isHidden)}
            ref={drop}
            data-testid="ChapterContentDropWrapper"
        >
            {children}
        </Box>
    );
}

export default ChapterContentDropWrapper;
