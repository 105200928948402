import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { useMutation, gql } from '@apollo/client';
import { useCallback, useState } from 'react';

export const DELETE_TOOL = gql`
    mutation DeleteTool($id: ID!) {
        deleteTool(input: { id: $id }) {
            clientMutationId
        }
    }
`;

function useDeleteTool(
    onDeleted?: (toolId: string) => void
): [(tool: string | ToolResponse) => void, boolean] {
    const [deleteTool] = useMutation(DELETE_TOOL, {
        update(cache) {
            cache.evict({ fieldName: 'tools' });
        },
    });
    const setAlert = useAlert();
    const [isDeleting, setIsDeleteting] = useState<boolean>(false);

    const deleteItem = useCallback(
        (tool: string | ToolResponse) => {
            const id = entityToId(tool);

            if (!id) {
                return;
            }

            setIsDeleteting(true);

            deleteTool({ variables: { id } })
                .finally(() => {
                    setIsDeleteting(false);
                })
                .then(() => {
                    onDeleted && onDeleted(id);
                })
                .catch(() => {
                    setAlert(
                        "Une erreur est survenue lors de la suppression de l'outils.",
                        'error'
                    );
                });
        },
        [onDeleted, setIsDeleteting, deleteTool, setAlert]
    );

    return [deleteItem, isDeleting];
}

export default useDeleteTool;
