import React from 'react';
import BaseDialog, {
    DialogProps as SharedDialogProps,
} from '@acdc/shared/src/ui/Dialog';
import ThemeProvider from '../mui/ThemeProvider';

export type DialogProps = SharedDialogProps;

/**
 * La dialog définie dans @acdc/shared/src/ui/Dialog avec un thème light
 */
function Dialog(props: DialogProps) {
    return (
        <ThemeProvider variant="light">
            <BaseDialog {...props} />
        </ThemeProvider>
    );
}

export default Dialog;
