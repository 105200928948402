import React, { useState, useCallback } from 'react';
import { Button } from '@mui/material';
import Dialog, { DialogProps } from './Dialog';

// Ne pas réutiliser DialogButton avec son interface actuelle. Il faut pouvoir séparer les Dialog et le bouton dans 2 composants différents
// hors là les props de la dialog (mainTitle par exemple) sont requise dans le bouton alors qu'on pourrait les définir au niveau de la dialog
// et il faut pouvoir accépter une Dialog qui demande plus de props (cf customProps de DialogProps)

type SoftDialogProps = Omit<
    DialogProps,
    'open' | 'onClose' | 'children' | 'TransitionProps'
> & {
    /**
     * Appellé à la fermeture de la dialog une fois qu'elle est fermée
     */
    onClosed?: () => void;
};

export interface DialogButtonProps {
    children: (close: () => void) => React.ReactNode;
    /**
     * le texte du bouton
     */
    label: React.ReactNode;
    dialogComponent?: React.ComponentType<DialogProps>;
    dialogProps: SoftDialogProps;
    buttonComponent?: React.ComponentType<{
        onClick: () => void;
        disabled?: boolean | undefined;
        children?: React.ReactNode;
    }>;
    buttonProps?: any;
    /**
     * Si true la dialog ne peut plus être fermée
     */
    keepOpen?: boolean;
    onClose?: (() => void) | undefined;
    validateBeforeOpen?: (() => boolean) | undefined;
}

function DialogButton({
    children,
    buttonComponent,
    buttonProps,
    dialogComponent,
    dialogProps: { onClosed, ...dialogProps },
    label,
    keepOpen,
    onClose,
    validateBeforeOpen,
}: DialogButtonProps) {
    const DialogComponent: React.ComponentType<DialogProps> =
        dialogComponent || Dialog;
    const ButtonComponent: React.ComponentType<{ onClick: () => void }> =
        buttonComponent || Button;
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = useCallback(() => {
        if (!keepOpen) {
            setOpen(false);
        }
        onClose && onClose();
    }, [setOpen, keepOpen, onClose]);

    return (
        <>
            <ButtonComponent
                {...buttonProps}
                onClick={() => {
                    if (!validateBeforeOpen || validateBeforeOpen()) {
                        setOpen(true);
                    }
                }}
            >
                {label}
            </ButtonComponent>
            <DialogComponent
                {...dialogProps}
                open={open}
                onClose={handleClose}
                TransitionProps={{
                    onExited: () => {
                        onClosed && onClosed();
                    },
                }}
            >
                {children(handleClose)}
            </DialogComponent>
        </>
    );
}

export default DialogButton;
