import ToolContentResponse from '@acdc/shared/src/features/toolContent/ToolContentResponse';
import { useCallback, useEffect, useRef, useState } from 'react';
import useMoveToolContent, { MoveToolContentFn } from './useMoveToolContent';
import useRemoveToolContent, {
    RemoveToolContentFn,
} from './useRemoveToolContent';
import useToolContents from './useToolContents';

export interface ToolConfiguratorResult {
    /**
     * La liste des ToolContents du Tool.
     */
    toolContents: ToolContentResponse[] | null;
    /**
     * Change la position d'un ToolContent
     */
    moveToolContent: MoveToolContentFn;
    /**
     * Supprime un ChapterContent d'un Chapter.
     */
    removeToolContent: RemoveToolContentFn;
}

function useToolConfigurator(toolId: string): ToolConfiguratorResult {
    const toolContentsResponse = useToolContents(toolId);
    const [toolContents, setToolContents] = useState<ToolContentResponse[]>();

    // initialisation du state quand on reçoit la réponse de useToolContents
    const initiated = useRef<boolean>(false);
    if (!initiated.current && toolContents !== undefined) {
        initiated.current = true;
    }
    useEffect(() => {
        if (!initiated.current && toolContentsResponse) {
            setToolContents(toolContentsResponse);
        }
    }, [toolContentsResponse]);

    const doMoveToolContent = useMoveToolContent(setToolContents);
    const moveToolContent = useCallback<MoveToolContentFn>(
        (...params) => {
            if (!initiated.current) {
                return;
            }
            doMoveToolContent(...params);
        },
        [doMoveToolContent]
    );

    const doRemoveToolContent = useRemoveToolContent(setToolContents);
    const removeToolContent = useCallback<RemoveToolContentFn>(
        (...params) => {
            if (!initiated.current) {
                return;
            }
            doRemoveToolContent(...params);
        },
        [doRemoveToolContent]
    );

    return {
        toolContents: toolContents || null,
        moveToolContent,
        removeToolContent,
    };
}

export default useToolConfigurator;
