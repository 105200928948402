import React from 'react';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { TextField } from 'formik-mui';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import { Box, FormLabel, IconButton, MenuItem, Stack } from '@mui/material';
import VariableType from '@acdc/shared/src/features/variable/VariableType.enum';
import BigSwitch from '@acdc/shared/src/formik/BigSwitch';
import SubmitButton from '@acdc/shared/src/formik/SubmitButton';
import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import DeleteIcon from '@mui/icons-material/Delete';
import VariableValueInput from '@acdc/shared/src/features/variableValue/VariableValueInput';
import PropertyFieldEnum from '@acdc/shared/src/features/property/PropertyField.enum';
import AgencyFieldEnum from '@acdc/shared/src/features/agency/AgencyField.enum';
import UserFieldEnum from '@acdc/shared/src/features/user/UserField.enum';
import PropertyFieldLabelEnum from '@acdc/shared/src/features/property/PropertyFieldLabel.enum';
import { capitalizeFirstLetter } from '@acdc/shared/src/utils/display-helpers';
import AgencyFieldLabelEnum from '@acdc/shared/src/features/agency/AgencyFieldLabel.enum';
import UserFieldLabelEnum from '@acdc/shared/src/features/user/UserFieldLabel.enum';
import type { VariableFormValue } from './VariableForm';
import DeleteVariableButton, {
    DeleteVariableButtonProps,
} from '../../../../variable/DeleteVariableButton';

const typeToFieldsEnum = {
    [VariableType.PROPERTY_FIELD]: Object.values(PropertyFieldEnum),
    [VariableType.AGENCY_FIELD]: Object.values(AgencyFieldEnum),
    [VariableType.USER_FIELD]: Object.values(UserFieldEnum),
};

const hasPropertyField = (type: string) =>
    Boolean((typeToFieldsEnum as any)[type]);

const getPropertyFieldOptions = (type: string): string[] =>
    (typeToFieldsEnum as any)[type] || [];

const getPropertyFieldOptionLabel = (propertyField: string, type: string) => {
    switch (type) {
        case VariableType.PROPERTY_FIELD:
            return (PropertyFieldLabelEnum as any)[propertyField] as
                | string
                | undefined;
        case VariableType.AGENCY_FIELD:
            return (AgencyFieldLabelEnum as any)[propertyField] as
                | string
                | undefined;
        case VariableType.USER_FIELD:
            return (UserFieldLabelEnum as any)[propertyField] as
                | string
                | undefined;
        default:
            return undefined;
    }
};
const getPropertyFieldLabel = (type: string) => {
    return capitalizeFirstLetter(type);
};

const hasDefaultValueInput = (type: string) =>
    (
        [VariableType.EMAIL, VariableType.NUMBER, VariableType.TEXT] as string[]
    ).includes(type);

export interface VariableFormInnerProps {
    getVariableId?: () => string | null;
    initialValue?: VariableResponse | undefined;
    onDeleted?: DeleteVariableButtonProps['onDeleted'];
    enableDeleteButton?: boolean | undefined;
}

function VariableFormInner({
    getVariableId,
    initialValue,
    onDeleted,
    enableDeleteButton,
}: VariableFormInnerProps) {
    const { values } = useFormikContext<VariableFormValue>();
    const variableId = getVariableId && getVariableId();

    return (
        <Stack direction="column" spacing={2}>
            <Field
                component={TextField}
                id="variableLabel"
                label="Libellé"
                name="label"
                required
                InputProps={{
                    'data-testid': 'variableLabel',
                }}
            />
            <Field
                component={TextField}
                id="variableType"
                label="Type"
                name="type"
                required
                InputProps={{
                    'data-testid': 'variableType',
                }}
                select
            >
                {Object.values(VariableType).map((type) => (
                    <MenuItem
                        key={type}
                        value={type}
                        data-testid="variableTypeOption"
                    >
                        {type}
                    </MenuItem>
                ))}
            </Field>
            {values.type === VariableType.TEXT && (
                <>
                    <Box display="flex">
                        <FormLabel
                            htmlFor="variableIsTextMulti"
                            required
                            sx={{ display: 'block', mr: 1 }}
                        >
                            Multi-ligne
                        </FormLabel>
                        <Field
                            component={BigSwitch}
                            inputProps={{
                                'data-testid': 'variableIsTextMulti',
                            }}
                            id="variableIsTextMulti"
                            name="isTextMulti"
                            type="checkbox"
                            checkedLabel="Multi-ligne"
                            uncheckedLabel="Une ligne"
                        />
                        <ErrorMessage
                            name="isTextMulti"
                            component={ErrorHelperText}
                        />
                    </Box>
                    {!values.isTextMulti && (
                        <Field
                            component={TextField}
                            id="variableMaxChar"
                            label="Nombre maximum de caractères"
                            name="maxChar"
                            InputProps={{
                                'data-testid': 'variableMaxChar',
                            }}
                            type="number"
                        />
                    )}
                    {values.isTextMulti && (
                        <Field
                            component={TextField}
                            id="variableMaxLine"
                            label="Nombre maximum de lignes"
                            name="maxLine"
                            InputProps={{
                                'data-testid': 'variableMaxLine',
                            }}
                            type="number"
                        />
                    )}
                </>
            )}
            {hasPropertyField(values.type) && (
                <Field
                    component={TextField}
                    id="variablePropertyField"
                    label={getPropertyFieldLabel(values.type)}
                    name="propertyField"
                    required
                    InputProps={{
                        'data-testid': 'variablePropertyField',
                    }}
                    select
                >
                    {getPropertyFieldOptions(values.type).map(
                        (propertyField) => (
                            <MenuItem
                                key={propertyField}
                                value={propertyField}
                                data-testid="variablePropertyFieldOption"
                            >
                                {getPropertyFieldOptionLabel(
                                    propertyField,
                                    values.type
                                ) || propertyField}
                            </MenuItem>
                        )
                    )}
                </Field>
            )}
            {hasDefaultValueInput(values.type) && (
                <VariableValueInput
                    variable={values}
                    name="defaultValue"
                    id="variableDefaultValue"
                    label="Valeur par défaut"
                />
            )}
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                {variableId && enableDeleteButton && (
                    <DeleteVariableButton
                        variable={variableId}
                        code={initialValue?.code}
                        onDeleted={onDeleted}
                        buttonComponent={IconButton}
                        title="Supprimer"
                    >
                        <DeleteIcon />
                    </DeleteVariableButton>
                )}
                <SubmitButton>Sauvegarder</SubmitButton>
            </Stack>
        </Stack>
    );
}

export default VariableFormInner;
