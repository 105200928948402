import React, { useCallback } from 'react';
import UserResponse from '@acdc/shared/src/features/user/UserResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import MultiAuth from '@acdc/shared/src/features/auth/MultiAuth';
import CONFIGS from '../../configs';
import isAllowedToLoginToBO from './isAllowedToLoginToBO';

function Connexion() {
    const {
        enableCasAuthentication,
        casEntrypoint,
        boEntrypoint,
        authStorageKeys,
        userDataStorageKeys,
    } = CONFIGS;

    const setAlert = useAlert();
    const isAllowed = useCallback(
        (user: UserResponse) => {
            if (isAllowedToLoginToBO(user)) {
                return true;
            }
            setAlert('Vous devez avoir le rôle administrateur siège', 'error');
            return false;
        },
        [setAlert]
    );

    return (
        <MultiAuth
            validationUrl={`${boEntrypoint}/auth/validation`}
            casEntrypoint={casEntrypoint}
            authStorageKeys={authStorageKeys}
            userDataStorageKeys={userDataStorageKeys}
            enableCasAuthentication={enableCasAuthentication === 'true'}
            isAllowed={isAllowed}
        />
    );
}
export default Connexion;
