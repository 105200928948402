import React from 'react';
import { Box } from '@mui/system';
import { AppBar, Toolbar, Button, Typography, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { history } from '@acdc/shared/src/router/BrowserRouter';
import MenuIcon from '@mui/icons-material/Menu';

interface HeaderProps {
    toggleDrawer: () => void;
}

function Header({ toggleDrawer }: HeaderProps) {
    return (
        <AppBar
            id="header"
            sx={{
                position: 'fixed',
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                }}
                variant="dense"
            >
                <Box sx={{ flex: 1 }} />
                <Typography
                    variant="h3"
                    component={Link}
                    to="/"
                    sx={{
                        textDecoration: 'none',
                        fontStyle: 'italic',
                        '&:link': { color: 'inherit' },
                        '&:visited': { color: 'inherit' },
                        '&:hover': { color: 'inherit' },
                        '&:active': { color: 'inherit' },
                    }}
                >
                    Mon appli conseiller
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        data-testid="backButton"
                        sx={{
                            fontSize: '0.7rem',
                            mr: 2,
                        }}
                        onClick={() => history.back()}
                        variant="contained"
                    >
                        Retour
                    </Button>
                    <IconButton
                        size="large"
                        onClick={toggleDrawer}
                        data-testid="MenuSideBarButton"
                        sx={{
                            marginLeft: '2rem',
                            paddingTop: '0',
                            paddingBottom: '0',
                        }}
                    >
                        <MenuIcon color="primary" fontSize="large" />
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
