import { Grid, Typography } from '@mui/material';
import React from 'react';
import DownloadPresentationStatisticsForm from '../../features/presentation-statistic/DownloadPresentationStatisticsForm';

function DownloadPresentationStatistics() {
    return (
        <>
            <Typography variant="h1" component="h1" textAlign="center">
                Extraire les statistiques
            </Typography>
            <Grid container justifyContent="center" px={2}>
                <Grid item xs={12} md={8}>
                    <DownloadPresentationStatisticsForm />
                </Grid>
            </Grid>
        </>
    );
}

export default DownloadPresentationStatistics;
