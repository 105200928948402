import Tool from '@acdc/shared/src/features/tool/Tool.model';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import { ApiFile } from '@acdc/shared/src/ui/Dropzone';
import { initBool, initString } from '@acdc/shared/src/utils/form-helpers';
import { useEffect, useMemo } from 'react';
import CONFIGS from '../../../configs';
import type { ToolFormValue } from './ToolForm';
import useLazyToolToUpdate from './useLazyToolToUpdate';

const initValue = (value: DeepPartial<Tool>): ToolFormValue => ({
    title: initString(value?.title),
    toolCategory: value.toolCategory || null,
    required: initBool(value?.required, true),
    canUpdateTitle: initBool(value?.canUpdateTitle, false),
    toolThumbnail: [],
});

const useInitialValues = (
    value: DeepPartial<Tool> | undefined
): {
    initialValues: ToolFormValue | null;
    initialToolThumbnails: ApiFile[] | undefined;
    tool: ToolResponse | undefined;
} => {
    const isUpdate: boolean = !!value?.id;

    // chargement de la valeur de l'api si besoin
    const [query, { data: toolResponse }] = useLazyToolToUpdate(value?.id);
    useEffect(() => {
        if (isUpdate) {
            query();
        }
    }, [query, isUpdate]);
    const toolFromResponse = useMemo<DeepPartial<Tool> | undefined>(() => {
        if (!toolResponse?.tool) {
            return undefined;
        }

        // convertit le ToolResponse reçu de l'api en un DeepPartial<Tool> attendu par initValue
        return {
            id: toolResponse.tool.id,
            title: toolResponse.tool.title,
            toolCategory: toolResponse.tool.toolCategory && {
                id: toolResponse.tool.toolCategory.id,
                label: toolResponse.tool.toolCategory.label,
            },
            required: toolResponse.tool.required,
            canUpdateTitle: toolResponse.tool.canUpdateTitle,
        };
    }, [toolResponse?.tool]);

    const initialValue = isUpdate ? toolFromResponse : value;

    const initialFormValues: ToolFormValue = useMemo(
        () => initValue(initialValue || {}),
        [initialValue]
    );

    const isLoaded: boolean = isUpdate ? Boolean(toolResponse) : true;

    const initialToolThumbnails = useMemo<ApiFile[] | undefined>(() => {
        if (isLoaded && toolResponse?.tool?.toolThumbnail) {
            const apiUrl = CONFIGS.apiEntrypoint;
            const filePath = toolResponse?.tool?.toolThumbnail.publicPath;
            return [
                {
                    ...toolResponse?.tool?.toolThumbnail,
                    publicUrl: `${apiUrl}${filePath}`,
                },
            ];
        }

        return undefined;
    }, [isLoaded, toolResponse?.tool?.toolThumbnail]);

    return {
        initialValues: isLoaded ? initialFormValues : null,
        initialToolThumbnails,
        tool: toolResponse?.tool || undefined,
    };
};

export default useInitialValues;
