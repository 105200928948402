import React from 'react';
import { Skeleton } from '@mui/material';
import { Box } from '@mui/system';

const getInputs = (nbInputs: number = 3) => {
    const inputItems = [];

    for (let inputId = 0; inputId < nbInputs; inputId += 1) {
        inputItems.push(
            <Skeleton
                key={inputId}
                data-testid="form-skeleton-input"
                width="100%"
                height={60}
            />
        );
    }

    return inputItems;
};

interface FormSkeletonProps {
    nbInputs?: number;
}

function FormSkeleton({ nbInputs }: FormSkeletonProps) {
    return (
        <div data-testid="FormSkeleton">
            {getInputs(nbInputs)}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Skeleton width={100} height={50} />
            </Box>
        </div>
    );
}

export default FormSkeleton;
