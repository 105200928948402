import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListModuleCategoriesResponse from '@acdc/shared/src/features/moduleCategory/ListModuleCategoriesResponse';

export const GET_MODULE_CATEGORIES = gql`
    query GetModuleCategories {
        moduleCategories(order: [{ label: "ASC" }], itemsPerPage: 10000) {
            collection {
                id
                label
                modules(order: [{ label: "ASC" }], itemsPerPage: 10000) {
                    collection {
                        id
                        label
                        icon
                        code
                        settings(itemsPerPage: 10000) {
                            collection {
                                id
                                code
                                label
                                fieldType
                                options(itemsPerPage: 10000) {
                                    collection {
                                        id
                                        label
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

/**
 * Charges les ModulesCategories avec leurs Modules pour les afficher dans DraggableModulesList et les configurer dans ContentModuleConfigurator.
 */
const useModuleCategories = () => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<ListModuleCategoriesResponse>(
        GET_MODULE_CATEGORIES
        // pas besoin de fetchPolicy: 'network-only'
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des modules.',
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default useModuleCategories;
