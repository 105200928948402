import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListToolsResponse from '@acdc/shared/src/features/tool/ListToolsResponse';

export const GET_TOOLS = gql`
    query GetTools {
        tools(order: [{ id: "DESC" }], itemsPerPage: 10000) {
            collection {
                id
                title
                toolThumbnail {
                    id
                    publicPath
                }
                toolCategory {
                    id
                    label
                }
            }
        }
    }
`;

const useTools = () => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<ListToolsResponse>(GET_TOOLS, {
        fetchPolicy: 'network-only',
    });

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des outils.',
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default useTools;
