import Yup from '@acdc/shared/src/yup/yupFr';

const getChapterFormSchema = () =>
    Yup.object().shape({
        title: Yup.string().label('Le titre').required(),
        subTitle: Yup.string().label('Le sous titre').optional(),
        chapterImage: Yup.array().label("L'image").optional(),
    });

export default getChapterFormSchema;
