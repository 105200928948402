import ToolContentResponse from '@acdc/shared/src/features/toolContent/ToolContentResponse';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { useMutation, gql } from '@apollo/client';
import { useCallback } from 'react';

export const DELETE_TOOL_CONTENT = gql`
    mutation DeleteToolContent($id: ID!) {
        deleteToolContent(input: { id: $id }) {
            clientMutationId
        }
    }
`;

/**
 * Retourne une fonction pour supprimer un ToolContent de l'API
 */
function useDeleteToolContent(
    onDeleted?: (toolContentId: string) => void
): (toolContent: string | ToolContentResponse) => void {
    const [deleteToolContent] = useMutation(DELETE_TOOL_CONTENT);
    const setAlert = useAlert();

    const deleteItem = useCallback(
        (toolContent: string | ContentModuleResponse) => {
            const id = entityToId(toolContent);

            if (!id) {
                return;
            }

            deleteToolContent({ variables: { id } })
                .then(() => {
                    onDeleted && onDeleted(id);
                })
                .catch(() => {
                    setAlert(
                        "Une erreur est survenue lors de la suppression d'une page de l'outil.",
                        'error'
                    );
                });
        },
        [onDeleted, deleteToolContent, setAlert]
    );

    return deleteItem;
}

export default useDeleteToolContent;
