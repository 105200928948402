import ToolContentResponse from '@acdc/shared/src/features/toolContent/ToolContentResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { Box, Theme } from '@mui/material';
import React, { useCallback } from 'react';
import { pageDrawerWidth } from '../../../mui/theme';
import DragAndDropList from '../../../ui/DragAndDropList/DragAndDropList';
import { MoveToolContentFn } from '../useMoveToolContent';
import { RemoveToolContentFn } from '../useRemoveToolContent';
import DraggableToolContent from './DraggableToolContent';
import ToolContentDropZone, { OnDropFn } from './ToolContentDropZone';

export interface SortToolContentsFormProps {
    toolContents: ToolContentResponse[];
    moveToolContent: MoveToolContentFn;
    removeToolContent: RemoveToolContentFn;
}

function SortToolContentsForm({
    toolContents,
    moveToolContent,
    removeToolContent,
}: SortToolContentsFormProps) {
    const handleDrop = useCallback<OnDropFn>(
        (toolContent, position) => {
            let pos: number = 0;
            if (position !== undefined) {
                // si position donnée, on le place à la même position donc avant l'item qui avait cette position (lui il sera incrémenté comme les suivants)
                pos = position;
            } else if (toolContents.length > 0) {
                // si pas de position on le place après la position du dernier item
                pos = toolContents[toolContents.length - 1].position || 0;
                pos += 1;
            }

            moveToolContent(toolContent, pos);
        },
        [toolContents, moveToolContent]
    );

    return (
        <ToolContentDropZone
            onDrop={handleDrop}
            // le drop de cette dropzone est désactivée, on ne s'en sert qui pour afficher le cadre bleu au over
            disableDrop
            sx={{
                width: pageDrawerWidth,
            }}
            overSx={{
                boxShadow: (theme: Theme) =>
                    `0 0 0 2px ${theme.palette.primary.main}`,
            }}
        >
            <DragAndDropList>
                {toolContents.map((toolContent) => (
                    <ToolContentDropZone
                        key={entityToId(toolContent)}
                        position={toolContent.position}
                        onDrop={handleDrop}
                        overSx={{
                            // un espace apparait au dessus de l'item quand on l'over avec un autre pour indiquer que l'autre item sera
                            // droppé au dessus de celui ci
                            '&:before': {
                                content: '""',
                                display: 'block',
                                height: '38px',
                            },
                        }}
                    >
                        <DraggableToolContent
                            toolContent={toolContent}
                            enableDeleteButton
                            onRemove={removeToolContent}
                        />
                    </ToolContentDropZone>
                ))}

                {Boolean(toolContents.length) && (
                    <ToolContentDropZone
                        // une dropzone après la liste pour pouvoir push un item en fin de liste
                        onDrop={handleDrop}
                        sx={{
                            height: '100%',
                        }}
                        overSx={{
                            '&:before': {
                                content: '""',
                                display: 'block',
                                height: '38px',
                            },
                        }}
                    >
                        <Box
                            sx={{
                                minHeight: '38px',
                                height: '100%',
                            }}
                        />
                    </ToolContentDropZone>
                )}
            </DragAndDropList>
        </ToolContentDropZone>
    );
}

export default SortToolContentsForm;
