import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import Header from './Header';
import { headerHeight } from '../mui/theme';
import SideBar from './sidebar/SideBar';

function Layout() {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };
    return (
        <Box pt={headerHeight}>
            <SideBar toggleDrawer={toggleDrawer} open={open} />
            <Header toggleDrawer={toggleDrawer} />
            <Outlet />
        </Box>
    );
}

export default Layout;
