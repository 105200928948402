enum ModuleCode {
    WYSIWYG = 'wysiwyg',
    QUOTATION = 'quotation',
    PACKAGES = 'packages',
    IMAGE = 'image',
    PDF = 'pdf',
    PARTNERS = 'partners',
}

export default ModuleCode;
