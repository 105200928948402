import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import { Box, Grid } from '@mui/material';
import React from 'react';
import ToolCategoryResponse from '@acdc/shared/src/features/toolCategory/ToolCategoryResponse';
import ToolsListItem from './ToolsListItem';
import useTools from './useTools';
import useFilterToolsByCategories from './useFilterToolsByCategories';

interface ToolsListProps {
    filters: ToolCategoryResponse[];
}

function ToolsList({ filters }: ToolsListProps) {
    const { data: toolsResponse } = useTools();
    const tools = toolsResponse?.tools?.collection || null;
    const filteredTools = useFilterToolsByCategories(tools, filters);

    return (
        <Box px={2}>
            <Grid container spacing={2} columns={15}>
                {filteredTools?.map((tool: ToolResponse) => (
                    <Grid
                        item
                        sm={5}
                        md={3}
                        key={tool.id}
                        height="200px"
                        position="relative"
                    >
                        <ToolsListItem tool={tool} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ToolsList;
