enum VariableType {
    TEXT = 'texte',
    EMAIL = 'email',
    NUMBER = 'nombre',
    PROPERTY_FIELD = 'info du bien',
    AGENCY_FIELD = "info de l'agence",
    USER_FIELD = "info de l'agent",
}

export default VariableType;
