import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Contents from './Contents';
import ConfigureContent from './ConfigureContent';

function ContentsPages() {
    return (
        <Routes>
            <Route index element={<Contents />} />
            <Route path=":contentId">
                <Route path="configurer" element={<ConfigureContent />} />
                <Route index element={<Navigate replace to="/404" />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
    );
}

export default ContentsPages;
