interface ConnexionCasPageProps {
    validationUrl: string;
    casEntrypoint: string;
}

function ConnexionCasPage({
    validationUrl,
    casEntrypoint,
}: ConnexionCasPageProps) {
    window.location.href = `${casEntrypoint}/idp/cas/login?service=${encodeURIComponent(
        validationUrl
    )}`;
    return null;
}

export default ConnexionCasPage;
