enum ModuleSettingCode {
    WYSIWYG_VALUE = 'wysiwyg_value',
    IMAGE = 'image',
    IMAGE_VERTICAL_POSITION = 'image_vertical_position',
    IMAGE_HORIZONTAL_POSITION = 'image_horizontal_position',
    IMAGE_FIT = 'image_fit',
    ALT_NAME = 'alt_name',
    IMAGE_EDITABLE = 'image_editable',
    IMAGE_LABEL = 'image_label',
    IMAGE_USER_PHOTO = 'image_user_photo',
    IMAGE_PARTNER1 = 'image_partner1',
    IMAGE_PARTNER2 = 'image_partner2',
    IMAGE_PARTNER3 = 'image_partner3',
    IMAGE_PARTNER4 = 'image_partner4',
    IMAGE_PARTNER5 = 'image_partner5',
    IMAGE_PARTNER6 = 'image_partner6',
    IMAGE_PARTNER7 = 'image_partner7',
    IMAGE_PARTNER8 = 'image_partner8',
}

export default ModuleSettingCode;
