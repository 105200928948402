import { Box, IconButton } from '@mui/material';
import React from 'react';
import FlexImage from '@acdc/shared/src/ui/FlexImage';
import CancelIcon from '@mui/icons-material/Cancel';
import ModuleFile from '@acdc/shared/src/features/moduleFile/ModuleFile.model';
import ModuleFileEnum from '@acdc/shared/src/features/moduleFile/ModuleFile.enum';

interface ModuleFilePickerItemProps {
    file: ModuleFile;
    isPlaceholder: boolean;
    disabled?: boolean | undefined;
    onDelete?: (uuid: string) => void;
    apiEntrypoint: string;
}

const groupImgSx = {
    width: 'calc((100% / 4) - (0.3rem * 2))',
    height: '4rem',
    margin: '0.3rem',
    position: 'relative',
};
const imageSx = { width: '100%', height: '100%', backgroundColor: '#fff' };
const ButtonDeleteSx = {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 0,
    zIndex: 5,
};

function ModuleFilePickerItem({
    file,
    isPlaceholder,
    disabled,
    onDelete,
    apiEntrypoint,
}: ModuleFilePickerItemProps) {
    const publicPath =
        file.type === ModuleFileEnum.FILE
            ? file.publicPath
            : apiEntrypoint + file.publicPath;

    return (
        <Box sx={groupImgSx}>
            {onDelete && (
                <IconButton
                    aria-label="Supprimer"
                    color="error"
                    onClick={() => onDelete(file.uuid)}
                    data-testid="deleteSharedFile"
                    size="small"
                    sx={ButtonDeleteSx}
                    disabled={!file.uuid || disabled}
                    title="Supprimer ce partenaire"
                >
                    <CancelIcon />
                </IconButton>
            )}
            <FlexImage
                src={publicPath || ''}
                alt={file.title || ''}
                sx={{ ...imageSx, filter: isPlaceholder ? 'grayscale(1)' : '' }}
            />
        </Box>
    );
}

export default ModuleFilePickerItem;
