const CONFIGS = {
    apiEntrypoint: process.env.REACT_APP_API_ENTRYPOINT || '',
    sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
    frontEntrypoint: process.env.REACT_APP_FRONT_ENTRYPOINT || '',
    boEntrypoint: process.env.REACT_APP_BO_ENTRYPOINT || '',
    tinymceApiKey: process.env.REACT_APP_TINYMCE_API_KEY || '',
    casEntrypoint: process.env.REACT_APP_CAS_ENTRYPOINT || '',
    authStorageKeys: {
        authToken: 'authTokenUPzH5yL62z',
        refreshToken: 'refreshTokenD2boaRCVSr',
        roles: 'rolesAqKu6M8XDc',
    },
    userDataStorageKeys: {
        currentUser: 'user62e395ca8d4775.75224231',
        currentAgency: 'agency62e395d8724260.68067127',
    },
    enableCasAuthentication:
        process.env.REACT_APP_ENABLE_CAS_AUTHENTICATION || 'true',
    environment: process.env.NODE_ENV,
};

export default CONFIGS;
