import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ToolContent from '@acdc/shared/src/features/toolContent/ToolContent.model';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import useToolDetail from '../../features/tool/useToolDetail';
import SortToolContentsForm from '../../features/tool/SortToolContentsForm/SortToolContentsForm';
import useToolConfigurator from '../../features/tool/useToolConfigurator';
import MultiStepContentFormDialogButton from '../../features/content/ContentForm/MultiStepContentFormDialogButton';

const bodySx = {
    display: 'flex',
    justifyContent: 'center',
    mt: 3,
};

function ConfigureTool() {
    const { toolId } = useParams();
    const toolIri = useMemo(() => `/tools/${toolId}`, [toolId]);
    const { data: toolResponse } = useToolDetail(toolIri);
    const tool = toolResponse?.tool;
    const { toolContents, moveToolContent, removeToolContent } =
        useToolConfigurator(toolIri);

    const newToolContent = useMemo<DeepPartial<ToolContent>>(() => {
        const lastIndex = toolContents?.length
            ? toolContents.length - 1
            : undefined;
        const lastPosition =
            lastIndex !== undefined
                ? toolContents![lastIndex].position
                : undefined;
        return {
            tool: toolIri,
            position: (lastPosition ?? 0) + 1,
        };
    }, [toolContents, toolIri]);

    if (!toolContents) {
        return null;
    }

    return (
        <>
            <Box display="flex" flexWrap="nowrap">
                <Typography
                    variant="h1"
                    component="h1"
                    textAlign="center"
                    flex={1}
                >
                    {tool?.title}
                </Typography>
                <Box mt={2} px={4}>
                    <MultiStepContentFormDialogButton
                        id="create-tool-content-dialog"
                        buttonProps={{ color: 'primary', variant: 'contained' }}
                        type={ContentType.TOOL_CONTENT}
                        value={newToolContent}
                    >
                        Ajouter une page{' '}
                        <AddCircleOutlineIcon fontSize="small" sx={{ ml: 1 }} />
                    </MultiStepContentFormDialogButton>
                </Box>
            </Box>
            <Box sx={bodySx}>
                <SortToolContentsForm
                    toolContents={toolContents}
                    moveToolContent={moveToolContent}
                    removeToolContent={removeToolContent}
                />
            </Box>
        </>
    );
}

export default ConfigureTool;
