import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ConfigureContent from '../contents/ConfigureContent';
import ConfigureTool from './ConfigureTool';
import Tools from './Tools';

function ToolsPages() {
    return (
        <Routes>
            <Route index element={<Tools />} />
            <Route
                path="pages/:contentId/configurer"
                element={<ConfigureContent type={ContentType.TOOL_CONTENT} />}
            />
            <Route path=":toolId">
                <Route path="configurer" element={<ConfigureTool />} />
                <Route index element={<Navigate replace to="/404" />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
    );
}

export default ToolsPages;
