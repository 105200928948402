import React, { useMemo, useState } from 'react';
import { Formik, Field, Form, ErrorMessage, FormikProps } from 'formik';
import SubmitButton from '@acdc/shared/src/formik/SubmitButton';
import Tool from '@acdc/shared/src/features/tool/Tool.model';
import { ApolloError } from '@apollo/client';
import { Box, Stack, FormLabel } from '@mui/material';
import { Switch, TextField } from 'formik-mui';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import FormSkeleton from '@acdc/shared/src/ui/FormSkeleton';
import ToolCategory from '@acdc/shared/src/features/toolCategory/ToolCategory.model';
import BigSwitch from '@acdc/shared/src/formik/BigSwitch';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import Dropzone from '@acdc/shared/src/ui/Dropzone';
import {
    megaBytesToBytes,
    acceptImage,
} from '@acdc/shared/src/utils/file-helpers';
import useInitialValues from './useInitialValues';
import toolFormSchema from './tooFormSchema';
import useSubmit from './useSubmit';
import ToolCategorySelect from '../../toolCategory/ToolCategorySelect';

const imageMaxSize = megaBytesToBytes(5);

export interface ToolFormValue {
    title: string;
    toolCategory: Pick<Partial<ToolCategory>, 'id' | 'label'> | string | null; // model ou response
    required: boolean;
    canUpdateTitle: boolean;
    toolThumbnail: File[];
}

export interface ToolFormProps {
    value?: DeepPartial<Tool> | undefined;
    onSuccess?: ((res: ToolResponse) => void) | undefined;
    onError?: ((err: ApolloError) => void) | undefined;
}

/**
 * Formulaire de Tool.
 * Si la valeur fournie a un id le Tool est chargé depuis l'api avant de rendre le formulaire.
 */
function ToolForm({ value, onSuccess, onError }: ToolFormProps) {
    const { initialValues, initialToolThumbnails } = useInitialValues(value);
    const submit = useSubmit(value, onSuccess, onError);
    const [shouldDeleteApiFile, setShouldDeleteApiFile] = useState(false);

    const apiFiles = useMemo(() => {
        return shouldDeleteApiFile ? [] : initialToolThumbnails;
    }, [initialToolThumbnails, shouldDeleteApiFile]);

    const handleDeleteApiFile = () => {
        setShouldDeleteApiFile(true);
    };

    if (!initialValues) {
        return <FormSkeleton nbInputs={2} />;
    }

    return (
        <Formik
            validationSchema={toolFormSchema}
            initialValues={initialValues}
            onSubmit={(values: ToolFormValue, formikHelpers) => {
                submit(values, shouldDeleteApiFile, formikHelpers);
            }}
        >
            {({ values, handleChange }: FormikProps<ToolFormValue>) => (
                <Form data-testid="toolForm">
                    <Stack direction="column" spacing={2}>
                        <Field
                            component={TextField}
                            inputProps={{ 'data-testid': 'title' }}
                            id="title"
                            label="Libellé"
                            name="title"
                            required
                        />
                        <div>
                            <FormLabel htmlFor="canUpdateTitle" required>
                                Autoriser la modification du titre
                            </FormLabel>
                            <Field
                                component={Switch}
                                id="canUpdateTitle"
                                name="canUpdateTitle"
                                type="checkbox"
                            />
                            <ErrorMessage
                                name="canUpdateTitle"
                                component={ErrorHelperText}
                            />
                        </div>
                        <div>
                            <FormLabel
                                htmlFor="required"
                                required
                                sx={{ display: 'block' }}
                            >
                                Droits d&apos;affichage
                            </FormLabel>
                            <Field
                                component={BigSwitch}
                                inputProps={{
                                    'data-testid': 'required',
                                }}
                                id="required"
                                label="Droits d'affichage"
                                name="required"
                                type="checkbox"
                                checkedLabel="Obligatoire"
                                uncheckedLabel="Optionel"
                            />
                            <ErrorMessage
                                name="required"
                                component={ErrorHelperText}
                            />
                        </div>
                        <Field
                            component={ToolCategorySelect}
                            name="toolCategory"
                            label="Rubriques d'outils"
                            id="toolCategorySelect"
                            required
                        />
                        <Box mt={2}>
                            <Dropzone
                                id="toolThumbnail"
                                name="toolThumbnail"
                                value={values.toolThumbnail}
                                onChange={handleChange}
                                label="Déposez votre image"
                                multiple={false}
                                accept={acceptImage}
                                inputProps={{
                                    'data-testid': 'toolThumbnail',
                                }}
                                rejectionsListProps={{
                                    'data-testid': 'toolThumbnailRejections',
                                }}
                                validFilesListProps={{
                                    'data-testid': 'toolThumbnailValidFiles',
                                }}
                                initialApiFilesListProps={{
                                    'data-testid':
                                        'toolThumbnailInitialApiFiles',
                                }}
                                initialApiFiles={apiFiles}
                                maxSize={imageMaxSize}
                                onDeleteApiFile={handleDeleteApiFile}
                                clearableApiFiles
                            />
                            <ErrorMessage
                                name="toolThumbnail"
                                component={ErrorHelperText}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <SubmitButton
                                buttonProps={{
                                    'data-testid': 'toolFormSubmitButton',
                                    sx: { width: '396px' },
                                }}
                            >
                                Suivant
                            </SubmitButton>
                        </Box>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}

export default ToolForm;
