import { FetchResult, gql, useMutation } from '@apollo/client';
import React from 'react';
import CreateSignedUrlResponse from '@acdc/shared/src/features/signedUrl/CreateSignedUrlResponse';
import DownloadPresentationStatistics from '@acdc/shared/src/features/presentationStatistic/DownloadPresentationStatistics';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import { Box, Grid, Stack } from '@mui/material';
import SubmitButton from '@acdc/shared/src/formik/SubmitButton';
import { entitiesToIds } from '@acdc/shared/src/utils/form-helpers';
import DateTimePickerFR from '@acdc/shared/src/formik/DateTimePickerFR';
import CONFIGS from '../../configs';
import UserSelect from '../user/UserSelect';
import AgencySelect from '../agency/AgencySelect';

interface DownloadPresentationStatisticsFormValue
    extends DownloadPresentationStatistics {}

const downloadPresentationStatisticsFormSchema = Yup.object().shape({
    start: Yup.date().label('La date de début').nullable().optional(),
    end: Yup.date().label('La date de fin').nullable().optional(),
    agency: Yup.array().label("L'agence").optional(),
    user: Yup.array().label('Le collaborateur').optional(),
});

const initialValues: DownloadPresentationStatisticsFormValue = {
    start: null,
    end: null,
    agency: [],
    user: [],
};

export const CREATE_DOWNLOAD_PRESENTATION_STATISTICS_SIGNED_URL = gql`
    mutation CreateDownloadPresentationStatisticsSignedUrl(
        $input: createDownloadPresentationStatisticsSignedUrlInput!
    ) {
        createDownloadPresentationStatisticsSignedUrl(input: $input) {
            signedUrl {
                path
            }
        }
    }
`;

function DownloadPresentationStatisticsForm() {
    const [create] = useMutation(
        CREATE_DOWNLOAD_PRESENTATION_STATISTICS_SIGNED_URL
    );
    const setAlert = useAlert();

    return (
        <Formik
            validationSchema={downloadPresentationStatisticsFormSchema}
            initialValues={initialValues}
            onSubmit={(
                values: DownloadPresentationStatisticsFormValue,
                {
                    setSubmitting,
                }: FormikHelpers<DownloadPresentationStatisticsFormValue>
            ) => {
                const input: Partial<DownloadPresentationStatistics> = {
                    start: values.start || undefined,
                    end: values.end || undefined,
                    agency: entitiesToIds(values.agency),
                    user: entitiesToIds(values.user),
                };

                create({ variables: { input } })
                    .then(
                        (
                            res: FetchResult<
                                CreateSignedUrlResponse<'createDownloadPresentationStatisticsSignedUrl'>
                            >
                        ) => {
                            const path =
                                res.data
                                    ?.createDownloadPresentationStatisticsSignedUrl
                                    ?.signedUrl?.path;
                            if (path) {
                                window.open(
                                    CONFIGS.apiEntrypoint + path,
                                    '_blank'
                                );
                            } else {
                                throw new Error(
                                    'Path manquant dans la réponse'
                                );
                            }
                        }
                    )
                    .catch(() => {
                        setAlert(
                            'Une erreur est survenue lors de la création du lien de téléchargement.',
                            'error'
                        );
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        >
            <Form>
                <Stack direction="column" spacing={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={DateTimePickerFR}
                                textField={{ id: 'downloadStart' }}
                                name="start"
                                label="Date de début"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Field
                                component={DateTimePickerFR}
                                textField={{ id: 'downloadEnd' }}
                                name="end"
                                label="Date de fin"
                            />
                        </Grid>
                    </Grid>
                    <Field
                        component={AgencySelect}
                        multiple
                        id="downloadAgency"
                        name="agency"
                        label="Agence"
                    />
                    <Field
                        component={UserSelect}
                        multiple
                        id="downloadUser"
                        name="user"
                        label="Collaborateur"
                    />
                </Stack>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <SubmitButton>Extraire</SubmitButton>
                </Box>
            </Form>
        </Formik>
    );
}

export default DownloadPresentationStatisticsForm;
