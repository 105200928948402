import React from 'react';
import { Box, Paper, Typography, SxProps } from '@mui/material';
import Logo from '../../img/LAFORET_Logo.png';

export interface AuthLayoutProps {
    title: React.ReactNode;
    sx?: SxProps;
    children: React.ReactNode;
}

function AuthLayout({ title, sx, children }: AuthLayoutProps) {
    return (
        <Box height="100vh">
            <Paper
                className="AuthLayout-Paper"
                data-testid="LayoutConnexion"
                sx={{
                    width: { sm: '90%', md: '75%', lg: '65%', xl: '50%' },
                    minHeight: '60%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    ...sx,
                }}
            >
                <Box p={5}>
                    <Box
                        component="img"
                        sx={{ width: '7rem' }}
                        src={Logo}
                        alt="logo LaForet"
                    />

                    <Typography
                        variant="h1"
                        component="h1"
                        mt={4}
                        mb={3}
                        sx={{ textDecoration: 'none' }}
                    >
                        {title}
                    </Typography>

                    {children}
                </Box>
            </Paper>
        </Box>
    );
}

export default AuthLayout;
