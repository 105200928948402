import React from 'react';
import { useDrag } from 'react-dnd';
import type { DragSourceMonitor } from 'react-dnd';
import ToolContentResponse from '@acdc/shared/src/features/toolContent/ToolContentResponse';
import DragAndDropListItem from '../../../ui/DragAndDropList/DragAndDropListItem';

export const DROPPABLE_TOOL_CONTENT = 'droppable_tool_content';

export interface DragProps {
    isDragging: boolean;
}

export interface DropProps {
    isOver: boolean;
}

export interface DraggableToolContentProps {
    toolContent: ToolContentResponse;
    enableDeleteButton?: boolean;
    onRemove?: (toolContent: ToolContentResponse) => void;
    disableDrag?: boolean;
}

function DraggableToolContent({
    toolContent,
    enableDeleteButton,
    onRemove,
    disableDrag,
}: DraggableToolContentProps) {
    const [{ isDragging }, drag, preview] = useDrag<
        ToolContentResponse,
        void,
        DragProps
    >(
        () => ({
            type: DROPPABLE_TOOL_CONTENT,
            item: toolContent,
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag: () => !disableDrag,
        }),
        [toolContent, disableDrag]
    );

    return (
        <div ref={preview}>
            <DragAndDropListItem
                ref={drag}
                label={toolContent.title || ''}
                onDelete={() => onRemove && onRemove(toolContent)}
                toolContent={toolContent}
                data-testid="DraggableToolContent"
                isDragging={isDragging}
                enableDeleteButton={enableDeleteButton}
            />
        </div>
    );
}

export default DraggableToolContent;
