import ListChaptersResponse from '@acdc/shared/src/features/chapter/ListChaptersResponse';
import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import ListContentsResponse from '@acdc/shared/src/features/content/ListContentsResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useCallback, useEffect, useRef, useState } from 'react';
import SetChapters from './SetChapters';
import useContentsWithoutTools from './useContentsWithoutTools';
import useMoveContent, { MoveContentFn } from './useMoveContent';
import usePresentationFlowChapters from './usePresentationFlowChapters';
import useRemoveChapterContent, {
    RemoveChapterContentFn,
} from './useRemoveChapterContent';

export interface PresentationFlowResult {
    /**
     * La liste des Contents pas encore utilisés dans ce PresentationFlow.
     */
    availableContents: ListContentsResponse | null;
    /**
     * Les Chapters du PresentationFlow avec ses chapterContents.
     * En cas de création de ChapterContent, il sera présent dans le Chapter avec un id undefined.
     */
    chapters: ListChaptersResponse | null;
    /**
     * Déplace un Content entre 2 Chapter ou ajoute un Content inutilisé dans un Chapter.
     */
    moveContent: MoveContentFn;
    /**
     * Supprime un ChapterContent d'un Chapter.
     */
    removeChapterContent: RemoveChapterContentFn;
}

const isInChapter = (
    content: ContentResponse,
    chapters: ListChaptersResponse
) => {
    const countChapters = chapters.chapters?.collection.length || 0;
    for (let c = 0; c < countChapters; c += 1) {
        const chapter = chapters.chapters?.collection[c];
        if (
            chapter?.chapterContents?.collection.some((chapterContent) =>
                matchOnId(chapterContent.content, content)
            )
        ) {
            return true;
        }
    }

    return false;
};

/**
 * Retourne une liste de Contents sans ceux présents dans les Chapters
 */
const filterContents = (
    contents: ListContentsResponse,
    chapters: ListChaptersResponse
): ListContentsResponse => ({
    ...contents,
    contents: {
        ...contents.contents,
        collection:
            contents.contents?.collection.filter(
                (content) => !isInChapter(content, chapters)
            ) || [],
    },
});

interface PresentationFlowConfiguratorState {
    availableContents: ListContentsResponse | null;
    chapters: ListChaptersResponse | null;
}

function usePresentationFlowConfigurator(
    presentationFlowId: string
): PresentationFlowResult {
    const setAlert = useAlert();
    const { data: allContents } = useContentsWithoutTools();
    const { data: initialChapters } =
        usePresentationFlowChapters(presentationFlowId);

    const [state, setState] = useState<PresentationFlowConfiguratorState>(
        () => ({
            availableContents: null,
            chapters: null,
        })
    );

    // initialisation
    const initiated = useRef<boolean>(false);
    if (!initiated.current && state.chapters && state.availableContents) {
        initiated.current = true;
    }
    useEffect(() => {
        if (allContents && initialChapters) {
            // initialise + met à jour la page si jamais on a un reload de contents ou chapters
            setState({
                availableContents: filterContents(allContents, initialChapters),
                chapters: initialChapters,
            });
        }
    }, [setState, allContents, initialChapters]);

    const setChapters = useCallback<SetChapters>(
        (getNewChapters) => {
            if (!initiated.current) {
                setAlert(
                    'Impossible de modifier les chapitres pendant le chargement de la page.',
                    'error'
                );
                return;
            }
            setState((curr) => {
                const newChapters =
                    typeof getNewChapters === 'function'
                        ? getNewChapters(curr.chapters as ListChaptersResponse)
                        : getNewChapters;
                return {
                    chapters: newChapters,
                    availableContents: filterContents(
                        allContents as ListContentsResponse,
                        newChapters
                    ),
                };
            });
        },
        [setState, allContents, setAlert]
    );

    const doMoveContent = useMoveContent(
        state.chapters,
        setChapters,
        allContents
    );
    const moveContent = useCallback<MoveContentFn>(
        (...params) => {
            if (!initiated.current) {
                return;
            }
            doMoveContent(...params);
        },
        [doMoveContent]
    );

    const doRemoveChapterContent = useRemoveChapterContent(setChapters);
    const removeChapterContent = useCallback<RemoveChapterContentFn>(
        (...params) => {
            if (!initiated.current) {
                return;
            }
            doRemoveChapterContent(...params);
        },
        [doRemoveChapterContent]
    );

    return {
        availableContents: state.availableContents,
        chapters: state.chapters,
        moveContent,
        removeChapterContent,
    };
}

export default usePresentationFlowConfigurator;
