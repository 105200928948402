import React from 'react';
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    ListItem,
} from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';
import { useMatch, useResolvedPath } from 'react-router';

interface RootLink {
    icon: React.ReactNode;
}

type SideBarListItemLinkProps = RootLink & Pick<LinkProps, 'to' | 'children'>;

export const getTypoSx = (match: boolean) => ({
    fontWeight: match ? 'bold' : 'normal',
});

function SideBarListItemLink({ children, to, icon }: SideBarListItemLinkProps) {
    const resolved = useResolvedPath(to);
    const match = useMatch({ path: resolved.pathname, end: true });
    return (
        <ListItem disablePadding>
            <ListItemButton component={Link} to={to}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography component="span" sx={getTypoSx(!!match)}>
                            {children}
                        </Typography>
                    }
                />
            </ListItemButton>
        </ListItem>
    );
}

export default SideBarListItemLink;
