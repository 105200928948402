import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    gql,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import MutateVariableResponse from '@acdc/shared/src/features/variable/MutateVariableResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import UpdateVariableResponse from '@acdc/shared/src/features/variable/UpdateVariableResponse';
import CreateVariableResponse from '@acdc/shared/src/features/variable/CreateVariableResponse';
import Variable from '@acdc/shared/src/features/variable/Variable.model';
import {
    filterNumber,
    filterString,
} from '@acdc/shared/src/utils/form-helpers';
import VariableType from '@acdc/shared/src/features/variable/VariableType.enum';
import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import PropertyFieldEnum from '@acdc/shared/src/features/property/PropertyField.enum';
import type { VariableFormProps, VariableFormValue } from './VariableForm';
import ConfiguredVariableFragment from '../../ConfiguredVariableFragment';

export const CREATE_VARIABLE = gql`
    ${ConfiguredVariableFragment}
    mutation CreateVariable($input: createVariableInput!) {
        createVariable(input: $input) {
            variable {
                ...ConfiguredVariable
            }
        }
    }
`;

export const UPDATE_VARIABLE = gql`
    ${ConfiguredVariableFragment}
    mutation UpdateVariable($input: updateVariableInput!) {
        updateVariable(input: $input) {
            variable {
                ...ConfiguredVariable
            }
        }
    }
`;

/**
 * Submit une Variable
 */
function doSubmit(
    values: VariableFormValue,
    getId: () => string | null,
    createVariable: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<CreateVariableResponse>>,
    updateVariable: (
        options: MutationFunctionOptions
    ) => Promise<FetchResult<UpdateVariableResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<Variable>
) {
    return (
        Promise.resolve()
            // préparation de la Variable à créer
            .then(() => {
                const input: DeepPartial<Variable> = {
                    ...values,
                    type: values.type as unknown as VariableType,
                    maxChar: filterNumber(values.maxChar),
                    maxLine: filterNumber(values.maxLine),
                    defaultValue: filterString(values.defaultValue),
                    propertyField: filterString(
                        values.propertyField
                    ) as unknown as PropertyFieldEnum | null,
                    ...fixedProperties,
                };

                if (getId()) {
                    input.id = getId() || undefined;
                }

                return input;
            })

            // création de la Variable
            .then((input) =>
                (
                    (getId() ? updateVariable : createVariable) as (
                        options: MutationFunctionOptions
                    ) => Promise<FetchResult<MutateVariableResponse>>
                )({ variables: { input } }).catch((err: ApolloError) => {
                    handleError()(err);

                    throw Error('cancelled');
                })
            )
    );
}

/**
 *
 * @param getId Fonction qui retourne l'id de la Variable pour savoir si on doit faire une création ou une mise à jour.
 * @param setId Fonction qui set l'id d'une Variable créé pour que le prochain submit sache qu'il doit faire une mise à jour.
 * @param setSaved
 * @param yupSchema
 * @param onSuccess
 * @param onError
 * @param fixedProperties Des propriétés fixes à définir dans la Variable
 * @returns
 */
const useSubmitVariable = (
    getId: () => string | null,
    setId: (id: string) => void,
    setSaved: React.Dispatch<React.SetStateAction<boolean>>,
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: VariableFormProps['onSuccess'],
    onError: VariableFormProps['onError'],
    fixedProperties: DeepPartial<Variable>
) => {
    const setAlert = useAlert();
    const [createVariable] = useMutation(CREATE_VARIABLE, {
        // pas d'eviction du cache
    });

    const [updateVariable] = useMutation(UPDATE_VARIABLE, {
        // pas d'eviction du cache
    });

    return useCallback(
        (
            formValues: VariableFormValue,
            { setSubmitting, setErrors }: FormikHelpers<VariableFormValue>
        ) => {
            doSubmit(
                formValues,
                getId,
                createVariable,
                updateVariable,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties
            )
                .then((res: FetchResult<MutateVariableResponse>) => {
                    const isUpdate = Boolean(getId());
                    const resItem: VariableResponse | null | undefined =
                        isUpdate
                            ? res.data?.updateVariable?.variable
                            : res.data?.createVariable?.variable;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }
                    setId(resItem.id);
                    setSaved(true);
                    onSuccess && onSuccess(resItem);
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            getId,
            createVariable,
            updateVariable,
            fixedProperties,
            setAlert,
            yupSchema,
            onError,
            setId,
            setSaved,
            onSuccess,
        ]
    );
};

export default useSubmitVariable;
