import { useEffect, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import ListToolContentsResponse from '@acdc/shared/src/features/toolContent/ListToolContentsResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';

export const GET_TOOL_CONTENTS_OF_TOOL = gql`
    query GetTooContentsOfTool($tool: String!) {
        toolContents(tool: $tool, order: [{ id: "ASC" }], itemsPerPage: 10000) {
            collection {
                id
                title
                position
                required
            }
        }
    }
`;

const useToolContents = (tool: string | { id: string }) => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListToolContentsResponse>(
        GET_TOOL_CONTENTS_OF_TOOL,
        {
            variables: {
                tool: getIdFromIri(entityToId(tool)),
            },
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                "Une erreur est survenue lors du chargement des pages de l'outil.",
                'error'
            );
        }
    }, [error, setAlert]);

    return useMemo(() => {
        if (
            data?.toolContents?.collection === undefined ||
            data?.toolContents?.collection === null
        ) {
            return undefined;
        }
        const items = [...data.toolContents.collection];
        items.sort((a, b) => (a.position || 0) - (b.position || 0));
        return items;
    }, [data]);
};

export default useToolContents;
