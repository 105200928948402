import React, { useEffect } from 'react';
import { Box, Theme } from '@mui/material';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import type { PickModuleFileFormValue } from './PickModuleFileForm';
import SharedFileResponse from '../sharedFile/SharedFileResponse';
import FormikSharedFileSelect from './FormikSharedFileSelect';
import SubmitButton from '../../ui/SubmitButton';
import Dropzone from '../../ui/Dropzone';
import { acceptImage, megaBytesToBytes } from '../../utils/file-helpers';
import ErrorHelperText from '../../ui/ErrorHelperText';

interface PickModuleFileFormInnerProps {
    files: File[];
    disableDropzone: boolean;
    sharedFiles: SharedFileResponse[] | undefined;
    disabled: boolean | undefined;
    apiEntrypoint: string;
    multiple: boolean;
    handleChange: (e: {
        target: { name: string | null; value: File[] };
    }) => void;
    colorSelect: (theme: Theme) => string;
}
const imageMaxSize = megaBytesToBytes(5);
function PickModuleFileFormInner({
    sharedFiles,
    disabled,
    multiple,
    files,
    disableDropzone,
    apiEntrypoint,
    handleChange,
    colorSelect,
}: PickModuleFileFormInnerProps) {
    const { values, submitForm } = useFormikContext<PickModuleFileFormValue>();

    useEffect(() => {
        const nbFiles = values.files.length + values.sharedFiles.length;
        if (nbFiles > 0 && multiple === false) {
            submitForm();
        }
    }, [values, multiple, submitForm]);

    return (
        <Box
            sx={{
                maxHeight: 'calc(100vh - 170px)',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Field
                component={FormikSharedFileSelect}
                label="Gallerie"
                id="sharedFiles"
                name="sharedFiles"
                sharedFiles={sharedFiles}
                apiEntrypoint={apiEntrypoint}
                type="checkbox"
                disabled={disabled}
                multiple={multiple}
                colorSelect={colorSelect}
            />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                {!disableDropzone && (
                    <Dropzone
                        id="PartnersUploader"
                        name="files"
                        value={files}
                        onChange={handleChange}
                        label="Ajouter des images"
                        accept={acceptImage}
                        multiple={multiple}
                        maxSize={imageMaxSize}
                        showFilesOnRight
                        disabled={disabled}
                        max={8}
                    />
                )}
                <ErrorMessage name="files" component={ErrorHelperText} />
                {multiple && (
                    <SubmitButton
                        buttonProps={{
                            'data-testid': 'PickSharedFileButton',
                            sx: {
                                width: '396px',
                                marginTop: 3,
                            },
                        }}
                    >
                        Valider
                    </SubmitButton>
                )}
            </Box>
        </Box>
    );
}

export default PickModuleFileFormInner;
