import React, { useMemo } from 'react';
import type ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import ModuleFile from '@acdc/shared/src/features/moduleFile/ModuleFile.model';
import { Form, Formik, FormikProps } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import ModuleFileEnum from '@acdc/shared/src/features/moduleFile/ModuleFile.enum';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { ApolloError } from '@apollo/client';
import SettingValueResponse from '@acdc/shared/src/features/settingValue/SettingValueResponse';
import useQuerySharedFiles from '@acdc/shared/src/features/moduleFile/useQuerySharedFiles';
import generateLocalUniqueId from '@acdc/shared/src/utils/generateLocalUniqueId';
import useSubmitSettingValues from './useSubmitSettingValues';
import CONFIGS from '../../../../../configs';
import useModuleSettingPartners from '../../../../moduleSettings/useModuleSettingPartners';
import PartnersFormInner from './PartnersFormInner';

export interface PartnersFormProps {
    contentModule: ContentModuleResponse;
    onSuccess?: (res: SettingValueResponse[]) => void;
    onError?: (err: ApolloError) => void;
}

export interface PartnersFormValues {
    files: ModuleFile[];
}

const partnersFormSchema = Yup.object().shape({
    files: Yup.array().optional().max(8),
});

function PartnersForm({
    contentModule,
    onSuccess,
    onError,
}: PartnersFormProps) {
    const currentPartners = useMemo(() => {
        const partners: ModuleFile[] = [];
        if (contentModule && contentModule.settingValues) {
            contentModule.settingValues.collection.forEach((item) => {
                if (item.sharedFile !== null) {
                    partners.push({
                        id: item.id,
                        uuid: generateLocalUniqueId(),
                        publicPath: item.sharedFile?.publicPath || undefined,
                        title: item.sharedFile?.title || '',
                        type: ModuleFileEnum.SHARED_FILE,
                        sharedFile: item.sharedFile,
                    });
                }
            });
        }
        return partners;
    }, [contentModule]);

    const sharedFilesResponse = useQuerySharedFiles();

    const initialValues: PartnersFormValues = useMemo(() => {
        return { files: currentPartners };
    }, [currentPartners]);

    const fixedProperties = useMemo(
        () => ({
            contentModule: entityToId(contentModule)!,
        }),
        [contentModule]
    );

    const moduleSettingPartners = useModuleSettingPartners();

    const submit = useSubmitSettingValues(
        partnersFormSchema,
        onSuccess,
        onError,
        fixedProperties,
        contentModule.settingValues?.collection,
        moduleSettingPartners!
    );

    return (
        <Formik
            validationSchema={partnersFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            {({
                values,
                handleChange,
                isSubmitting,
            }: FormikProps<PartnersFormValues>) => (
                <Form>
                    <PartnersFormInner
                        values={values}
                        handleChange={handleChange}
                        sharedFiles={
                            sharedFilesResponse?.sharedFiles?.collection
                        }
                        isSubmitting={isSubmitting}
                        name="files"
                        placeHolderPartners={[]}
                        apiEntrypoint={CONFIGS.apiEntrypoint}
                    />
                </Form>
            )}
        </Formik>
    );
}

export default PartnersForm;
