import React, { useMemo } from 'react';
import { Drawer, Box, Typography, Theme } from '@mui/material';
import { useParams } from 'react-router';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Chapter from '@acdc/shared/src/features/chapter/Chapter.model';
import { headerHeight, pageDrawerWidth } from '../../mui/theme';
import ChapterFormDialogButton from '../../features/chapter/ChapterForm/ChapterFormDialogButton';
import ChaptersList from '../../features/chapter/ChaptersList';
import DraggableContentsList from '../../features/content/DraggableContentsList';
import usePresentationFlowConfigurator from '../../features/presentationFlow/PresentationFlowConfigurator/usePresentationFlowConfigurator';
import usePresentationFlowDetail from '../../features/presentationFlow/usePresentationFlowDetail';

function ConfigurePresentationFlow() {
    const { presentationFlowId } = useParams();
    const presentationFlowIri = useMemo(
        () => `/presentation-flows/${presentationFlowId}`,
        [presentationFlowId]
    );
    const { data: presentationFlowResponse } =
        usePresentationFlowDetail(presentationFlowIri);
    const presentationFlow = presentationFlowResponse?.presentationFlow;
    const newChapter = useMemo<DeepPartial<Chapter>>(
        () => ({ presentationFlow: presentationFlowIri }),
        [presentationFlowIri]
    );
    const { availableContents, chapters, moveContent, removeChapterContent } =
        usePresentationFlowConfigurator(presentationFlowIri);

    return (
        <>
            <Box display="flex" flexWrap="nowrap" mr={pageDrawerWidth}>
                <Typography
                    variant="h1"
                    component="h1"
                    textAlign="center"
                    flex={1}
                >
                    {presentationFlow?.label}
                </Typography>
                <Box mt={2} px={4}>
                    <ChapterFormDialogButton
                        id="create-chapter-dialog"
                        buttonProps={{ color: 'primary', variant: 'contained' }}
                        value={newChapter}
                    >
                        Nouveau chapitre{' '}
                        <AddCircleOutlineIcon fontSize="small" sx={{ ml: 1 }} />
                    </ChapterFormDialogButton>
                </Box>
            </Box>
            <Drawer
                variant="permanent"
                id="PagesDrawer"
                anchor="right"
                sx={{
                    '& .MuiDrawer-paper': {
                        backgroundColor: (theme: Theme) =>
                            theme.palette.grey['700'],
                        height: `calc(100vh - ${headerHeight})`,
                        top: headerHeight,
                        width: pageDrawerWidth,
                    },
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        padding: (theme: Theme) => theme.spacing(2),
                        marginTop: (theme: Theme) => theme.spacing(1),
                    }}
                >
                    <Typography variant="subtitle1" component="span">
                        Pages
                    </Typography>
                </Box>
                <DraggableContentsList contents={availableContents} />
            </Drawer>
            <Box
                sx={{
                    marginRight: pageDrawerWidth,
                }}
            >
                <ChaptersList
                    chapters={chapters}
                    moveContent={moveContent}
                    removeChapterContent={removeChapterContent}
                />
            </Box>
        </>
    );
}

export default ConfigurePresentationFlow;
