import DateAdapter from '@mui/lab/AdapterDateFns';

class DateFnsUtilsFR extends DateAdapter {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, class-methods-use-this
    getFormatHelperText = (format: string) => {
        // formats affichés dans les hints des date et time pickers notemment.
        switch (format) {
            case 'P':
                return 'jj/mm/aaaa';
            case 'P HH:mm':
                return 'jj/mm/aaaa hh:mm';
            default:
                return format;
        }
    };
}

export default DateFnsUtilsFR;
