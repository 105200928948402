import React from 'react';
import {
    Autocomplete as FormikAutocomplete,
    AutocompleteProps as FormikAutocompleteProps,
} from 'formik-mui';
import { CircularProgress, TextField, useTheme } from '@mui/material';
import { useFormikContext } from 'formik';

function getStartAdornment(loading: boolean | null | undefined, params: any) {
    const adornments: any[] = [];
    if (params.InputProps.startAdornment) {
        adornments.push(params.InputProps.startAdornment);
    }
    if (loading) {
        adornments.push(
            <CircularProgress key="loading" color="inherit" size={20} />
        );
    }

    if (adornments.length === 0) {
        return null;
    }

    return adornments;
}

export type AutocompleteProps = FormikAutocompleteProps<
    any,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
> & {
    label: string;
    required?: boolean;
    placeholder?: string;
};

/**
 * Autocomplete de formik-mui qui s'affiche avec un TextField.
 */
function Autocomplete({ field, ...props }: AutocompleteProps) {
    const { touched, errors } = useFormikContext<any>();
    const theme = useTheme();

    return (
        <FormikAutocomplete
            {...props}
            field={field}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name={field.name}
                    error={touched[field.name] && !!errors[field.name]}
                    helperText={errors[field.name] as string}
                    label={props.label}
                    InputProps={{
                        ...theme.components?.MuiTextField?.defaultProps
                            ?.InputProps,
                        ...params.InputProps,
                        startAdornment: getStartAdornment(
                            props.loading,
                            params
                        ),
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                    required={props?.required}
                    placeholder={props.placeholder}
                />
            )}
        />
    );
}

export default Autocomplete;
