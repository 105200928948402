import {
    ToggleButtonGroup,
    ToggleButton,
    Typography,
    ToggleButtonGroupProps,
    Theme,
} from '@mui/material';
import React, { useMemo } from 'react';
import FlexImage from '@acdc/shared/src/ui/FlexImage';
import SharedFileResponse from '@acdc/shared/src/features/sharedFile/SharedFileResponse';

const groupSx = {
    '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
        marginRight: 1,
    },
    '& .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        marginLeft: 0,
    },
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: 3,
    overflow: 'auto',
};

const imageSx = { width: '100%', backgroundColor: '#fff' };

const labelSx = {
    textAlign: 'left',
    fontSize: (theme: Theme) => theme.typography.fontSize,
    width: '100%',
    textTransform: 'none',
    marginTop: 1,
};

export type SharedFileSelectProps = ToggleButtonGroupProps & {
    sharedFiles: SharedFileResponse[];
    apiEntrypoint: string;
    colorSelect: (theme: Theme) => string;
};

function SharedFileSelect({
    sharedFiles,
    apiEntrypoint,
    disabled,
    colorSelect,
    ...props
}: SharedFileSelectProps) {
    const buttonSx = useMemo(
        () => ({
            display: 'flex',
            flexDirection: 'column',
            minHeight: '130px',
            height: '100%',
            width: '130px',
            background: '#fff',
            '&.Mui-focusVisible': {
                backgroundColor: 'rgba(0, 0, 0, 0.06)',
            },
            '&.Mui-selected': {
                background: colorSelect,
                color: 'primary.contrastText',
                '&:hover': {
                    background: colorSelect,
                },
                '&.Mui-focusVisible': {
                    background: colorSelect,
                },
            },
            borderRadius: 0,
            border: 0,
            marginTop: 1,
        }),
        [colorSelect]
    );

    const variableButtonSx = useMemo(
        () => ({
            ...buttonSx,
            ...(disabled && {
                filter: 'grayscale(70%)',
            }),
        }),
        [buttonSx, disabled]
    );

    return (
        <ToggleButtonGroup disabled={disabled} {...props} sx={groupSx}>
            {sharedFiles.map((sharedFile) => (
                <ToggleButton
                    key={sharedFile.id}
                    value={sharedFile.id!}
                    aria-label={sharedFile.title}
                    sx={variableButtonSx}
                    disableRipple
                >
                    <FlexImage
                        src={apiEntrypoint + sharedFile.publicPath}
                        alt={sharedFile.title || ''}
                        sx={imageSx}
                    />
                    <Typography variant="subtitle1" component="p" sx={labelSx}>
                        {sharedFile.title}
                    </Typography>
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
}

export default SharedFileSelect;
