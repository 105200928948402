import { useAlert } from '@acdc/shared/src/tools/alert';
import { useCallback } from 'react';
import {
    useMutation,
    FetchResult,
    MutationFunctionOptions,
    ApolloError,
} from '@apollo/client';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { FormikHelpers } from 'formik';
import SettingValue from '@acdc/shared/src/features/settingValue/SettingValue.model';
import MutateSettingValueResponse from '@acdc/shared/src/features/settingValue/MutateSettingValueResponse';
import SettingValueResponse from '@acdc/shared/src/features/settingValue/SettingValueResponse';
import Yup from '@acdc/shared/src/yup/yupFr';
import UpdateSettingValueResponse from '@acdc/shared/src/features/settingValue/UpdateSettingValueResponse';
import CreateSettingValueResponse from '@acdc/shared/src/features/settingValue/CreateSettingValueResponse';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import ModuleFile from '@acdc/shared/src/features/moduleFile/ModuleFile.model';
import ModuleSettingResponse from '@acdc/shared/src/features/moduleSetting/ModuleSettingResponse';
import type { PartnersFormProps, PartnersFormValues } from './PartnersForm';
import {
    CREATE_SETTING_VALUE,
    UPDATE_SETTING_VALUE,
} from '../useSubmitSettingValue';

function doSubmit(
    // valeur du formulaire
    formValues: PartnersFormValues,
    // Valeur qui proviens de l'api
    initialSettingValues: SettingValueResponse[] | undefined,
    createSettingValue: (
        options: MutationFunctionOptions<CreateSettingValueResponse>
    ) => Promise<FetchResult<CreateSettingValueResponse>>,
    updateSettingValue: (
        options: MutationFunctionOptions<UpdateSettingValueResponse>
    ) => Promise<FetchResult<UpdateSettingValueResponse>>,
    handleError: (
        overrideFormikKey?: string | undefined
    ) => (err: ApolloError) => void,
    fixedProperties: DeepPartial<SettingValue>,
    moduleSettings: ModuleSettingResponse[]
) {
    const settingValuePromises = moduleSettings.map((moduleSetting, index) => {
        const formValue: ModuleFile = formValues.files?.[index];
        // Create ou update si on a deja un id
        const input: DeepPartial<SettingValue> = {
            ...fixedProperties,
            moduleSetting: entityToId(moduleSetting) || '',
            sharedFile: formValue ? entityToId(formValue?.sharedFile) : null,
        };

        const oldValue = initialSettingValues?.find((settingValue) =>
            matchOnId(settingValue.moduleSetting, moduleSetting)
        );

        const isUpdate = Boolean(oldValue?.id);

        if (isUpdate) {
            input.id = oldValue?.id || '';
        }

        const mutate = isUpdate ? updateSettingValue : createSettingValue;

        return mutate({ variables: { input } });
    });

    return Promise.all(settingValuePromises).catch((err: ApolloError) => {
        handleError()(err);

        throw Error('cancelled');
    });
}
/**
 * @param yupSchema
 * @param onSuccess
 * @param onError
 * @param fixedProperties Des propriétés fixes à définir dans le SettingValue
 * @param allSettingValues
 * @returns
 */
const useSubmitSettingValues = (
    yupSchema: Yup.ObjectSchema<any>,
    onSuccess: PartnersFormProps['onSuccess'],
    onError: PartnersFormProps['onError'],
    fixedProperties: DeepPartial<SettingValue>,
    allSettingValues: SettingValueResponse[] | undefined,
    moduleSettings: ModuleSettingResponse[]
) => {
    const setAlert = useAlert();
    const [createSettingValue] = useMutation(CREATE_SETTING_VALUE, {
        // pas d'éviction du cache
    });

    const [updateSettingValue] = useMutation(UPDATE_SETTING_VALUE, {
        // pas d'éviction du cache
    });

    return useCallback(
        (
            formValues: PartnersFormValues,
            { setSubmitting, setErrors }: FormikHelpers<PartnersFormValues>
        ) => {
            doSubmit(
                formValues,
                allSettingValues,
                createSettingValue,
                updateSettingValue,
                (overrideFormikKey?: string | undefined) =>
                    handleApolloError(
                        setErrors,
                        setAlert,
                        yupSchema,
                        onError,
                        overrideFormikKey
                    ),
                fixedProperties,
                moduleSettings
            )
                .then((results: FetchResult<MutateSettingValueResponse>[]) => {
                    const settingValues = results.map((res) => {
                        const isUpdate = !!allSettingValues?.length;
                        const resItem: SettingValueResponse | null | undefined =
                            isUpdate
                                ? res.data?.updateSettingValue?.settingValue
                                : res.data?.createSettingValue?.settingValue;

                        if (!resItem?.id) {
                            // eslint-disable-next-line no-console
                            console.error('Missing data result', res.data);
                        }
                        return resItem;
                    });

                    if (settingValues) {
                        const filteredSettingValues: SettingValueResponse[] =
                            settingValues.filter(
                                (value): value is SettingValueResponse =>
                                    value !== null && value !== undefined
                            );

                        onSuccess && onSuccess(filteredSettingValues);
                    }
                })
                .finally(() => {
                    setSubmitting(false);
                })
                .catch((err: Error) => {
                    if (err.message !== 'cancelled') {
                        throw err;
                    }
                });
        },
        [
            allSettingValues,
            createSettingValue,
            updateSettingValue,
            fixedProperties,
            moduleSettings,
            setAlert,
            yupSchema,
            onError,
            onSuccess,
        ]
    );
};
export default useSubmitSettingValues;
