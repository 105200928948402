import ToolContentResponse from '@acdc/shared/src/features/toolContent/ToolContentResponse';
import { entityToId, matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useCallback } from 'react';
import useMutateToolContent from './useMutateToolContent';

export type MoveToolContentFn = (
    toolContent: string | ToolContentResponse,
    newPosition: number
) => void;

/**
 * Retourne une fonction pour déplacer un ToolContent dans un Tool.
 * Fait le déplacement directement dans les datas locales + répercute le changement sur l'API.
 */
function useMoveToolContent(
    setToolContents: React.Dispatch<
        React.SetStateAction<ToolContentResponse[] | undefined>
    >
) {
    const updateToolContent = useMutateToolContent();

    return useCallback<MoveToolContentFn>(
        (toolContent: string | ToolContentResponse, newPosition: number) => {
            setToolContents((curr) =>
                curr
                    ?.map((tc) => {
                        if (matchOnId(tc, toolContent)) {
                            // on maj la position du toolContent déplacé
                            return {
                                ...tc,
                                position: newPosition,
                            };
                        }

                        if (
                            tc.position !== undefined &&
                            tc.position >= newPosition
                        ) {
                            // on incrémente la position de tous les toolContents suivants
                            // l'api fait la même chose de son coté
                            return {
                                ...tc,
                                position: tc.position + 1,
                            };
                        }

                        return tc;
                    })
                    // réordonne par position
                    .sort(
                        (tc1, tc2) => (tc1.position || 0) - (tc2.position || 0)
                    )
            );

            updateToolContent({
                id: entityToId(toolContent) || undefined,
                position: newPosition,
            });
        },
        [setToolContents, updateToolContent]
    );
}

export default useMoveToolContent;
