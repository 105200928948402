import { Box, Theme } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SharedFileResponse from '@acdc/shared/src/features/sharedFile/SharedFileResponse';
import ModuleFile from '@acdc/shared/src/features/moduleFile/ModuleFile.model';
import ModuleFileEnum from '@acdc/shared/src/features/moduleFile/ModuleFile.enum';
import PickModuleFileFormDialogButton from './PickModuleFileFormDialogButton';
import ModuleFilePickerItem from './ModuleFilePickerItem';
import { matchOnId } from '../../utils/form-helpers';

const noValue: ModuleFile[] = [];

interface ChangeEvent {
    target: { name: string | null; value: ModuleFile[] };
}

interface ModuleFilePickerProps {
    value: ModuleFile[];
    onChange: (e: ChangeEvent) => void;
    disableDropzone: boolean;
    sharedFiles: SharedFileResponse[] | undefined;
    placeholder: ModuleFile[];
    name: string;
    disabled?: boolean;
    apiEntrypoint: string;
    /**
     * todo : expliquer ça
     * il faut renommer cette prop parceque le picker est toujours multiple.
     * C'est le PickModuleFileForm qui l'utilise pour ajouter les items 1 par un ou pouvoir en selectionner plusieurs d'un coup.
     * Mais pourquoi on a eu besoin de ça ?
     */
    multiple: boolean;
    colorSelect: (theme: Theme) => string;
}

const groupImgListSx = { display: 'flex', flexWrap: 'wrap', padding: '0.2rem' };
const containerBtnSx = {
    display: 'flex',
    justifyContent: 'center',
    width: 'calc((100% / 4) - (0.3rem * 2))',
};

/**
 * Affiche la liste des images séléctionnées avec la possibilité de les supprimer.
 * Possède également une modal avec la possibilité de selectionner des images de notre banque d'image
 * ou d'en upload des nouvelles avec la dropzone
 */
function ModuleFilePicker({
    value,
    onChange,
    disableDropzone,
    sharedFiles,
    placeholder,
    name,
    disabled,
    apiEntrypoint,
    multiple,
    colorSelect,
}: ModuleFilePickerProps) {
    const moduleFilesWithPicture = useMemo(
        // todo : pourquoi ça peut arriver ?
        () => value.filter((file) => file.publicPath !== undefined),
        [value]
    );

    const handleDelete = useCallback(
        (uuid: string) => {
            const updatedValue = value.filter((item) => item.uuid !== uuid);

            onChange &&
                onChange({
                    target: {
                        name: name || null,
                        value: updatedValue,
                    },
                });
        },
        [value, name, onChange]
    );

    // Si multiple=false on ne propose à la selection que les SharedFiles
    // qui ne sont pas déjà sélectionnés.
    const remainingSharedFiles = useMemo(() => {
        return sharedFiles?.filter((sharedFile) => {
            return !value.some(
                (val) =>
                    val.type === ModuleFileEnum.SHARED_FILE &&
                    matchOnId(val.sharedFile, sharedFile)
            );
        });
    }, [sharedFiles, value]);

    const handleChange = useCallback(
        (e: ChangeEvent) => {
            if (!multiple) {
                // Si multiple=false on veut que PickModuleFileForm ne permette de
                // sélectionner qu'1 fichier à la fois mais ModuleFilePicker reste multiple
                // donc le fichier selectionné doit être ajouté aux fichiers actuels et pas
                // venir les remplacer.
                const fullValue = [...value, ...e.target.value];
                onChange({
                    ...e,
                    target: {
                        ...e.target,
                        value: fullValue,
                    },
                });
            } else {
                onChange(e);
            }
        },
        [onChange, multiple, value]
    );

    return (
        <Box sx={groupImgListSx}>
            {moduleFilesWithPicture.length
                ? moduleFilesWithPicture.map((file) => (
                      <ModuleFilePickerItem
                          key={file.uuid}
                          file={file}
                          isPlaceholder={false}
                          disabled={disabled}
                          onDelete={handleDelete}
                          apiEntrypoint={apiEntrypoint}
                      />
                  ))
                : placeholder.map((file) => (
                      <ModuleFilePickerItem
                          key={file.id}
                          file={file}
                          isPlaceholder
                          apiEntrypoint={apiEntrypoint}
                      />
                  ))}

            {moduleFilesWithPicture.length < 8 && (
                <Box sx={containerBtnSx}>
                    <PickModuleFileFormDialogButton
                        disableDropzone={disableDropzone}
                        value={multiple ? moduleFilesWithPicture : noValue}
                        sharedFiles={
                            multiple ? sharedFiles : remainingSharedFiles
                        }
                        name={name}
                        onChange={handleChange}
                        disabled={disabled}
                        apiEntrypoint={apiEntrypoint}
                        multiple={multiple}
                        colorSelect={colorSelect}
                    >
                        <AddCircleOutlineIcon fontSize="large" />
                    </PickModuleFileFormDialogButton>
                </Box>
            )}
        </Box>
    );
}

export default ModuleFilePicker;
