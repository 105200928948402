import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import useDeleteTool from './useDeleteTool';

export interface DeleteToolButtonProps extends Omit<ButtonProps, 'onClick'> {
    tool: string | ToolResponse;
    onDeleted?: (contentId: string) => void;
    buttonComponent?: React.ComponentType<ButtonProps> | undefined;
}

function DeleteToolButton({
    tool,
    buttonComponent,
    onDeleted,
    ...buttonProps
}: DeleteToolButtonProps) {
    const [deleteTool, isLoading] = useDeleteTool(onDeleted);
    const ButtonComponent: React.ComponentType<ButtonProps> =
        buttonComponent || Button;

    return (
        <ButtonComponent
            {...buttonProps}
            onClick={() => {
                const title =
                    typeof tool !== 'string' && tool.title
                        ? ` "${tool.title}"`
                        : '';
                if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                        `Êtes-vous sûr de vouloir supprimer l'outil${title} ?`
                    )
                ) {
                    deleteTool(tool);
                }
            }}
            disabled={isLoading}
        />
    );
}

export default DeleteToolButton;
