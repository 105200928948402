import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import GetContentResponse from '@acdc/shared/src/features/content/GetContentResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import GetToolContentResponse from '@acdc/shared/src/features/toolContent/GetToolContentResponse';
import BaseContentResponse from '@acdc/shared/src/features/baseContent/BaseContentResponse';
import useTemplate from '../../template/useTemplate';
import useContentModule from '../../contentModule/useContentModule';

export const GET_CONTENT_TO_CONFIGURE = gql`
    query GetContentToConfigure($id: ID!) {
        content(id: $id) {
            id
            template {
                id
            }
        }
    }
`;
export const GET_TOOL_CONTENT_TO_CONFIGURE = gql`
    query GetToolContentToConfigure($id: ID!) {
        toolContent(id: $id) {
            id
            template {
                id
            }
        }
    }
`;

/**
 * Charge un Content avec toutes ses relations nécessaires pour le configurer.
 */
const useContentToConfigure = (
    content: string | { id: string },
    type: ContentType
): BaseContentResponse | null | undefined => {
    const setAlert = useAlert();
    const { data, error } = useQuery<
        GetContentResponse | GetToolContentResponse
    >(
        type === ContentType.TOOL_CONTENT
            ? GET_TOOL_CONTENT_TO_CONFIGURE
            : GET_CONTENT_TO_CONFIGURE,
        {
            variables: { id: entityToId(content) },
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement de la configuration de la page.',
                'error'
            );
        }
    }, [error, setAlert]);

    const res =
        type === ContentType.TOOL_CONTENT
            ? (data as GetToolContentResponse)?.toolContent
            : (data as GetContentResponse)?.content;

    const template = useTemplate(res?.template?.id);
    const contentModules = useContentModule(res?.id);

    if (!template || !contentModules) {
        return undefined;
    }
    return {
        ...res,
        template,
        contentModules: {
            collection: contentModules,
        },
    };
};

export default useContentToConfigure;
