import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import RequireAnonymous from '@acdc/shared/src/security/RequireAnonymous';
import RequireAuth from '@acdc/shared/src/security/RequireAuth';
import Layout from '../layout/Layout';
import ContentsPages from './contents/ContentsPages';
import Logout from './Logout';
import NoMatchPage from './NoMatchPage';
import PresentationFlowsPages from './presentationFlows/PresentationFlowsPages';
import AuthPages from './Auth/AuthPages';
import DownloadPresentationStatistics from './presentationStatistics/DownloadPresentationStatistics';
import ToolsPages from './tools/ToolsPages';
import NotificationsPages from './notifications/NotificationsPages';

function PageLoader() {
    return null;
}

function Pages() {
    return (
        <Suspense fallback={<PageLoader />}>
            <Routes>
                <Route
                    path="/auth/*"
                    element={
                        <RequireAnonymous>
                            <AuthPages />
                        </RequireAnonymous>
                    }
                />

                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <Layout />
                        </RequireAuth>
                    }
                >
                    <Route index element={<Navigate to="pages" replace />} />
                    <Route path="pages/*" element={<ContentsPages />} />
                    <Route
                        path="deroules/*"
                        element={<PresentationFlowsPages />}
                    />
                    <Route path="outils/*" element={<ToolsPages />} />
                    <Route
                        path="notifications/*"
                        element={<NotificationsPages />}
                    />
                    <Route
                        path="extraire-statistiques"
                        element={<DownloadPresentationStatistics />}
                    />
                </Route>
                <Route path="/deconnexion" element={<Logout />} />
                <Route path="/404" element={<NoMatchPage />} />
                <Route path="*" element={<Navigate replace to="/404" />} />
            </Routes>
        </Suspense>
    );
}

export default Pages;
