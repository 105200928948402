import { Box } from '@mui/material';
import React from 'react';
import { useDrop } from 'react-dnd';
import type { DropTargetMonitor } from 'react-dnd';
import ToolContentResponse from '@acdc/shared/src/features/toolContent/ToolContentResponse';
import { DROPPABLE_TOOL_CONTENT } from './DraggableToolContent';

const rootSx = (isOver: boolean, sx: any, overSx: any) => {
    return {
        ...sx,
        ...(isOver ? overSx : {}),
    };
};

export interface DragProps {
    isDragging: boolean;
}

export interface DropProps {
    isOver: boolean;
}

export type OnDropFn = (
    drop: ToolContentResponse,
    newPosition?: number | undefined
) => void;

export interface ToolContentDropZoneProps {
    sx?: any;
    /**
     * Le style qui s'affiche au over
     */
    overSx?: any;
    position?: number | undefined;
    onDrop: OnDropFn;
    children: React.ReactNode;
    disableDrop?: boolean;
}

function ToolContentDropZone({
    sx,
    overSx,
    position,
    onDrop,
    children,
    disableDrop,
}: ToolContentDropZoneProps) {
    const [{ isOver }, drop] = useDrop<ToolContentResponse, void, DropProps>(
        () => ({
            accept: [DROPPABLE_TOOL_CONTENT],
            drop: (item: ToolContentResponse) => {
                if (disableDrop) {
                    return;
                }
                if (position || position === 0) {
                    onDrop(item, position);
                } else {
                    onDrop(item);
                }
            },
            collect: (monitor: DropTargetMonitor) => ({
                isOver: monitor.isOver(),
            }),
        }),
        [onDrop, position, disableDrop]
    );

    return (
        <Box
            sx={rootSx(isOver, sx, overSx)}
            ref={drop}
            data-testid="ToolContentDropZone"
        >
            {children}
        </Box>
    );
}

export default ToolContentDropZone;
