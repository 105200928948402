import {
    Alert,
    AlertTitle,
    Button,
    IconButton,
    Typography,
} from '@mui/material';
import { Box, SxProps, Theme } from '@mui/system';
import React, { useCallback, useMemo, MouseEvent } from 'react';
import {
    ErrorCode,
    FileError,
    FileRejection,
    useDropzone,
    Accept,
} from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import { bytesToMegaBytes } from '../utils/file-helpers';

const defaultColor = 'grey.500';

const getErrorMessage = (
    error: FileError,
    file: File,
    maxSize: number | undefined
): string => {
    let message: string;
    switch (error.code) {
        case ErrorCode.FileInvalidType:
            message = 'Type de fichier non supporté.';
            break;
        case ErrorCode.FileTooLarge:
            message = 'Fichier trop lourd.';
            if (maxSize !== undefined) {
                message += ` ${bytesToMegaBytes(maxSize)}MB maximum attendus.`;
            }
            break;
        case ErrorCode.FileTooSmall:
            message = 'Fichier trop petit.';
            break;
        case ErrorCode.TooManyFiles:
            message = 'Trop de fichiers.';
            break;
        default:
            message = 'Une erreur est survenue';
            break;
    }

    // eslint-disable-next-line no-irregular-whitespace
    const fileName: string = file && file.name ? `${file.name} : ` : '';

    return fileName + message;
};

function toEvent<T = any>(
    name: string | null,
    value: T
): { target: { name: string | null; value: T } } {
    return {
        target: {
            name,
            value,
        },
    };
}

const handleAcceptedFiles = (
    multiple: boolean,
    value: File[],
    acceptedFiles: File[]
): File[] => {
    if (multiple) {
        return [
            ...value,
            ...acceptedFiles, // on push les nouveaux fichiers
        ];
    }
    return [
        ...acceptedFiles, // on remplace par le nouveau fichier
    ];
};

const getMaxRemaining = (
    max: number | null | undefined,
    current: number
): number | undefined => {
    if (!max && max !== 0) {
        return undefined;
    }

    const remaining: number = max - current;

    return remaining < 0 ? 0 : remaining;
};

const getBorderColor = (
    isDragActive: boolean,
    isDragAccept: boolean,
    isDragReject: boolean
): string => {
    if (isDragAccept) {
        return 'success.main';
    }

    if (isDragReject) {
        return 'error.main';
    }

    if (isDragActive) {
        return 'grey.700';
    }

    return defaultColor;
};

const generateUniqueKey = (file: File) => {
    return `${window.btoa(encodeURIComponent(file.name))}_${file.size}}]`;
};

const iconSx = {
    fontSize: '50px',
    color: defaultColor,
};

const buttonSx = {
    backgroundColor: defaultColor,
    '&:hover': {
        backgroundColor: 'grey.600',
    },
};

export interface ApiFile {
    originalName?: string;
    /**
     * L'url pour télécharger le fichier.
     * Notez qu'on attend l'url complète alors que l'api retourne habituellement le chemin sans domaine ni protocol.
     */
    publicUrl?: string | null;
    size?: string;
}

interface DropzoneProps {
    id: string;
    label: string;
    value?: File[];
    onChange?: (e: { target: { name: string | null; value: File[] } }) => void;
    name?: string;
    accept?: Accept;
    multiple?: boolean;
    max?: number;
    disabled?: boolean;
    helperText?: string;
    inputProps?: any;
    rejectionsListProps?: any;
    validFilesListProps?: any;
    /**
     * Les fichiers déjà uploadés.
     */
    initialApiFiles?: ApiFile[] | undefined;
    initialApiFilesListProps?: any;
    /**
     * En bytes
     */
    maxSize?: number | undefined;
    /**
     * Doit on pouvoir supprimer les fichiers de la dropzone ?
     * Default true
     */
    clearable?: boolean;
    onDeleteApiFile?: (apiFile: ApiFile) => void;
    onDeleteAllApiFiles?: (apiFiles: ApiFile[]) => void;
    clearableApiFiles?: boolean;
    showFilesOnRight?: boolean;
}

function Dropzone({
    id,
    label,
    value = [],
    onChange,
    name,
    accept,
    multiple = true,
    max,
    disabled,
    helperText,
    inputProps,
    rejectionsListProps,
    validFilesListProps,
    initialApiFiles,
    initialApiFilesListProps,
    maxSize,
    clearable: clearableInput,
    onDeleteApiFile,
    onDeleteAllApiFiles,
    clearableApiFiles: clearableApiFilesInput,
    showFilesOnRight = false,
}: DropzoneProps) {
    const clearable = clearableInput !== undefined ? clearableInput : true;
    const clearableApiFiles =
        clearableApiFilesInput !== undefined ? clearableApiFilesInput : false;

    const maxRemaining: number | undefined = getMaxRemaining(max, value.length);

    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            const valueUniqueKeys: string[] = value.map((file: File) =>
                generateUniqueKey(file)
            );
            const newFiles: File[] = acceptedFiles.filter(
                (file: File) =>
                    !valueUniqueKeys.includes(generateUniqueKey(file))
            );
            onChange &&
                onChange(
                    toEvent(
                        name || null,
                        handleAcceptedFiles(multiple, value, newFiles)
                    )
                );
        },
        [value, onChange, name, multiple]
    );

    const handleDelete = useCallback(
        (index: number) => {
            onChange &&
                onChange(
                    toEvent(
                        name || null,
                        value.reduce(
                            (curr: File[], file: File, ind: number) => {
                                if (ind !== index) {
                                    curr.push(file);
                                }

                                return curr;
                            },
                            []
                        )
                    )
                );
        },
        [value, onChange, name]
    );

    const handleDeleteAll = useCallback(() => {
        onChange && onChange(toEvent(name || null, []));
    }, [onChange, name]);

    const {
        getRootProps,
        getInputProps,
        fileRejections,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop: handleDrop,
        accept,
        multiple,
        maxFiles: maxRemaining,
        disabled: maxRemaining === 0 ? true : disabled,
        maxSize,
    });

    const dropzoneStyle = useMemo<SxProps<Theme>>(
        () => ({
            border: 2,
            borderColor: getBorderColor(
                isDragActive,
                isDragAccept,
                isDragReject
            ),
            borderRadius: 1,
            borderStyle: 'dashed',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: (theme: Theme) => theme.spacing(2),
            paddingX: (theme: Theme) => theme.spacing(3),
        }),
        [isDragActive, isDragAccept, isDragReject]
    );

    const files = useMemo(
        () =>
            value.map((file: File, index: number) => (
                <li key={generateUniqueKey(file)}>
                    {file.name} - {bytesToMegaBytes(file.size).toFixed(2)} MB
                    {clearable && (
                        <IconButton
                            type="button"
                            onClick={() => handleDelete(index)}
                            aria-label="delete"
                            size="small"
                            title="Supprimer"
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </li>
            )),
        [value, handleDelete, clearable]
    );

    const rejections: React.ReactNode[] = useMemo(
        () =>
            fileRejections.reduce(
                (curr: React.ReactNode[], r: FileRejection) => {
                    // eslint-disable-next-line no-param-reassign
                    curr = r.errors.reduce(
                        (rej: React.ReactNode[], error: FileError) => {
                            rej.push(
                                <ul key={rej.length}>
                                    {getErrorMessage(error, r.file, maxSize)}
                                </ul>
                            );

                            return rej;
                        },
                        curr
                    );

                    return curr;
                },
                []
            ),
        [fileRejections, maxSize]
    );

    const initialApiFilesItems: React.ReactNode[] | undefined = useMemo(() => {
        if (!initialApiFiles) {
            return undefined;
        }
        return initialApiFiles.map((file: ApiFile) => (
            <li key={`apiFile${file.publicUrl}`}>
                <a
                    href={`${file.publicUrl}`}
                    target="_blank"
                    download
                    rel="noreferrer"
                >
                    {file.originalName}
                </a>
                {file.size &&
                    ` - ${bytesToMegaBytes(parseInt(file.size, 10)).toFixed(
                        2
                    )} MB`}
                {clearableApiFiles && onDeleteApiFile && (
                    <IconButton
                        type="button"
                        onClick={() => onDeleteApiFile(file)}
                        aria-label="delete"
                        size="small"
                        title="Supprimer"
                    >
                        <DeleteIcon />
                    </IconButton>
                )}
            </li>
        ));
    }, [clearableApiFiles, onDeleteApiFile, initialApiFiles]);

    return (
        <Box
            component="section"
            sx={
                showFilesOnRight
                    ? { display: 'flex', justifyContent: 'center' }
                    : {}
            }
        >
            <Box {...getRootProps()} sx={dropzoneStyle}>
                <input
                    {...getInputProps({
                        name,
                        id,
                        'data-testid': 'dropzone',
                        ...inputProps,
                    })}
                />
                <PhotoSizeSelectActualOutlinedIcon sx={iconSx} />
                <Button
                    component="label"
                    htmlFor={id}
                    onClick={(e: MouseEvent) => {
                        e.preventDefault();
                    }}
                    variant="contained"
                    size="small"
                    sx={buttonSx}
                >
                    {label}
                </Button>
                {helperText && (
                    <Typography variant="body2">{helperText}</Typography>
                )}
            </Box>
            <Box
                mt={2}
                component="aside"
                sx={showFilesOnRight ? { marginLeft: 3 } : {}}
            >
                {Boolean(rejections.length) && (
                    <Alert severity="error">
                        <AlertTitle>Erreurs :</AlertTitle>
                        <ul
                            data-testid="dropzone-errors"
                            {...rejectionsListProps}
                        >
                            {rejections}
                        </ul>
                    </Alert>
                )}
                {Boolean(files.length) && (
                    <Box mt={2}>
                        <Typography variant="h4" component="h4">
                            Fichiers
                            {value && !!value.length && clearable && (
                                <IconButton
                                    type="button"
                                    onClick={() => handleDeleteAll()}
                                    aria-label="delete"
                                    size="small"
                                    title="Tout supprimer"
                                    disabled={disabled}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </Typography>
                        <ul
                            data-testid="dropzone-files"
                            {...validFilesListProps}
                        >
                            {files}
                        </ul>
                    </Box>
                )}
                {Boolean(
                    initialApiFiles?.length &&
                        !files.length &&
                        !rejections.length
                ) && (
                    <Box mt={2}>
                        <Typography variant="h4" component="h4">
                            Fichiers
                            {initialApiFiles &&
                                !!initialApiFiles.length &&
                                clearableApiFiles &&
                                onDeleteAllApiFiles && (
                                    <IconButton
                                        type="button"
                                        onClick={() =>
                                            onDeleteAllApiFiles(initialApiFiles)
                                        }
                                        aria-label="delete"
                                        size="small"
                                        title="Tout supprimer"
                                        disabled={disabled}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                        </Typography>
                        <ul
                            data-testid="dropzone-initial-api-files"
                            {...initialApiFilesListProps}
                        >
                            {initialApiFilesItems}
                        </ul>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default Dropzone;
