import ToolContentResponse from '@acdc/shared/src/features/toolContent/ToolContentResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useCallback } from 'react';
import useDeleteToolContent from './useDeleteToolContent';

export type RemoveToolContentFn = (
    toolContent: string | ToolContentResponse
) => void;

/**
 * Retourne une fonction pour supprimer un ToolContent (du Tool et tout court).
 * Fait la suppression directement dans les datas locales + répercute le changement sur l'API.
 */
function useRemoveToolContent(
    setToolContents: React.Dispatch<
        React.SetStateAction<ToolContentResponse[] | undefined>
    >
) {
    const deleteToolContent = useDeleteToolContent();

    return useCallback<RemoveToolContentFn>(
        (toolContent: string | ToolContentResponse) => {
            if (
                // eslint-disable-next-line no-alert
                !window.confirm(
                    'Êtes-vous sûr de vouloir supprimer définitivement cette page et toute sa configuration ?'
                )
            ) {
                return;
            }

            setToolContents((curr) =>
                curr?.filter((tc) => !matchOnId(tc, toolContent))
            );

            deleteToolContent(toolContent);
        },
        [setToolContents, deleteToolContent]
    );
}

export default useRemoveToolContent;
