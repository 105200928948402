import React, { useMemo } from 'react';
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import SubmitButton from '@acdc/shared/src/formik/SubmitButton';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import BaseContentModel from '@acdc/shared/src/features/baseContent/BaseContent.model';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import MutateContentResponse from '@acdc/shared/src/features/content/MutateContentResponse';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { Box, Stack } from '@mui/material';
import BaseContentResponse from '@acdc/shared/src/features/baseContent/BaseContentResponse';
import MutateToolContentResponse from '@acdc/shared/src/features/toolContent/MutateToolContentResponse';
import ToolContent from '@acdc/shared/src/features/toolContent/ToolContent.model';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import FormikTemplateSelect from '../../template/FormikTemplateSelect';
import useQueryTemplatesToPick from './useQueryTemplatesToPick';
import TemplateSelectSkeleton from '../../template/TemplateSelectSkeleton';

export const CREATE_CONTENT = gql`
    mutation CreateContent($input: createContentInput!) {
        createContent(input: $input) {
            content {
                id
            }
        }
    }
`;

export const UPDATE_CONTENT = gql`
    mutation UpdateContent($input: updateContentInput!) {
        updateContent(input: $input) {
            content {
                id
            }
        }
    }
`;

export const CREATE_TOOL_CONTENT = gql`
    mutation CreateToolContent($input: createToolContentInput!) {
        createToolContent(input: $input) {
            toolContent {
                id
            }
        }
    }
`;

export const UPDATE_TOOL_CONTENT = gql`
    mutation UpdateToolContent($input: updateToolContentInput!) {
        updateToolContent(input: $input) {
            toolContent {
                id
            }
        }
    }
`;

const getMutation = (type: ContentType, isUpdate: boolean) => {
    if (type === ContentType.TOOL_CONTENT) {
        return isUpdate ? UPDATE_TOOL_CONTENT : CREATE_TOOL_CONTENT;
    }
    return isUpdate ? UPDATE_CONTENT : CREATE_CONTENT;
};

type PickContentTemplateFormValue = {
    template: string | null;
};

const pickContentTemplateFormSchema = Yup.object().shape({
    template: Yup.string().label('Le template').nullable().required(),
});

const initValue = (
    value: DeepPartial<BaseContentModel>
): PickContentTemplateFormValue => ({
    template: entityToId(value?.template),
});

export interface PickContentTemplateFormProps {
    type?: ContentType | undefined;
    value?: DeepPartial<BaseContentModel>;
    onSuccess?: (content: BaseContentResponse) => void;
    onError?: (err: ApolloError) => void;
}

function PickContentTemplateForm({
    type,
    value,
    onSuccess,
    onError,
}: PickContentTemplateFormProps) {
    const setAlert = useAlert();
    const isUpdate: boolean = !!value?.id;

    const [mutate] = useMutation(
        getMutation(type || ContentType.CONTENT, isUpdate),
        {
            update(cache) {
                cache.evict({
                    fieldName:
                        type === ContentType.TOOL_CONTENT
                            ? 'toolContents'
                            : 'contents',
                });
            },
        }
    );

    const initialValues: PickContentTemplateFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    const { data: templatesResponse } = useQueryTemplatesToPick();

    if (!templatesResponse?.templates) {
        return <TemplateSelectSkeleton />;
    }

    const {
        templates: { collection: templates },
    } = templatesResponse;

    return (
        <Formik
            validationSchema={pickContentTemplateFormSchema}
            initialValues={initialValues}
            onSubmit={(
                values: PickContentTemplateFormValue,
                {
                    setSubmitting,
                    setErrors,
                }: FormikHelpers<PickContentTemplateFormValue>
            ) => {
                const input: DeepPartial<BaseContentModel> = {
                    ...value, // forward des valeurs du form précédent
                    template: values.template!,
                };

                if ((input as DeepPartial<ToolContent>).tool) {
                    const toolInput = input as DeepPartial<ToolContent>;
                    (input as DeepPartial<ToolContent>).tool =
                        entityToId(toolInput.tool) || undefined;
                }

                if (isUpdate) {
                    input.id = value?.id;
                }

                mutate({ variables: { input } })
                    .finally(() => {
                        setSubmitting(false);
                    })
                    .then(
                        (
                            res: FetchResult<
                                | MutateContentResponse
                                | MutateToolContentResponse
                            >
                        ) => {
                            let resItem: BaseContentResponse | null | undefined;
                            if (type === ContentType.TOOL_CONTENT) {
                                const data =
                                    res.data as MutateToolContentResponse;
                                resItem = isUpdate
                                    ? data?.updateToolContent?.toolContent
                                    : data?.createToolContent?.toolContent;
                            } else {
                                const data = res.data as MutateContentResponse;
                                resItem = isUpdate
                                    ? data?.updateContent?.content
                                    : data?.createContent?.content;
                            }

                            if (!resItem?.id) {
                                // eslint-disable-next-line no-console
                                console.error('Missing data result', res.data);
                                return;
                            }

                            onSuccess && onSuccess(resItem);
                        }
                    )
                    .catch(
                        handleApolloError(
                            setErrors,
                            setAlert,
                            pickContentTemplateFormSchema,
                            onError
                        )
                    );
            }}
        >
            <Form data-testid="contentForm">
                <Stack direction="column" spacing={2}>
                    <div>
                        <Field
                            component={FormikTemplateSelect}
                            id="template"
                            name="template"
                            templates={templates}
                            type="checkbox"
                            exclusive
                            disabled={isUpdate}
                        />
                        <ErrorMessage
                            name="template"
                            component={ErrorHelperText}
                        />
                    </div>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <SubmitButton
                            buttonProps={{
                                'data-testid':
                                    'pickContentTemplateFormSubmitButton',
                                sx: { width: '396px' },
                            }}
                        >
                            Suivant
                        </SubmitButton>
                    </Box>
                </Stack>
            </Form>
        </Formik>
    );
}

export default PickContentTemplateForm;
