import React from 'react';
import { Box, Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NotificationFormDialogButton from '../../features/notification/NotificationsForm/NotificationFormDialogButton';
import NotificationsList from '../../features/notification/NotificationList';

function Notifications() {
    return (
        <>
            <Typography variant="h1" component="h1" sx={visuallyHidden}>
                Notifications
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 2,
                    mb: 4,
                    mx: 4,
                }}
            >
                <NotificationFormDialogButton
                    id="create-notification-dialog"
                    buttonProps={{ color: 'primary', variant: 'contained' }}
                >
                    Créer une notification{' '}
                    <AddCircleOutlineIcon fontSize="small" sx={{ ml: 1 }} />
                </NotificationFormDialogButton>
            </Box>
            <NotificationsList />
        </>
    );
}

export default Notifications;
