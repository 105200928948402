import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import useDeleteChapter from './useDeleteChapter';

export interface DeleteChapterButtonProps extends Omit<ButtonProps, 'onClick'> {
    chapter: Pick<ChapterResponse, 'id' | 'title'>;
    onDeleted?: (chapterId: string) => void;
    buttonComponent?: React.ComponentType<ButtonProps> | undefined;
}

function DeleteChapterButton({
    chapter,
    buttonComponent,
    onDeleted,
    ...buttonProps
}: DeleteChapterButtonProps) {
    const [deleteChapter, isLoading] = useDeleteChapter(onDeleted);
    const ButtonComponent: React.ComponentType<ButtonProps> =
        buttonComponent || Button;

    return (
        <ButtonComponent
            color="error"
            {...buttonProps}
            onClick={() => {
                const title = chapter.title ? ` "${chapter.title}"` : '';
                if (
                    // eslint-disable-next-line no-alert
                    window.confirm(
                        `Êtes-vous sûr de vouloir supprimer le chapitre${title} ?`
                    )
                ) {
                    deleteChapter(chapter);
                }
            }}
            disabled={isLoading}
            type="button"
        />
    );
}

export default DeleteChapterButton;
