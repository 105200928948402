import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import ListPresentationFlowsResponse from '@acdc/shared/src/features/presentationFlow/ListPresentationFlowsResponse';

export const GET_PRESENTATION_FLOWS = gql`
    query GetPresentationFlows {
        presentationFlows(order: [{ id: "DESC" }], itemsPerPage: 10000) {
            collection {
                id
                label
            }
        }
    }
`;

const usePresentationFlows = () => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<ListPresentationFlowsResponse>(
        GET_PRESENTATION_FLOWS,
        {
            fetchPolicy: 'network-only',
        }
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des déroulés.',
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default usePresentationFlows;
