import React from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { Stack } from '@mui/material';
import { TextField } from 'formik-mui';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import { FormikPasswordField } from '../../formik/PasswordField';
import SubmitButton from '../../formik/SubmitButton';
import UserResponse from '../user/UserResponse';
import { handleApolloError } from '../../utils/error-helpers';
import Yup from '../../yup/yupFr';
import { useAlert } from '../../tools/alert';

export const CONNEXION_USER = gql`
    mutation loginUser($email: String!, $password: String!) {
        loginUser(input: { email: $email, password: $password }) {
            user {
                id
                code
                token
                refreshToken
                roles
                firstname
                lastname
            }
        }
    }
`;

const userSchema = Yup.object().shape({
    email: Yup.string().label('Le nom').required(),
    password: Yup.string().label('Le mot de passe').required(),
});

interface LoginUser {
    email: string;
    password: string;
}

interface MutateUserResult {
    loginUser: {
        user: UserResponse;
    };
}

interface UserFormProps {
    onSuccess?: (user: UserResponse) => void;
    onError?: (err: ApolloError) => void;
}

function ConnexionForm({ onSuccess, onError }: UserFormProps) {
    const initialValues: LoginUser = { email: '', password: '' };
    const setAlert = useAlert();
    const [mutate] = useMutation(CONNEXION_USER);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={userSchema}
            onSubmit={(
                values: LoginUser,
                { setSubmitting, setErrors }: FormikHelpers<LoginUser>
            ) => {
                mutate({ variables: values })
                    .finally(() => {
                        setSubmitting(false);
                    })
                    .then((res: FetchResult<MutateUserResult>) => {
                        if (!res.data) {
                            throw Error('Missing data result');
                        }
                        onSuccess && onSuccess(res.data.loginUser.user);
                    })
                    .catch((error: ApolloError) => {
                        if (error.message === 'Invalid credentials.') {
                            setAlert('Identifiants invalides.', 'error');
                        } else {
                            handleApolloError(
                                setErrors,
                                setAlert,
                                userSchema,
                                onError
                            )(error);
                        }
                    });
            }}
        >
            <Form>
                <Stack spacing={3}>
                    <Stack spacing={4}>
                        <Field
                            component={TextField}
                            type="email"
                            id="email"
                            label="Email"
                            name="email"
                            inputProps={{ 'data-testid': 'Email' }}
                        />
                        <Field
                            component={FormikPasswordField}
                            name="password"
                            id="password"
                            label="Mot de passe"
                            inputProps={{ 'data-testid': 'MotDePasse' }}
                        />
                    </Stack>
                    <SubmitButton
                        buttonProps={{ 'data-testid': 'connexionFormSubmit' }}
                    />
                </Stack>
            </Form>
        </Formik>
    );
}

export default ConnexionForm;
