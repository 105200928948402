import React from 'react';
import { Field, FieldAttributes } from 'formik';
import { TextField } from 'formik-mui';
import VariableType from '@acdc/shared/src/features/variable/VariableType.enum';
import { TextFieldProps } from '@mui/material';

/**
 * Les champs du model Variable utilisés mais compatible avec
 * VariableResponse et VariableFormValue.
 */
export interface LimitedVariable {
    label: string | undefined | null;
    isTextMulti: boolean | undefined | null;
    maxChar: string | number | undefined | null;
    maxLine: string | number | undefined | null;
    type: string | undefined | null;
}

const getInputType = (type: string | undefined) => {
    switch (type) {
        case VariableType.EMAIL:
            return 'email';
        case VariableType.NUMBER:
            return 'number';
        default:
            return undefined;
    }
};

export type VariableValueFormInnerProps = FieldAttributes<TextFieldProps> & {
    variable: Partial<LimitedVariable>;
};

/**
 * Champ texte pour saisir la valeur d'une Variable (donc value de VariableValue ou defaultValue de Variable).
 * Le champ s'adapte à la configuration de la Variable.
 */
function VariableValueInput({
    variable,
    ...props
}: VariableValueFormInnerProps) {
    return (
        <Field
            component={TextField}
            label={variable.label}
            type={getInputType(variable.type || VariableType.TEXT)}
            multiline={
                variable.type === VariableType.TEXT && variable.isTextMulti
            }
            rows={
                variable.type === VariableType.TEXT &&
                variable.isTextMulti &&
                variable.maxLine
                    ? variable.maxLine
                    : undefined
            }
            minRows={
                variable.type === VariableType.TEXT &&
                variable.isTextMulti &&
                !variable.maxLine
                    ? 2
                    : undefined
            }
            {...props}
            InputProps={{
                'data-testid': 'variableValueInput',
                ...props.InputProps,
                inputProps: {
                    maxLength:
                        variable.type === VariableType.TEXT &&
                        !variable.isTextMulti &&
                        variable.maxChar
                            ? variable.maxChar
                            : undefined,
                    ...props.InputProps?.inputProps,
                },
            }}
        />
    );
}

export default VariableValueInput;
