import { useAlert } from '@acdc/shared/src/tools/alert';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import ListModuleSettingsResponse from '@acdc/shared/src/features/moduleSetting/ListModuleSettingsResponse';
import moduleSettingsCodePartner from '@acdc/shared/src/features/partner/moduleSettingsCodePartner';

export const GET_MODULE_SETTINGS_PARTNERS = gql`
    query GetModuleSettings {
        moduleSettings(itemsPerPage: 10000, code_list: ${JSON.stringify(
            moduleSettingsCodePartner()
        )}) {
            collection {
                id
                label
                code
                module {
                    id
                }
            }
        }
    }
`;

const useModuleSettingPartners = () => {
    const setAlert = useAlert();
    const { data, error } = useQuery<ListModuleSettingsResponse>(
        GET_MODULE_SETTINGS_PARTNERS
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des options du module partenaire.',
                'error'
            );
        }
    }, [error, setAlert]);

    return data?.moduleSettings?.collection;
};

export default useModuleSettingPartners;
