import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import GetContentResponse from '@acdc/shared/src/features/content/GetContentResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { getSingleErrorMessage } from '@acdc/shared/src/utils/error-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { ApolloError, FetchResult, useMutation, gql } from '@apollo/client';
import { useCallback, useState } from 'react';

export const DUPLICATE_CONTENT = gql`
    mutation DuplicateContent($input: duplicateContentInput!) {
        duplicateContent(input: $input) {
            content {
                id
            }
        }
    }
`;

export interface DuplicateContentResponse {
    duplicateContent?: GetContentResponse;
}
function useDuplicateContent(
    onSuccess?: ((contentId: string) => void) | undefined
): [(content: string | { id: string }) => void, boolean] {
    const [mutate] = useMutation(DUPLICATE_CONTENT, {
        update(cache) {
            cache.evict({ fieldName: 'contents' });
        },
    });
    const [submitting, setSubmitting] = useState<boolean>(false);
    const setAlert = useAlert();

    const duplicate = useCallback(
        (content: string | { id: string }) => {
            mutate({ variables: { input: { contentId: entityToId(content) } } })
                .finally(() => {
                    setSubmitting(false);
                })
                .then((res: FetchResult<DuplicateContentResponse>) => {
                    const resItem: ContentResponse | null | undefined =
                        res.data?.duplicateContent?.content;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }

                    onSuccess && onSuccess(resItem.id);
                })
                .catch((err: ApolloError) => {
                    setAlert(getSingleErrorMessage(err), 'error');
                });
        },
        [mutate, setSubmitting, setAlert, onSuccess]
    );

    return [duplicate, submitting];
}

export default useDuplicateContent;
