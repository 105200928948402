import Tool from '@acdc/shared/src/features/tool/Tool.model';
import ToolResponse from '@acdc/shared/src/features/tool/ToolResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CONFIGS from '../../../configs';
import ToolFormDialogButton from '../ToolForm/ToolFormDialogButton';
import DeleteToolButton from '../DeleteToolButton';

const rootSx = {
    height: '100%',
    overflow: 'hidden',
    borderRadius: 1,
    position: 'relative',
    '& .ToolsListItemActions': {
        display: 'none',
    },
    '&:hover .ToolsListItemActions': {
        display: 'flex',
    },
};

const actionsSx = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    flexFlow: 'row nowrap',
    '& > button:not(:first-of-type)': {
        marginLeft: 2,
    },
};

const actionProps = {
    sx: {
        backgroundColor: 'grey.400',
        '&:hover': {
            backgroundColor: 'grey.600',
        },
    },
};

export interface ToolsListItemProps {
    tool: ToolResponse;
}

function ToolsListItem({ tool }: ToolsListItemProps) {
    const toolToUpdate = useMemo<DeepPartial<Tool>>(
        () => ({
            id: tool.id,
            // on laisse ToolForm charger les autres champs
        }),
        [tool.id]
    );
    const navigate = useNavigate();
    const handleToolUpdated = useCallback(
        (res: ToolResponse) => {
            navigate(`/outils/${getIdFromIri(entityToId(res))}/configurer`);
        },
        [navigate]
    );

    return (
        <Box sx={rootSx} data-testid="ToolsListItemRoot">
            <Box
                component="img"
                height="100%"
                width="100%"
                src={
                    tool.toolThumbnail?.publicPath
                        ? `${CONFIGS.apiEntrypoint}${tool.toolThumbnail?.publicPath}`
                        : '/blank.jpg'
                }
                alt={tool.title}
                loading="lazy"
            />
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    paddingX: 2.5,
                    paddingY: 1,
                    backgroundColor: (theme: Theme) =>
                        theme.palette.grey['700'],
                    borderTopRightRadius: (theme: Theme) =>
                        theme.shape.borderRadius,
                }}
            >
                <Typography variant="body1" component="span">
                    {tool.title}
                </Typography>
            </Box>
            <Box
                className="ToolsListItemActions"
                sx={actionsSx}
                data-testid="ToolsListItemActions"
            >
                <ToolFormDialogButton
                    id={`update-Tool-${getIdFromIri(entityToId(tool))}`}
                    value={toolToUpdate}
                    onSuccess={handleToolUpdated}
                    buttonComponent={IconButton}
                    buttonProps={actionProps}
                >
                    <EditIcon />
                </ToolFormDialogButton>

                <DeleteToolButton
                    tool={tool}
                    buttonComponent={IconButton}
                    {...actionProps}
                >
                    <DeleteIcon />
                </DeleteToolButton>
            </Box>
        </Box>
    );
}

export default ToolsListItem;
