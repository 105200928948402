import { useState, useCallback, useEffect, useRef } from 'react';

interface UseDebounceState {
    value: any;
    event: Event | null;
}

type Event = { target: { value: any } };
type OnCancel = () => void;

export interface DebounceOptions {
    /**
     * default = 400
     */
    debounceTime?: number | undefined;

    /**
     * Callback appelée directement quand la valeur change sans attendre le debounce.
     * Pratique pour lancer un loader par exemple qui indique une modification en cours mais pas encore traitée.
     * Cette callback n'est pas appelée à chaque event reçu mais à chaque event avec une valeur différente de la dernière
     * valeur remontée après debounce.
     *
     * onDirectChange peut retourner une autre callback qu'on pourrait appeler "onCancel" qui sera appelée si la
     * modification est annulée.
     * Par exemple si la valeur initiale vaut "foo", que je modifie en "bar" puis remodifie en "foo" avant
     * la fin du debounce, la modification est annulée donc onChange ne sera pas appelé mais la callback "onCancel"
     * retournée par onDirectChange sera appelée pour vous permettre d'annuler votre loader par exemple.
     */
    onDirectChange?: (value: any) => OnCancel | void;
}

/**
 * Permet de ne recevoir les notifications de changement d'un input que si il n'a pas été touché depuis 400ms.
 * Aucun event n'est émi si on touche l'input mais qu'on ne change pas sa valeur.
 * Équivaut à debounce + distinct de rxjs :
 * https://www.learnrxjs.io/learn-rxjs/operators/filtering/debounce
 * https://www.learnrxjs.io/learn-rxjs/operators/filtering/distinct
 *
 * exemple :
 * Dans l'exemple suivant onChange sera appellé avec le dernier event de l'input quand il n'aura pas été touché depuis 400ms
 * const [directValue, handleChange] = useDebounce(value, onChange);
 * return <input value={directValue} onChange={handleChange} />;
 */
const useDebounce = (
    value: any,
    onChange?: (event: Event) => void,
    options?: DebounceOptions
): [any, (event: Event) => void] => {
    const { debounceTime = 400, onDirectChange } = options || {};

    const previousValueRef = useRef<any>(value);
    const [content, setContent] = useState<UseDebounceState>({
        value,
        event: null,
    });
    const handleEvent = useCallback(
        (event: Event) => {
            setContent({
                value: event.target.value,
                event,
            });
        },
        [setContent]
    );

    useEffect(() => {
        // garde l'input controllé par le parent
        previousValueRef.current = value;
        setContent({ value, event: null });
    }, [value, setContent]);

    const onCancelRef = useRef<OnCancel | undefined>();
    useEffect(() => {
        let timeout: NodeJS.Timeout;

        // Si le dernier event à une valeur différente de la dernière valeur remontée
        // on lance un timer après lequel on remonte la nouvelle valeur de l'event.
        // On annulera le timeout (dans le return) si on reçoit un autre event avant sa fin.
        if (content.event && content.value !== previousValueRef.current) {
            if (onDirectChange) {
                onCancelRef.current =
                    onDirectChange(content.value) || undefined;
            }
            timeout = setTimeout(() => {
                if (onChange && content.event) {
                    // on sauvegarde la valeur transmise dans une ref car le composant parent
                    // n'envoit pas forcément la valeur dans le parametre value
                    previousValueRef.current = content.value;
                    onChange(content.event);
                }
            }, debounceTime);
        } else if (onCancelRef.current) {
            onCancelRef.current();
            onCancelRef.current = undefined;
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [content, onChange, debounceTime, onDirectChange]);

    return [content.value, handleEvent];
};

export default useDebounce;
