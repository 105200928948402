import { Box, SxProps } from '@mui/material';
import React from 'react';

export interface WysiwygValueProps {
    value: string;

    /**
     * Le style défini ici sert de style parent pour le html du wysiwyg.
     * Il faut donc y mettre les mêmes valeurs par défaut que prévu dans le wysiwyg qui a servi à générer la valeur.
     */
    sx: SxProps;
}

/**
 * Rend la valeur définie depuis un Wysiwyg.
 * Ajoute un wrapper avec le même style par défaut que prévu dans le wysiwyg.
 * Permet en particulier d'avoir la même font par défaut que dans le wysiwyg.
 */
function WysiwygValue({ value, sx }: WysiwygValueProps) {
    return (
        <Box
            sx={sx}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
                __html: value,
            }}
        />
    );
}

export default WysiwygValue;
