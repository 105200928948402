import { Box, SxProps } from '@mui/material';
import React from 'react';
import LocalStorageKeys from '../../apollo/LocalStorageKeys';
import UserDataStorageKeys from '../../security/UserDataStorageKeys';
import UserResponse from '../user/UserResponse';
import ConnexionCasPage from './ConnexionCasPage';
import ConnexionPage from './ConnexionPage';

interface MultiAuthProps {
    enableCasAuthentication: boolean;
    validationUrl: string;
    casEntrypoint: string;
    isAllowed?: (user: UserResponse) => boolean;
    authStorageKeys: LocalStorageKeys;
    userDataStorageKeys: UserDataStorageKeys;
    sx?: SxProps;
}

function MultiAuth({
    enableCasAuthentication,
    isAllowed,
    validationUrl,
    casEntrypoint,
    authStorageKeys,
    userDataStorageKeys,
    sx,
}: MultiAuthProps) {
    return (
        <Box>
            {enableCasAuthentication ? (
                <ConnexionCasPage
                    validationUrl={validationUrl}
                    casEntrypoint={casEntrypoint}
                />
            ) : (
                <ConnexionPage
                    isAllowed={isAllowed}
                    authStorageKeys={authStorageKeys}
                    userDataStorageKeys={userDataStorageKeys}
                    sx={sx}
                />
            )}
        </Box>
    );
}

export default MultiAuth;
