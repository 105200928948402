import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import DialogButton from '../../../ui/DialogButton';
import NotificationForm, { NotificationFormProps } from './NotificationForm';

type NotificationFormDialogButtonProps = Pick<
    NotificationFormProps,
    'value' | 'onSuccess'
> & {
    id: string;
    children: React.ReactNode;
    buttonComponent?: React.ComponentType<{ onClick: () => void }>;
    buttonProps?: any;
};

function NotificationFormDialogButton({
    id,
    children,
    value,
    onSuccess,
    buttonComponent,
    buttonProps,
}: NotificationFormDialogButtonProps) {
    const dialogProps = useMemo<any>(
        () => ({
            id,
            mainTitle: value?.id
                ? 'Modifier une notification'
                : 'Créer une notification',
            maxWidth: 'xs',
            // [Wysiwyg] Sans cette option les champs inputs dans la modal des liens / images ne marche
            disableEnforceFocus: true,
            sx: { zIndex: '1200' },
        }),
        [id, value?.id]
    );

    return (
        <DialogButton
            label={children}
            buttonComponent={buttonComponent}
            buttonProps={{
                variant: 'contained',
                'data-testid': 'NotificationFormDialogButton',
                ...buttonProps,
            }}
            dialogProps={dialogProps}
        >
            {(close) => (
                <Box pt={2}>
                    <NotificationForm
                        value={value}
                        onSuccess={(res) => {
                            close();
                            onSuccess && onSuccess(res);
                        }}
                    />
                </Box>
            )}
        </DialogButton>
    );
}

export default NotificationFormDialogButton;
