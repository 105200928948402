import ChapterContent from '@acdc/shared/src/features/chapterContent/ChapterContent.model';
import ChapterContentResponse from '@acdc/shared/src/features/chapterContent/ChapterContentResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import { useCallback } from 'react';
import MutateChapterContentResponse from '@acdc/shared/src/features/chapterContent/MutateChapterContentResponse';
import { getSingleErrorMessage } from '@acdc/shared/src/utils/error-helpers';

export const CREATE_CHAPTER_CONTENT = gql`
    mutation CreateChapterContent($input: createChapterContentInput!) {
        createChapterContent(input: $input) {
            chapterContent {
                id
            }
        }
    }
`;

export const UPDATE_CHAPTER_CONTENT = gql`
    mutation UpdateChapterContent($input: updateChapterContentInput!) {
        updateChapterContent(input: $input) {
            chapterContent {
                id
            }
        }
    }
`;

function useMutateChapterContent(
    onMutated?: (
        response: ChapterContentResponse,
        forwardedValues: DeepPartial<ChapterContent>
    ) => void
) {
    const setAlert = useAlert();

    const [create] = useMutation(CREATE_CHAPTER_CONTENT, {
        update(cache) {
            cache.evict({ fieldName: 'chapterContents' });
        },
    });
    const [update] = useMutation(UPDATE_CHAPTER_CONTENT, {
        update(cache) {
            cache.evict({ fieldName: 'chapterContents' });
        },
    });

    return useCallback(
        (values: DeepPartial<ChapterContent>) => {
            const { id, position, content, chapter } = values;
            const input: DeepPartial<ChapterContent> = {
                position,
                content: entityToId(content) || undefined,
                chapter: entityToId(chapter) || undefined,
            };
            if (id) {
                input.id = id;
            }

            const mutate = id ? update : create;
            mutate({ variables: { input } })
                .then((res: FetchResult<MutateChapterContentResponse>) => {
                    const resItem: ChapterContentResponse | null | undefined =
                        input.id
                            ? res.data?.updateChapterContent?.chapterContent
                            : res.data?.createChapterContent?.chapterContent;

                    if (!resItem?.id) {
                        // eslint-disable-next-line no-console
                        console.error('Missing data result', res.data);
                        return;
                    }

                    onMutated && onMutated(resItem, values);
                })
                .catch((err: ApolloError) => {
                    setAlert(getSingleErrorMessage(err), 'error');
                });
        },
        [create, update, onMutated, setAlert]
    );
}

export default useMutateChapterContent;
