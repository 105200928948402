import React from 'react';
import { Drawer, List, SxProps, Button, Box, Theme } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link } from 'react-router-dom';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import HandymanIcon from '@mui/icons-material/Handyman';
import SideBarListItemLink from './SideBarListItemLink';

interface SideBarProps {
    open: boolean;
    toggleDrawer: () => void;
}

const iconsSx: SxProps = {
    color: (theme: any) => theme.palette.common.white,
};

function SideBar({ open, toggleDrawer }: SideBarProps) {
    return (
        <Drawer
            id="sideBar"
            anchor="right"
            open={open}
            ModalProps={{ onBackdropClick: toggleDrawer }}
            sx={{
                height: '100vh',
                flexDirection: 'column',
                '& .MuiPaper-root': {
                    backgroundColor: 'background.default',
                },
            }}
        >
            <Box
                sx={{
                    marginTop: (theme: Theme) => theme.spacing(2),
                    marginBottom: (theme: Theme) => theme.spacing(1),
                    justifyContent: 'center',
                    width: '100%',
                    display: 'flex',
                }}
            >
                <img
                    src="/images/logo-laforet-white.png"
                    alt="Logo LaForêt"
                    data-testid="logoLaForet"
                    style={{
                        width: 'auto',
                        height: '40px',
                    }}
                />
            </Box>
            <List sx={{ flex: 1 }}>
                <SideBarListItemLink
                    to="/pages"
                    icon={<ArticleIcon sx={iconsSx} />}
                >
                    Gérer les pages
                </SideBarListItemLink>
                <SideBarListItemLink
                    to="/deroules"
                    icon={<SlideshowIcon sx={iconsSx} />}
                >
                    Gérer les déroulés
                </SideBarListItemLink>
                <SideBarListItemLink
                    to="/outils"
                    icon={<HandymanIcon sx={iconsSx} />}
                >
                    Gérer les outils
                </SideBarListItemLink>
                <SideBarListItemLink
                    to="/notifications"
                    icon={<NotificationsIcon sx={iconsSx} />}
                >
                    Gérer les notifications
                </SideBarListItemLink>
                <SideBarListItemLink
                    to="/extraire-statistiques"
                    icon={<TrendingUpIcon sx={iconsSx} />}
                >
                    Extraire les statistiques
                </SideBarListItemLink>
            </List>

            <Box
                sx={{
                    marginBottom: (theme: Theme) => theme.spacing(1),
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                <Button
                    data-testid="disconnectionButton"
                    variant="contained"
                    component={Link}
                    to="/deconnexion"
                >
                    Déconnexion
                </Button>
            </Box>
        </Drawer>
    );
}

export default SideBar;
