import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import { Box, Grid } from '@mui/material';
import React from 'react';
import useContents from './useContents';
import ContentsListItem from './ContentsListItem';

function ContentsList() {
    const { data: contentsResponse } = useContents();
    const contents = contentsResponse?.contents?.collection || null;

    return (
        <Box px={2}>
            <Grid container spacing={2} columns={15}>
                {contents?.map((content: ContentResponse) => (
                    <Grid
                        item
                        sm={5}
                        md={3}
                        key={content.id}
                        height="200px"
                        position="relative"
                    >
                        <ContentsListItem content={content} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ContentsList;
