import ListTemplatesResponse from '@acdc/shared/src/features/template/ListTemplatesResponse';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

export const GET_TEMPLATES = gql`
    query GetTemplates {
        templates(order: [{ title: "ASC" }], itemsPerPage: 10000) {
            collection {
                id
                title
                templateImage {
                    id
                    publicPath
                }
            }
        }
    }
`;

const useQueryTemplatesToPick = () => {
    const setAlert = useAlert();
    const { data, error, loading } = useQuery<ListTemplatesResponse>(
        GET_TEMPLATES
        // pas besoin de fetchPolicy: 'network-only',
    );

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des templates.',
                'error'
            );
        }
    }, [error, setAlert]);

    return { data, error, loading };
};

export default useQueryTemplatesToPick;
