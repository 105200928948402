import React, { useEffect, useCallback, useMemo } from 'react';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { gql, useLazyQuery } from '@apollo/client';
import useDebounce from '@acdc/shared/src/utils/useDebounce';
import Autocomplete, {
    AutocompleteProps,
} from '@acdc/shared/src/formik/Autocomplete';
import ListAgenciesResponse from '@acdc/shared/src/features/agency/ListAgenciesResponse';

export const GET_AGENCIES = gql`
    query SearchAgencies($search: String!) {
        agencies(
            itemsPerPage: 100
            order: [{ name: "ASC" }, { code: "ASC" }]
            search: $search
            searchOn: ["code", "name"]
        ) {
            collection {
                id
                code
                name
            }
        }
    }
`;

function AgencySelect({ ...props }: AutocompleteProps) {
    const setAlert = useAlert();
    const [query, { loading, data, error, called }] =
        useLazyQuery<ListAgenciesResponse>(GET_AGENCIES, {
            fetchPolicy: 'network-only',
        });
    const options = useMemo(() => data?.agencies?.collection || [], [data]);
    const loadOptions = useCallback(
        (event: any) => {
            query({
                variables: {
                    search: `${event.target.value}`,
                },
            });
        },
        [query]
    );
    const [, onDirectValueChange] = useDebounce('', loadOptions);

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des agences.',
                'error'
            );
        }
    }, [error, setAlert]);

    return (
        <Autocomplete
            {...props}
            options={options}
            disableClearable
            getOptionLabel={(option) => `${option.name} - ${option.code}`}
            loading={loading}
            isOptionEqualToValue={matchOnId}
            filterOptions={(x) => x}
            onInputChange={(event: any) => {
                if (event?.target?.value) {
                    onDirectValueChange(event);
                }
            }}
            noOptionsText={
                called ? 'Pas de résultats' : 'Saisissez votre recherche'
            }
        />
    );
}

export default AgencySelect;
