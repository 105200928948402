import React from 'react';
import { Formik, Field, Form, FormikHelpers, ErrorMessage } from 'formik';
import Yup from '@acdc/shared/src/yup/yupFr';
import SubmitButton from '@acdc/shared/src/formik/SubmitButton';
import { useAlert } from '@acdc/shared/src/tools/alert';
import { useMutation, gql, FetchResult, ApolloError } from '@apollo/client';
import MutateNotificationResponse from '@acdc/shared/src/features/notification/MutateNotificationResponse';
import { handleApolloError } from '@acdc/shared/src/utils/error-helpers';
import { Box, Stack } from '@mui/material';
import { TextField } from 'formik-mui';
import FormSkeleton from '@acdc/shared/src/ui/FormSkeleton';
import NotificationResponse from '@acdc/shared/src/features/notification/NotificationResponse';
import NotificationModel from '@acdc/shared/src/features/notification/Notification.model';
import NotificationStatusResponse from '@acdc/shared/src/features/notificationStatus/NotificationStatusResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import ErrorHelperText from '@acdc/shared/src/ui/ErrorHelperText';
import useInitialValues from './useInitialValues';
import NotificationStatusSelect from './NotificationStatusSelect';
import FormikWysiwyg from '../../../ui/FormikWysiwyg';

export const CREATE_NOTIFICATION = gql`
    mutation CreateNotification($input: createNotificationInput!) {
        createNotification(input: $input) {
            notification {
                id
            }
        }
    }
`;

export const UPDATE_NOTIFICATION = gql`
    mutation UpdateNotification($input: updateNotificationInput!) {
        updateNotification(input: $input) {
            notification {
                id
            }
        }
    }
`;

export interface NotificationFormValue {
    title: string;
    description: string;
    notificationStatus: string | NotificationStatusResponse | null;
}

const notificationFormSchema = Yup.object().shape({
    title: Yup.string().label('Titre').required(),
    description: Yup.string().label('Description').required(),
    notificationStatus: Yup.mixed()
        .label('Priorité de la notification')
        .required(),
});

export interface NotificationFormProps {
    value?: DeepPartial<NotificationModel> | undefined;
    onSuccess?: ((res: NotificationResponse) => void) | undefined;
    onError?: ((err: ApolloError) => void) | undefined;
}

/**
 * Formulaire d'ajout / modification d'une notification.
 * Si la valeur fournie a un id la notification est chargé depuis l'api avant de rendre le formulaire.
 */
function NotificationForm({
    value,
    onSuccess,
    onError,
}: NotificationFormProps) {
    const isUpdate: boolean = !!value?.id;
    const initialValues = useInitialValues(value);

    const setAlert = useAlert();

    const [mutate] = useMutation(
        isUpdate ? UPDATE_NOTIFICATION : CREATE_NOTIFICATION,
        {
            update(cache) {
                cache.evict({ fieldName: 'notifications' });
            },
        }
    );

    if (!initialValues) {
        return <FormSkeleton nbInputs={3} />;
    }

    return (
        <Formik
            validationSchema={notificationFormSchema}
            initialValues={initialValues}
            onSubmit={(
                values: NotificationFormValue,
                {
                    setSubmitting,
                    setErrors,
                }: FormikHelpers<NotificationFormValue>
            ) => {
                const input: DeepPartial<NotificationModel> = {
                    ...values,
                    notificationStatus:
                        entityToId(values.notificationStatus) || undefined,
                };

                if (isUpdate) {
                    input.id = value?.id;
                }

                mutate({ variables: { input } })
                    .finally(() => {
                        setSubmitting(false);
                    })
                    .then((res: FetchResult<MutateNotificationResponse>) => {
                        const resItem: NotificationResponse | null | undefined =
                            isUpdate
                                ? res.data?.updateNotification?.notification
                                : res.data?.createNotification?.notification;

                        if (!resItem?.id) {
                            // eslint-disable-next-line no-console
                            console.error('Missing data result', res.data);
                            return;
                        }

                        onSuccess && onSuccess(resItem);
                    })
                    .catch(
                        handleApolloError(
                            setErrors,
                            setAlert,
                            notificationFormSchema,
                            onError
                        )
                    );
            }}
        >
            <Form data-testid="notificationForm">
                <Stack direction="column" spacing={2}>
                    <Field
                        component={TextField}
                        inputProps={{ 'data-testid': 'title' }}
                        id="title"
                        label="Titre"
                        name="title"
                        required
                    />
                    <Field
                        component={FormikWysiwyg}
                        inputProps={{ 'data-testid': 'description' }}
                        id="description"
                        label="Description"
                        name="description"
                        required
                    />
                    <ErrorMessage
                        name="description"
                        component={ErrorHelperText}
                    />
                    <Field
                        component={NotificationStatusSelect}
                        id="notificationStatusSelect"
                        name="notificationStatus"
                        label="Priorité de la notification"
                        required
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <SubmitButton
                            buttonProps={{
                                'data-testid': 'notificationFormSubmitButton',
                                sx: { width: '396px' },
                            }}
                        />
                    </Box>
                </Stack>
            </Form>
        </Formik>
    );
}

export default NotificationForm;
