/**
 * SharedFile correspond a un SharedFile
 * ImageOverrideFile correspond a un ImageOverrideFile
 * file correspond a un fichier ajouté dans un input type File (fichier pas encore upload)
 */

enum ModuleFile {
    SHARED_FILE = 'sharedFile',
    IMAGE_OVERRIDE_FILE = 'imageOverrideFile',
    FILE = 'file',
}

export default ModuleFile;
