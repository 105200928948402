import { usePermissions } from './PermissionsProvider';

/**
 * Retourne true si l'utilisateur est authentifié.
 * Attention en cas d'authentification ou de désauthentification ce hook ne trigger
 * pas de re-rendu du composant où il est utilisé.
 */
const useIsAuthenticated = () => {
    const { isAuthenticated } = usePermissions();

    return isAuthenticated();
};

export default useIsAuthenticated;
