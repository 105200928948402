export const displayCurrency = (
    amount: number,
    ignoreEmptyDecimals: boolean = false
) => {
    const moreOptions: any = {};
    if (ignoreEmptyDecimals && amount % 1 === 0) {
        // Si amount est entier, on ne veut pas de décimales.
        // On ne met pas cette option si amount est un nombre à virgule car on ne veut pas que toLocaleString affiche 1 décimale.
        moreOptions.minimumFractionDigits = 0;
    }
    return amount.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        ...moreOptions,
    });
};

// see https://gist.github.com/codeguy/6684588
export const slugify = (text: string) =>
    text
        .toString() // Cast to string (optional)
        .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/-$/g, ''); // Remove trailing - if any

/**
 * @see https://stackoverflow.com/a/1026087
 */
export function capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * @see https://stackoverflow.com/a/37511463
 */
export function unaccent(text: string): string {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
