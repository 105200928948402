import { ApolloError } from '@apollo/client';
import { FormikErrors } from 'formik';
import { GraphQLError } from 'graphql';
import { AnyObjectSchema } from 'yup';
import { SetAlert } from '../tools/alert';

export interface Violation {
    path: string;
    message: string;
}

export interface FieldsErrors {
    /**
     * La valeur étant les messages des violations concaténées.
     */
    [field: string]: string;
}

/**
 * Retourne les violations d'un erreur graphql. Les violations étant une extension de l'erreur.
 */
export const getViolations = (err: ApolloError): Violation[] => {
    if (!err.graphQLErrors || !err.graphQLErrors.length) {
        return [];
    }

    return err.graphQLErrors.reduce(
        (curr: Violation[], graphqlError: GraphQLError) => {
            // normalement on a que 1 graphqlError avec des violations donc on passe ici 1 fois

            if (
                !(graphqlError.extensions?.violations as any[] | undefined)
                    ?.length
            ) {
                return curr;
            }

            return [
                ...curr,
                ...(graphqlError.extensions.violations as Violation[]),
            ];
        },
        []
    );
};

/**
 * Groupe par concaténation les violations d'une erreur graphql par champ (champ = "path" de la violation).
 */
export const getFieldsErrors = (err: ApolloError): FieldsErrors | null => {
    const violations: Violation[] = getViolations(err);

    if (!violations.length) {
        return null;
    }

    return violations.reduce((curr: FieldsErrors, violation: Violation) => {
        if (curr[violation.path]) {
            // eslint-disable-next-line no-param-reassign
            curr[violation.path] += ` ${violation.message}`;
        } else {
            // eslint-disable-next-line no-param-reassign
            curr[violation.path] = violation.message;
        }

        return curr;
    }, {});
};

/**
 * Retourne les FieldsErrors des champs qui ne sont pas présent dans un schéma Yup.
 * @see getFieldsErrors
 */
export const getFieldsErrorsMissingFromForm = (
    errors: FieldsErrors,
    schema: AnyObjectSchema
): FieldsErrors | null => {
    const formFields: string[] = Object.getOwnPropertyNames(schema.fields);
    let notFound: boolean = true;

    const res: FieldsErrors = Object.getOwnPropertyNames(errors).reduce(
        (curr: FieldsErrors, prop: string) => {
            if (!formFields.includes(prop)) {
                notFound = false;
                // eslint-disable-next-line no-param-reassign
                curr[prop] = errors[prop];
            }

            return curr;
        },
        {}
    );

    return notFound ? null : res;
};

export const concatFieldsErrors = (errors: FieldsErrors): string => {
    return Object.values(errors).join(' ');
};

/**
 * Extrait un unique message d'erreur pertinent à partir d'une erreur graphql.
 */
export const getSingleErrorMessage = (
    err: ApolloError,
    defaultMessage: string = 'Une erreur est survenue.'
): string => {
    if (!err.graphQLErrors) {
        // pas une erreur Apollo en fait
        return defaultMessage;
    }

    const fieldsErrors: FieldsErrors | null = getFieldsErrors(err);
    if (fieldsErrors) {
        return concatFieldsErrors(fieldsErrors);
    }

    return defaultMessage;
};

/**
 * Gère une erreur graphql reçue lors de la soumission d'un formulaire Formik classique.
 * @param setErrors Fonction de Formik pour définir les erreurs des champs du formulaire
 * @param setAlert Fonction pour définir des erreurs à afficher globalement.
 * @param yupSchema Schéma du formulaire à utiliser pour faire le trie entre les erreurs à afficher sur les champs et celles à afficher globalement.
 * @param onErrorProp Callback appellée avec l'erreur. Peut être définie avec une prop onError du formulaire pour faire remonter l'erreur au composant parent.
 * @param overrideFormikKey Une clé à laquelle assigner toutes les violations à la place de la clé retournée par l'api.
 * @return Une fonction à passer au catch de la mutation Apollo.
 */
export const handleApolloError =
    (
        setErrors: (errors: FormikErrors<any>) => void,
        setAlert: SetAlert,
        yupSchema: AnyObjectSchema,
        onErrorProp?: (err: ApolloError) => void,
        overrideFormikKey?: string
    ) =>
    (err: ApolloError): void => {
        if (!err.graphQLErrors) {
            // pas une erreur Apollo en fait
            throw err;
        }

        const fieldsErrors: FieldsErrors | null = getFieldsErrors(err);
        if (fieldsErrors) {
            let fieldsErrorsToShow: FieldsErrors = fieldsErrors;
            if (overrideFormikKey) {
                fieldsErrorsToShow = {
                    [overrideFormikKey]: concatFieldsErrors(fieldsErrors),
                };
            }
            setErrors(fieldsErrorsToShow);

            const missingFieldsErrors = getFieldsErrorsMissingFromForm(
                fieldsErrorsToShow,
                yupSchema
            );
            if (missingFieldsErrors) {
                setAlert(
                    concatFieldsErrors(missingFieldsErrors) ||
                        'Une erreur est survenue.',
                    'error'
                );
            }
        } else {
            setAlert('Une erreur est survenue.', 'error');
        }

        if (onErrorProp) {
            onErrorProp(err);
        }
    };

export const concatViolations = (
    err: ApolloError,
    defaultMessage: string = 'Une erreur est survenue.'
) => {
    const fieldsErrors = getFieldsErrors(err);
    if (!fieldsErrors) {
        return defaultMessage;
    }

    return concatFieldsErrors(fieldsErrors) || defaultMessage;
};
