import { AlertColor } from '@mui/material';
import React, { useContext, useState, useCallback } from 'react';
import Alert from './Alert';
import AlertSnackBar from './AlertSnackBar';

export type SetAlert = (message: string, severity: AlertColor) => void;
export interface AlertOptions {
    onlyError?: Boolean;
}

export type SetValue<T> = (value: T | ((curr: T) => T)) => void;

const initialAlertCtxValues: SetAlert = () => {};
const initialAlertOptionsCtxValues: [AlertOptions, SetValue<AlertOptions>] = [
    {},
    () => {},
];

export const AlertCtx = React.createContext<SetAlert>(initialAlertCtxValues);
export const AlertOptionsCtx = React.createContext<
    [AlertOptions, SetValue<AlertOptions>]
>(initialAlertOptionsCtxValues);

export const useAlert = () => useContext(AlertCtx);
export const useAlertOptions = () => useContext(AlertOptionsCtx);

interface AlertProviderProps {
    children?: React.ReactNode;
}

function AlertProvider({ children }: AlertProviderProps) {
    const [alert, setAlert] = useState<Alert>();
    const optionsState = useState<AlertOptions>({});
    const [alertOptions] = optionsState;

    const setEasyAlert = useCallback(
        (message: string, severity: AlertColor = 'info') => {
            if (
                alertOptions &&
                alertOptions.onlyError &&
                severity !== 'error'
            ) {
                return;
            }
            setAlert({ message, severity });
        },
        [setAlert, alertOptions]
    );

    return (
        <AlertOptionsCtx.Provider value={optionsState}>
            <AlertCtx.Provider value={setEasyAlert}>
                <AlertSnackBar duration={5000} alert={alert} />
                {children}
            </AlertCtx.Provider>
        </AlertOptionsCtx.Provider>
    );
}

export default AlertProvider;
