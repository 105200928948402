import { useApolloClient } from '@apollo/client';
import { Box, Button } from '@mui/material';
import React, { useEffect } from 'react';
import { Link, To } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import * as Sentry from '@sentry/react';
import AuthLayout, { AuthLayoutProps } from './AuthLayout';

export interface LogoutPageProps {
    loginUrl: To;
    casEntrypoint: string;
    sx?: AuthLayoutProps['sx'];
    onLoggedOut?: (() => void) | undefined;
}

function LogoutPage({
    loginUrl,
    casEntrypoint,
    sx,
    onLoggedOut,
}: LogoutPageProps) {
    const apollo = useApolloClient();

    useEffect(() => {
        window.localStorage.clear();
        apollo.clearStore();
        Sentry.setUser(null);
        onLoggedOut && onLoggedOut();
    }, [apollo, onLoggedOut]);

    return (
        <AuthLayout title="Vous êtes déconnecté" sx={sx}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={6}
            >
                <Button
                    data-testid="LoginButton"
                    component={Link}
                    to={loginUrl}
                    variant="contained"
                    startIcon={<LoginIcon />}
                >
                    Se connecter
                </Button>
                <Button
                    component="a"
                    href={`${casEntrypoint}/logout`}
                    sx={{ mt: 4 }}
                    startIcon={<HighlightOffRoundedIcon />}
                >
                    Se déconnecter globalement de Laforêt
                </Button>
            </Box>
        </AuthLayout>
    );
}

export default LogoutPage;
