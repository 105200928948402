import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import SectionResponse from '@acdc/shared/src/features/section/SectionResponse';
import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import SectionConfigurator, {
    SectionConfiguratorProps,
} from './SectionConfigurator';

export interface ContentTemplateConfiguratorProps
    extends Pick<SectionConfiguratorProps, 'onSelectContentModule'> {
    content: ContentResponse;
    onContentChange: React.Dispatch<
        React.SetStateAction<ContentResponse | null>
    >;
    onUnselectContentModule: (
        contentModule: string | ContentModuleResponse
    ) => void;
}

/**
 * Configurateur du Template d'un Content.
 * Permet de créer des ContentModules en droppant des composants DraggableModule (de DraggableModulesList) dans les sections du template.
 * Permet aussi de sélectionner ou supprimer les ContentModules existants.
 */
function ContentTemplateConfigurator({
    content,
    onSelectContentModule,
    onUnselectContentModule,
    onContentChange,
}: ContentTemplateConfiguratorProps) {
    const handleAddContentModule = useCallback(
        (newContentModule: ContentModuleResponse) => {
            onContentChange((curr: ContentResponse | null): ContentResponse => {
                return {
                    ...curr,
                    contentModules: {
                        collection: [
                            ...(curr?.contentModules?.collection || []),
                            newContentModule,
                        ],
                    },
                };
            });
        },
        [onContentChange]
    );
    const handleRemoveContentModule = useCallback(
        (removedContentModuleId: string) => {
            onUnselectContentModule(removedContentModuleId);
            onContentChange((curr: ContentResponse | null): ContentResponse => {
                return {
                    ...curr,
                    contentModules: {
                        collection: (
                            curr?.contentModules?.collection || []
                        ).filter(
                            (cm: ContentModuleResponse) =>
                                !matchOnId(cm, removedContentModuleId)
                        ),
                    },
                };
            });
        },
        [onContentChange, onUnselectContentModule]
    );

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', height: '100%' }}>
            {content.template?.sections?.collection
                .slice()
                .sort((a: SectionResponse, b: SectionResponse) => {
                    return a.col && b.col && a.col > b.col ? 1 : -1;
                })
                .map((section: SectionResponse) => (
                    <SectionConfigurator
                        key={section.id}
                        content={content}
                        section={section}
                        contentModule={content.contentModules?.collection.find(
                            (contentModule) =>
                                matchOnId(contentModule.section, section)
                        )}
                        onAddContentModule={handleAddContentModule}
                        onRemoveContentModule={handleRemoveContentModule}
                        onSelectContentModule={onSelectContentModule}
                    />
                ))}
        </Box>
    );
}

export default ContentTemplateConfigurator;
