import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Alert from './Alert';

interface AlertSnackBarProps {
    alert?: Alert;
    duration?: number;
}

function AlertSnackBar({ alert, duration }: AlertSnackBarProps) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (alert) {
            setOpen(true);
        }
    }, [alert]);

    const handleClose = () => setOpen(false);

    return (
        <Snackbar
            data-testid="snackbar"
            autoHideDuration={duration}
            open={open}
            onClose={handleClose}
        >
            <MuiAlert
                onClose={handleClose}
                variant="filled"
                elevation={6}
                severity={alert?.severity}
                sx={{ width: '100%' }}
            >
                {alert?.message}
            </MuiAlert>
        </Snackbar>
    );
}

export default AlertSnackBar;
