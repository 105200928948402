import { Box, SxProps } from '@mui/material';
import React from 'react';

const imgSx = {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
};

const imgWrapperSx = {
    display: 'block',
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
};

export interface FlexImageProps {
    src: string;
    alt: string;
    sx?: SxProps;
}

/**
 * Image qui s'adapte à l'espace flexible https://stackoverflow.com/a/39812443
 */
function FlexImage({ src, alt, sx }: FlexImageProps) {
    return (
        <Box
            component="span"
            sx={{ display: 'block', flex: 1, position: 'relative', ...sx }}
        >
            <Box component="span" sx={imgWrapperSx}>
                <Box component="img" src={src} alt={alt} sx={imgSx} />
            </Box>
        </Box>
    );
}

export default FlexImage;
