import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import DialogButton from '../../../ui/DialogButton';
import ToolForm, { ToolFormProps } from './ToolForm';

type ToolFormDialogButtonProps = Pick<ToolFormProps, 'value' | 'onSuccess'> & {
    id: string;
    children: React.ReactNode;
    buttonComponent?: React.ComponentType<{ onClick: () => void }>;
    buttonProps?: any;
};

function ToolFormDialogButton({
    id,
    children,
    value,
    onSuccess,
    buttonComponent,
    buttonProps,
}: ToolFormDialogButtonProps) {
    const dialogProps = useMemo<any>(
        () => ({
            id,
            mainTitle: value?.id ? 'Modifier un outil' : 'Créer un outil',
            maxWidth: 'xs',
        }),
        [id, value?.id]
    );

    return (
        <DialogButton
            label={children}
            buttonComponent={buttonComponent}
            buttonProps={{
                variant: 'contained',
                'data-testid': 'ToolFormDialogButton',
                ...buttonProps,
            }}
            dialogProps={dialogProps}
        >
            {(close) => (
                <Box pt={2}>
                    <ToolForm
                        value={value}
                        onSuccess={(res) => {
                            close();
                            onSuccess && onSuccess(res);
                        }}
                    />
                </Box>
            )}
        </DialogButton>
    );
}

export default ToolFormDialogButton;
