import React from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { Box } from '@mui/material';
import DraggableContentsListItem, {
    DROPPABLE_CONTENT,
    DROPPABLE_CONTENT_FROM_CHAPTER,
} from '../features/content/DraggableContentsList/DraggableContentsListItem';

const DragLayerSx = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 2000, // higher than the sidebars (1200)
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
};

function getDragLayerStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null
) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform,
    };
}

function DragDisplayLayer() {
    const { itemType, isDragging, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }));

    const renderItem = () => {
        switch (itemType) {
            case DROPPABLE_CONTENT:
                return <DraggableContentsListItem content={item.content} />;
            case DROPPABLE_CONTENT_FROM_CHAPTER:
                return (
                    <DraggableContentsListItem
                        content={item.content}
                        chapter={item.currentChapter}
                        enableDeleteButton
                    />
                );
            default:
                return null;
        }
    };

    if (!isDragging) {
        return null;
    }

    return (
        <Box className="draglayer" data-testid="draglayer" sx={DragLayerSx}>
            <div style={getDragLayerStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </Box>
    );
}
export default DragDisplayLayer;
