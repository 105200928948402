import React from 'react';
import BaseDialogButton, {
    DialogButtonProps,
} from '@acdc/shared/src/ui/DialogButton';
import Dialog from './Dialog';

function DialogButton(props: DialogButtonProps) {
    return <BaseDialogButton dialogComponent={Dialog} {...props} />;
}

export default DialogButton;
