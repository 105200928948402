import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useNavigate } from 'react-router-dom';
import PresentationFlowResponse from '@acdc/shared/src/features/presentationFlow/PresentationFlowResponse';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import PresentationFlowFormDialogButton from '../../features/presentationFlow/PresentationFlowForm/PresentationFlowFormDialogButton';
import PresentationFlowsList from '../../features/presentationFlow/PresentationFlowsList/PresentationFlowsList';

function PresentationFlows() {
    const navigate = useNavigate();
    const handlePresentationFlowCreated = useCallback(
        (presentationFlow: PresentationFlowResponse) => {
            navigate(
                `/deroules/${getIdFromIri(
                    entityToId(presentationFlow)
                )}/configurer`
            );
        },
        [navigate]
    );

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    mb: 4,
                    mx: 4,
                }}
            >
                <Typography variant="h2" component="h1">
                    Mes déroulés
                </Typography>
                <Box>
                    <PresentationFlowFormDialogButton
                        id="create-presentation-flow-dialog"
                        buttonProps={{ color: 'primary', variant: 'contained' }}
                        onSuccess={handlePresentationFlowCreated}
                    >
                        Créer un déroulé{' '}
                        <AddCircleOutlineIcon fontSize="small" sx={{ ml: 1 }} />
                    </PresentationFlowFormDialogButton>
                </Box>
            </Box>
            <PresentationFlowsList />
        </>
    );
}

export default PresentationFlows;
