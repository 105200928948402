import { useField, useFormikContext } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useDebounce, {
    DebounceOptions,
} from '@acdc/shared/src/utils/useDebounce';

/**
 * Permet de submit automatiquement un formulaire formik composé d'1 champ si celui ci a été modifié mais n'est plus touché pendant 400ms.
 * Si votre formulaire a plusieurs champs il faut utiliser useAutoSubmitForm(). Son seul désavantage est qu'il submit
 * tous les changements même si le changement ne fait rien (exemple: on écrit un truc et on l'efface)
 * @param {string} fieldName Le name du champ
 * @param {(() => string|null) | undefined} getEntityId Une fonction qui retourne l'id de l'entité du formulaire. Permet de savoir si le premier submit d'une nouvelle entité est passé. Sinon on l'attend pour ne pas submit plusieur fois une création d'entité.
 * @return {boolean} true si un modification n'a pas encore été submit, false si tout est à jour.
 */
function useAutoSubmitSingleFieldForm(
    fieldName: string,
    getEntityId?: () => string | null
) {
    // indique si la sauvegarde est en attente
    const [pending, setPending] = useState(false);

    // le contexte de formik
    const { submitForm, isSubmitting } = useFormikContext();
    const [{ value: fieldValue }] = useField(fieldName);

    // une ref qui indique si une submission est en attente
    const delayedSubmission = useRef(false);

    // une ref pour savoir si le formulaire est en cours de submission
    const isSubmittingRef = useRef(false);
    isSubmittingRef.current = isSubmitting;

    // submit le form si il n'est pas déjà en cours de submission pour une création, sinon prépare une submission dés que possible
    const submit = useCallback(() => {
        if (isSubmittingRef.current && getEntityId && !getEntityId()) {
            // la requete de création de SettingValue est en cours, on attend la réponse pour avoir l'id pour la modification
            delayedSubmission.current = true;
        } else {
            setPending(false);
            submitForm();
        }
    }, [submitForm, getEntityId, setPending]);

    // appelle submit() dés que le champ n'est plus touché
    const initialValue = useMemo(() => {
        return fieldValue;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const debounceOptions = useMemo<DebounceOptions>(
        () => ({
            onDirectChange: () => {
                // loading quand le timer du debounce se lance
                setPending(true);

                const onChangeCancelled = () => {
                    // annulation du loader si la modification est annulée
                    setPending(false);
                };
                return onChangeCancelled;
            },
        }),
        [setPending]
    );
    const [, debounceHandleChange] = useDebounce(
        initialValue,
        submit,
        debounceOptions
    );

    // si le form ne submit plus et qu'on avait une submission en attente on la lance
    useEffect(() => {
        if (!isSubmitting && delayedSubmission.current) {
            delayedSubmission.current = false;
            submit();
        }
    }, [isSubmitting, submit]);
    const firstCall = useRef(true);

    // si la valeur du champ change on appelle la fonction du debounce pour submit dés que la valeur ne change plus
    useEffect(() => {
        if (!firstCall.current) {
            debounceHandleChange({
                target: {
                    value: fieldValue,
                },
            });
        } else {
            firstCall.current = false;
        }
    }, [fieldValue, debounceHandleChange]);

    return pending;
}

export default useAutoSubmitSingleFieldForm;
