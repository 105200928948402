import { useEffect } from 'react';
import {
    gql,
    LazyQueryResultTuple,
    OperationVariables,
    useLazyQuery,
} from '@apollo/client';
import { useAlert } from '@acdc/shared/src/tools/alert';
import type GetNotificationResponse from '@acdc/shared/src/features/notification/GetNotificationResponse';

export const GET_NOTIFICATION_TO_UPDATE = gql`
    query GetNotificationToUpdate($id: ID!) {
        notification(id: $id) {
            id
            title
            description
            notificationStatus {
                id
                label
            }
        }
    }
`;

const useLazyNotificationToUpdate = (
    id: string | null | undefined
): LazyQueryResultTuple<GetNotificationResponse, OperationVariables> => {
    const setAlert = useAlert();
    const res = useLazyQuery<GetNotificationResponse>(
        GET_NOTIFICATION_TO_UPDATE,
        {
            variables: {
                id,
            },
            fetchPolicy: 'network-only',
        }
    );
    const error = res?.[1]?.error;

    useEffect(() => {
        if (error) {
            setAlert(
                'Une erreur est survenue lors du chargement des notifications.',
                'error'
            );
        }
    }, [error, setAlert]);

    return res;
};

export default useLazyNotificationToUpdate;
