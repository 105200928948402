import ContentResponse from '@acdc/shared/src/features/content/ContentResponse';
import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import type { DragSourceMonitor } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import DragAndDropListItem from '../../../ui/DragAndDropList/DragAndDropListItem';

export const DROPPABLE_CONTENT = 'droppable_content';
export const DROPPABLE_CONTENT_FROM_CHAPTER = 'droppable_content_from_chapter';

export interface DraggableContentsListItemProps {
    content: ContentResponse;
    enableDeleteButton?: boolean;
    onRemove?: (content: ContentResponse) => void;
    chapter?: ChapterResponse | undefined;
    disableDrag?: boolean;
}

export interface DragProps {
    isDragging: boolean;
}

export interface DropProps {
    isOver: boolean;
}

function DraggableContentsListItem({
    chapter,
    content,
    enableDeleteButton,
    onRemove,
    disableDrag,
}: DraggableContentsListItemProps) {
    const [{ isDragging }, drag, preview] = useDrag<
        {
            content: ContentResponse;
            currentChapter: ChapterResponse | undefined;
        },
        void,
        DragProps
    >(
        () => ({
            type: enableDeleteButton
                ? DROPPABLE_CONTENT_FROM_CHAPTER
                : DROPPABLE_CONTENT,
            item: { content, currentChapter: chapter },
            collect: (monitor: DragSourceMonitor) => ({
                isDragging: monitor.isDragging(),
            }),
            canDrag: () => !disableDrag,
        }),
        [content, chapter, disableDrag]
    );

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    return (
        <DragAndDropListItem
            ref={drag}
            label={content.title || ''}
            onDelete={() => onRemove && onRemove(content)}
            data-testid="DraggableContentsListItemRoot"
            isDragging={isDragging}
            enableDeleteButton={enableDeleteButton}
        />
    );
}

export default DraggableContentsListItem;
