import { Form, Formik } from 'formik';
import React, { useMemo, useRef, useCallback, useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
    entityToId,
    initBool,
    initNumber,
    initString,
} from '@acdc/shared/src/utils/form-helpers';
import Yup from '@acdc/shared/src/yup/yupFr';
import ContentModuleResponse from '@acdc/shared/src/features/contentModule/ContentModuleResponse';
import VariableResponse from '@acdc/shared/src/features/variable/VariableResponse';
import Variable from '@acdc/shared/src/features/variable/Variable.model';
import VariableType from '@acdc/shared/src/features/variable/VariableType.enum';
import useSubmitVariable from './useSubmitVariable';
import VariableFormInner, { VariableFormInnerProps } from './VariableFormInner';

export interface VariableFormValue {
    label: string;
    type: string;
    isTextMulti: boolean;
    maxChar: number | string;
    maxLine: number | string;
    defaultValue: string;
    propertyField: string;
}

const initValue = (variable: VariableResponse): VariableFormValue => ({
    label: initString(variable.label),
    type: initString(variable.type || VariableType.TEXT),
    isTextMulti: initBool(variable.isTextMulti, false),
    maxChar: initNumber(variable.maxChar),
    maxLine: initNumber(variable.maxLine),
    defaultValue: initString(variable.defaultValue),
    propertyField: initString(variable.propertyField),
});

export const variableFormSchema = Yup.object().shape({
    label: Yup.string().label('Le libellé').required(),
    type: Yup.string().label('Le type').required(),
    isTextMulti: Yup.bool().label('Le champ multiligne').optional(),
    maxChar: Yup.number().label('Le nombre max de caractères').optional(),
    maxLine: Yup.number().label('Le nombre max de lignes').optional(),
    defaultValue: Yup.string()
        .label('La valeur par défaut')
        .optional()
        .test(
            'maxRows',
            ({ label }) => `${label} a trop de lignes.`,
            (value, { parent: { type, isTextMulti, maxLine } }) => {
                if (type === VariableType.TEXT && isTextMulti && maxLine) {
                    const lines = (value?.match(/\n/g) || []).length + 1;
                    return lines <= maxLine;
                }

                return true;
            }
        ),
    propertyField: Yup.string()
        .label("L'info de X")
        .optional()
        .test(
            'requiredIfTypeField',
            ({ label }) => `"${label}" est requise avec le type "info de X".`,
            (value, { parent: { type } }) => {
                if (
                    ![
                        VariableType.PROPERTY_FIELD,
                        VariableType.AGENCY_FIELD,
                        VariableType.USER_FIELD,
                    ].includes(type)
                ) {
                    return true;
                }

                return Boolean(value);
            }
        ),
});

export type VariableFormProps = {
    value: VariableResponse;
    contentModule: ContentModuleResponse;
    onSuccess?: (res: VariableResponse) => void;
    onError?: (err: ApolloError) => void;
    onDeleted?: VariableFormInnerProps['onDeleted'];
    enableDeleteButton?: VariableFormInnerProps['enableDeleteButton'];
};

/**
 * Formulaire pour créer ou modifier une Variable.
 * Le form se sauvegarde automatiquement en cas de modification.
 */
function VariableForm({
    value,
    contentModule,
    onSuccess,
    onError,
    onDeleted,
    enableDeleteButton,
}: VariableFormProps) {
    const variableIdRef = useRef(value?.id);
    const getVariableId = useCallback(() => variableIdRef.current || null, []);
    const setVariableId = useCallback((id: string) => {
        variableIdRef.current = id;
    }, []);

    const initialValues: VariableFormValue = useMemo(
        () => initValue(value || {}),
        [value]
    );

    const [, setSaved] = useState(Boolean(value?.id));

    // les propriétés qu'il faut envoyer à l'api mais qui ne font pas parti du formulaire
    const fixedProperties = useMemo<DeepPartial<Variable>>(
        () => ({
            contentModule: entityToId(contentModule)!,
            code: value?.code,
        }),
        [contentModule, value?.code]
    );

    const submit = useSubmitVariable(
        getVariableId,
        setVariableId,
        setSaved,
        variableFormSchema,
        onSuccess,
        onError,
        fixedProperties
    );

    return (
        <Formik
            validationSchema={variableFormSchema}
            initialValues={initialValues}
            onSubmit={submit}
        >
            <Form>
                <VariableFormInner
                    getVariableId={getVariableId}
                    initialValue={value}
                    onDeleted={onDeleted}
                    enableDeleteButton={enableDeleteButton}
                />
            </Form>
        </Formik>
    );
}

export default VariableForm;
