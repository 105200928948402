import { ApolloError } from '@apollo/client';
import { formatISO } from 'date-fns';

export const isBoolDefined = (val: boolean | null | undefined): boolean => {
    return val === true || val === false;
};

export const initBool = (
    val: boolean | null | undefined,
    defaultVal: boolean = true
): boolean => {
    return isBoolDefined(val) ? (val as boolean) : defaultVal;
};

export const boolToString = (val: boolean | null | undefined): string => {
    if (val === false) return 'false';
    if (val === true) return 'true';

    return '';
};

export const stringToBool = (val: string): boolean | null => {
    if (val === 'false') return false;
    if (val === 'true') return true;
    if (val === '') return null;

    throw Error(`Unsupported value "${val}".`);
};

/**
 * Retourne l'id d'une entité. Si la valeur était déjà un id la retourne.
 */
export const entityToId = (val: any): string | null => {
    if (!val) {
        return null;
    }

    if (typeof val === 'string') {
        return val;
    }

    if (!val.id) {
        return null;
    }

    return val.id;
};

/**
 * Retourne les ids d'un tableau d'entités. Si les valeurs du tableau sont déjà des ids les retourne.
 */
export const entitiesToIds = (vals: any[] | null | undefined): string[] => {
    if (!vals) {
        return [];
    }

    return vals.reduce((current: string[], val: any) => {
        const id: string | null = entityToId(val);

        if (id) {
            current.push(id);
        }

        return current;
    }, []);
};

export const matchOnId = (val1: any, val2: any): boolean => {
    const id1 = entityToId(val1);

    return Boolean(id1 && id1 === entityToId(val2));
};

export const initString = (val: string | null | undefined): string => {
    return val || '';
};

export const filterString = (val: string | number | null): string | null => {
    return val || val === 0 ? `${val}` : null;
};

export const isNumberDefined = (
    val: number | string | null | undefined
): boolean => {
    return Boolean(val || val === 0);
};

export const initNumber = (val: number | null | undefined): number | string => {
    return isNumberDefined(val) ? val! : '';
};

export function filterNumber(val: number | string): number | null {
    return isNumberDefined(val) ? +val : null;
}

export const initDate = (
    val: string | Date | null | undefined
): string | Date | null => {
    return val || null;
};

export const filterDate = (val: string | Date | null): string | null => {
    if (val) {
        return formatISO(new Date(val));
    }
    return null;
};

export type OnSubmitSuccess<R = any> = (res: R) => void;
export type OnSubmitError = (err: ApolloError) => void;
