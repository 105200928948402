import { matchOnId } from '@acdc/shared/src/utils/form-helpers';
import { useCallback } from 'react';
import SetChapters from './SetChapters';
import useDeleteChapterContent from './useDeleteChapterContent';

export type RemoveChapterContentFn = (
    chapterContentId: string,
    chapterId: string
) => void;

/**
 * Retourne une fonction pour supprimer un ChapterContent d'un Chapter.
 * Supprime directement en local + supprime sur l'API.
 */
function useRemoveChapterContent(setChapters: SetChapters) {
    const deleteChapterContent = useDeleteChapterContent();

    return useCallback<RemoveChapterContentFn>(
        (chapterContentId, chapterId) => {
            setChapters((curr) => ({
                chapters: {
                    ...curr.chapters,
                    collection:
                        curr.chapters?.collection.map((el) => {
                            if (matchOnId(el, chapterId)) {
                                return {
                                    ...el,
                                    chapterContents: {
                                        ...el.chapterContents,
                                        collection:
                                            el.chapterContents?.collection.filter(
                                                (someChapterContent) =>
                                                    !matchOnId(
                                                        someChapterContent,
                                                        chapterContentId
                                                    )
                                            ) || [],
                                    },
                                };
                            }

                            return el;
                        }) || [],
                },
            }));
            deleteChapterContent(chapterContentId);
        },
        [setChapters, deleteChapterContent]
    );
}

export default useRemoveChapterContent;
