import Chapter from '@acdc/shared/src/features/chapter/Chapter.model';
import ChapterResponse from '@acdc/shared/src/features/chapter/ChapterResponse';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { Box, IconButton, Typography, Theme } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ChapterFormDialogButton from '../ChapterForm/ChapterFormDialogButton';
import ChapterContentsList from '../../chapterContents/ChapterContentsList/ChapterContentsList';
import ContentDropzone from '../../content/ContentDropzone/ContentDropzone';
import {
    MoveContentFn,
    RemoveChapterContentFn,
} from '../../presentationFlow/PresentationFlowConfigurator/usePresentationFlowConfigurator';
import { OnDropFn } from '../../chapterContents/ChapterContentsList/ChapterContentDropWrapper';

const titleSx = {
    overflow: 'hidden',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    px: 2,
    marginX: (theme: Theme) => theme.spacing(1),
};

const actionProps = {
    sx: {
        color: 'inherit',
        '&:hover': {
            opacity: '70%',
        },
    },
};

export interface ChaptersListItemProps {
    itemNumber: number;
    chapter: ChapterResponse;
    moveContent: MoveContentFn;
    removeChapterContent: RemoveChapterContentFn;
}

function ChaptersListItem({
    itemNumber,
    chapter,
    moveContent,
    removeChapterContent,
}: ChaptersListItemProps) {
    const chapterToUpdate = useMemo<DeepPartial<Chapter>>(
        () => ({
            id: chapter.id,
            // on laisse ChapterForm charger les autres champs
        }),
        [chapter.id]
    );
    const onDrop = useCallback<OnDropFn>(
        ({ content, currentChapter }, newPosition) => {
            // soit la position reçue soit la derniere position du chapitre
            const pos =
                newPosition ||
                chapter.chapterContents?.collection.reduce((prev, curr) => {
                    // recherche de la position du dernier item + 1
                    if (curr.position && curr.position >= prev) {
                        return curr.position + 1;
                    }
                    return prev;
                }, 1) ||
                1;

            moveContent(
                entityToId(content)!,
                pos,
                entityToId(chapter)!,
                entityToId(currentChapter) || undefined
            );
        },
        [chapter, moveContent]
    );
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                minHeight: '300px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '150px',
                }}
            >
                <Typography
                    variant="h1"
                    component="span"
                    textAlign="center"
                    color="grey.700"
                    mr={2}
                    pl={2}
                    mb={0}
                >
                    {itemNumber.toString().padStart(2, '0')}
                </Typography>
                <Box sx={titleSx} data-testid="ChaptersListItemRoot">
                    <Typography
                        sx={{
                            wordBreak: 'break-word',
                            flex: 1,
                            fontWeight: 'bold',
                            // ellipsis pour mutiligne
                            display: '-webkit-box',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            whiteSpace: 'normal',
                        }}
                        pl={4}
                        textAlign="center"
                        variant="body1"
                        component="span"
                    >
                        {chapter.title}
                    </Typography>
                    <ChapterFormDialogButton
                        id={`update-Chapter-${getIdFromIri(
                            entityToId(chapter)
                        )}`}
                        value={chapterToUpdate}
                        buttonComponent={IconButton}
                        buttonProps={actionProps}
                    >
                        <EditIcon fontSize="small" />
                    </ChapterFormDialogButton>
                </Box>
            </Box>
            <Box sx={{ flex: 1, minHeight: '150px' }}>
                <ContentDropzone
                    onDrop={onDrop}
                    hasItems={Boolean(
                        chapter?.chapterContents?.collection.length
                    )}
                >
                    {chapter.chapterContents && (
                        <ChapterContentsList
                            chapter={chapter}
                            chapterContents={chapter.chapterContents}
                            onDrop={onDrop}
                            onRemoveChapterContent={(
                                chapterContentId: string
                            ) => {
                                if (!chapter.id) {
                                    return;
                                }
                                removeChapterContent(
                                    chapterContentId,
                                    chapter.id
                                );
                            }}
                        />
                    )}
                </ContentDropzone>
            </Box>
        </Box>
    );
}

export default ChaptersListItem;
