import BaseContentResponse from '@acdc/shared/src/features/baseContent/BaseContentResponse';
import ContentType from '@acdc/shared/src/features/baseContent/ContentType.enum';
import { DialogProps } from '@acdc/shared/src/ui/Dialog';
import { entityToId } from '@acdc/shared/src/utils/form-helpers';
import { getIdFromIri } from '@acdc/shared/src/utils/iri-helpers';
import { Box } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import Dialog from '../../../ui/Dialog';
import MultiStepContentForm, {
    ContentFormStep,
    MultiStepContentFormProps,
} from './MultiStepContentForm';

type MultiStepContentFormDialogProps = {
    customProps?: Pick<MultiStepContentFormProps, 'value' | 'type'>;
} & Omit<DialogProps, 'customProps'>;

/**
 * Dialog avec MultiStepContentForm qui redirige vers la configuration de Content en cas de modification ou création avec succès.
 */
function MultiStepContentFormDialog({
    customProps,
    onClose,
    ...moreDialogProps
}: MultiStepContentFormDialogProps) {
    const { value, type } = customProps || {};
    const defaultDialogProps = useMemo(
        () => ({
            mainTitle: value?.id
                ? 'Modifier une page'
                : 'Définir une nouvelle page',
            maxWidth: 'xs',
        }),
        [value?.id]
    );
    const [dialogProps, setDialogProps] = useState<any>(defaultDialogProps);
    const handleStepChange = useCallback(
        (step: ContentFormStep) => {
            switch (step) {
                case ContentFormStep.PICK_TEMPLATE:
                    setDialogProps((curr: any) => ({
                        ...curr,
                        mainTitle: 'Sélectionner un template',
                        maxWidth: 'xl',
                    }));
                    break;
                default:
                    setDialogProps(defaultDialogProps);
                    break;
            }
        },
        [setDialogProps, defaultDialogProps]
    );

    const navigate = useNavigate();
    const handleContentCreated = useCallback(
        (content: BaseContentResponse) => {
            if (type === ContentType.TOOL_CONTENT) {
                navigate(
                    `/outils/pages/${getIdFromIri(
                        entityToId(content)
                    )}/configurer`
                );
            } else {
                navigate(
                    `/pages/${getIdFromIri(entityToId(content))}/configurer`
                );
            }
        },
        [navigate, type]
    );

    return (
        <Dialog
            onClose={onClose}
            {...moreDialogProps}
            {...dialogProps}
            TransitionProps={{
                onExited: () => {
                    handleStepChange(ContentFormStep.BASE_FIELDS);
                },
            }}
        >
            <Box pt={2}>
                <MultiStepContentForm
                    type={type}
                    value={value}
                    onSuccess={(res) => {
                        onClose && onClose();
                        handleContentCreated(res);
                    }}
                    onStepChange={handleStepChange}
                />
            </Box>
        </Dialog>
    );
}

export default MultiStepContentFormDialog;
