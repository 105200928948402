enum PropertyFieldLabelEnum {
    clientLastname = 'Nom',
    clientFirstname = 'Prénom',
    clientEmail = 'E-mail',
    clientPhone = 'Téléphone',
    address = 'Adresse complète',
    street = 'Rue',
    postCode = 'Code postale',
    city = 'Ville',
}

export default PropertyFieldLabelEnum;
